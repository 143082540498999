import React, { Component } from "react"
import { Link, Redirect } from "react-router-dom"
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { Animated } from "react-animated-css"
import logo from "../../assets/ghidro-logo-footer.png"
import auth from '../../utils/auth'
import cfg from '../../utils/config'
import axios from 'axios'
import './style.css'

class Login extends Component {

    state = {
        email: "",
        password: "",
        conexao_persistente: false,
        redirectToReferrer: false,
        showmessage: false,
        logomarca: logo,
        loadcomplete: false,
        loading: false,
        config: { "titulo_sistema": "Hidroview v4", "descricao_sistema": "", "logomarca": "logomarcas/kronlogo.png", "descricao_horario_atendimento": "Seg. a Sex. das 8:00 às 18:00 ", "email_contato": "contato@ghidro.com.br ", "endereco": "Rua Carlos Ferrari, 18, Sala 610 – Centro – Garça/SP", "facebook": "https://www.facebook.com/ghidromonitoramento/", "link_criar_conta": "https://www.ghidro.com.br", "linkedin": "https://www.linkedin.com/company/g-hidro-monitoramento/", "telefone": "(14) 98161-0616", "youtube": "https://www.youtube.com/channel/UCj1BOaOQTuPMrTgPewSwgvw" },
        cores: { "degrade_top_primeira_cor": "#F44E3B", "degrade_top_segunda_cor": "#2f577b", "back_logomarca": "#37424A", "back_menu_lateral": "#37424A", "fonte_menu_lateral": "#c7c7c7", "fonte_grupo_menu_lateral": "#c7c7c7" },
        see_password: false,
        codigo_verificacao: "",
        msg_erro_codigo: "",
        modal: false
    }

    componentWillMount = () => {
        this.setFavicon(null)
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }
        let domain = window.location.host

        axios.get(cfg.base_api_url + cfg.api_v2 + `/configuracao-login/?domain=${domain}`, config_me)
            .then((config) => {
                let cores = ''
                let logomarca = ''
                let config_nova = ''

                cores = config.data.cores
                logomarca = config.data.config.logomarca
                config_nova = config.data.config
                document.title = config.data.config.titulo_sistema ?? "Afira"

                if (config.data.config?.favicon) {
                    this.setFavicon(config.data.config?.favicon)
                } else {
                    this.setFavicon("/favicon.ico")
                }

                this.setState({ cores: cores, opacity: 1, logomarca: logomarca, loadcomplete: true, config: config_nova })
            })
            .catch(console.error)
    }

    setFavicon = (link = null) => {
        let favicon = document.querySelector('link[rel="icon"]')

        if (favicon !== null) {
            favicon.href = link
        } else {
            favicon = document.createElement("link")
            favicon.rel = "icon"
            favicon.href = link
            document.head.appendChild(favicon)
        }
    }

    handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.handleSubmit(e)
        }
    }

    sendCode = () => {
        let bodyFormData = new FormData()
        bodyFormData.append('codigo', this.state.codigo_verificacao)

        axios({
            method: "POST",
            url: cfg.base_api_url + cfg.api_v2 + "/2fa/",
            data: bodyFormData,
            headers: { 'Authorization': 'Bearer ' + auth.getToken() }
        })
            .then(() => {
                this.getDataUser()
            })
            .catch((error) => {
                this.setState({ msg_erro_codigo: error.response.data.message })
            })
    }

    checkValidation = async () => {
        let validation = false
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }

        await axios.get(cfg.base_api_url + cfg.api_v2 + "/check-2fa/", config_me)
            .then((response) => {
                validation = response.data.ativo_2fa
            })

        return validation
    }

    getDataUser = () => {
        const config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }

        axios.get(cfg.base_api_url + cfg.api_v2 + '/me/', config_me)
            .then((res) => {
                res.data['pai'] = res.data.empresa
                auth.setUserInfo(res.data, true)
                this.setState({ redirectToReferrer: true, loading: false })
            })
            .catch(console.error)
    }

    handleSubmit = async (e) => {
        e.preventDefault()

        if (this.state.loading) return

        const requestURL = cfg.base_api_url + 'o/token/'
        const config = { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }

        let bodyFormData = new FormData()
        bodyFormData.append("client_id", cfg.client_id)
        bodyFormData.append("client_secret", cfg.client_secret)
        bodyFormData.append("grant_type", cfg.grant_type)
        bodyFormData.append("username", this.state.email)
        bodyFormData.append("password", this.state.password)

        this.setState({ loading: true })

        axios.post(requestURL, bodyFormData, config)
            .then((res) => {
                auth.setToken(res.data.access_token, true)
                auth.set(res.data.refresh_token, '__hidroview__refresh_token', true)
                auth.set(String(this.state.conexao_persistente), '__hidroview__persist_connect', true)

                this.checkValidation()
                    .then(is2fa => {
                        if (is2fa) {
                            this.setState({ modal: true, loading: false })
                        } else {
                            this.getDataUser()
                        }
                    })
            })
            .catch((error) => {
                this.setState({ showmessage: true, loading: false })
                setTimeout(() => this.setState({ showmessage: false }), 5000)
            })
    }

    seePassword = () => {
        this.setState({ see_password: !this.state.see_password })
    }

    render() {
        const { from } = this.props.location.state || { from: { pathname: "/" } }
        const { redirectToReferrer, see_password, cores, loading, modal } = this.state
        const type_password = see_password ? "text" : "password"
        const icon_see = see_password ? "las la-eye" : "las la-eye-slash"

        if (redirectToReferrer) {
            return <Redirect to={from} />
        }

        if (auth.getToken() !== null && auth.getUserInfo() !== null) {
            return <Redirect to={from} />
        }
        return (
            <div className={(this.state.loadcomplete) ? "body-page" : "hdv-noshow-item"} style={{ 'background': `linear-gradient(to right, ${this.state.cores.login_informacao_background} 50%, transparent 50%` }}>
                <Modal isOpen={modal} toggle={() => this.setState({ modal: false, msg_erro_codigo: "" })}>
                    <ModalHeader toggle={() => this.setState({ modal: false, msg_erro_codigo: "" })}>
                        <span>Verificação 2FA</span>
                    </ModalHeader>
                    <ModalBody>
                        <div>
                            <label>Código</label>
                            <input onKeyPress={(e) => e.key === 'Enter' ? this.sendCode() : ""} onChange={(e) => this.setState({ codigo_verificacao: e.target.value })} placeholder="Digite o código ..." type="text" />
                            <span id="validacao-codigo">{this.state.msg_erro_codigo}</span>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button onClick={this.sendCode} className="hdv-btn-forms hdv-btn-green">Checar</button>
                    </ModalFooter>
                </Modal>
                <Animated className="mensagem-erro" animationIn="fadeInDown" animationOut="fadeOutDown" animateOnMount={false} isVisible={this.state.showmessage}>
                    <div>
                        Falha na autenticação, por favor verifique seus dados e tente novamente. <br />
                        <i className="fa fa-exclamation mensagem-icone fa-white"></i>
                    </div>
                </Animated>
                <div className="container-login" style={{ 'backgroundColor': this.state.cores.login_informacao_background }}>
                    <div className="login-logo-sociais">
                        <div className="logomarca">
                            <img style={{filter: this.state.cores.filtro_logo ? 'brightness(0) invert(1)' : '', maxWidth: "200px"}} src={this.state.logomarca}/>
                        </div>
                    </div>
                    <div className="form-login">

                        <div className="imagem-titulo" style={{ width: "160px", height: "100px", backgroundImage: `url(${this.state.logomarca})`, backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "cover", margin: "0 auto" }}></div>

                        <div className="titulo">{this.state.config.titulo_sistema ? this.state.config.titulo_sistema : "Acesso ao Sistema"}</div>

                        <form>
                            <input placeholder="E-mail ..." type="text" id="email" onChange={e => this.setState({ email: e.target.value })} onKeyPress={this.handleKeyPress} className="campo-form" />

                            <div className="container-login-password">
                                <div className="password">
                                    <input placeholder="Senha ..." type={type_password} id="password" onChange={e => this.setState({ password: e.target.value })} onKeyPress={this.handleKeyPress} className="usuario" />
                                </div>

                                <div className="see-password">
                                    <i onClick={this.seePassword} className={icon_see}></i>
                                </div>
                            </div>

                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0px 5px" }}>
                                <span>
                                    <input onChange={e => this.setState({ conexao_persistente: e.target.checked })} onKeyPress={this.handleKeyPress} type="checkbox" name="permanecer" id="permanecer" />
                                    <label htmlFor="permanecer" className="permancer-label">Permanecer conectado?</label>
                                </span>
                                <div className="esqueci-minha-senha"><Link to="/lost-password">Esqueceu sua senha?</Link></div>
                            </div>
                            <div
                                style={{ 'background': cores.login_informacao_background, color: cores.login_formulario_fonte_cor, opacity: loading ? "0.7" : "1" }}
                                className="btn-login"
                                onClick={this.handleSubmit}>
                                Acessar
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default Login