import React, { Component } from "react";
import Select from '../../components/Select'
import { InputGroup, Input, InputGroupAddon, Button } from 'reactstrap'
import { DateRangePicker } from 'react-date-range'
import auth from '../../utils/auth'
import cfg from '../../utils/config'
import axios from 'axios'
import * as rdrLocales from 'react-date-range/dist/locale'
import pdfMake from 'pdfmake'
import CsvDownloader from 'react-csv-downloader'
import ReactLoading from 'react-loading'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

pdfMake.fonts = {
    Roboto: {
        normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
    }
}

class Form extends Component {
    state = {
        detalhes: { cliente: null, monitorado: null, tipo: { label: 'Vazão', value: 'vazao' }, volume: null, vazao: null, horimetro: null, periodo: null, inicio: null, fim: null },
        validacao: { periodo: true, cliente: true, monitorado: true, tipo: true, volume: true, horimetro: true, vazao: true, inicio: true, fim: true },
        monitorados: [],
        equipamentos: [],
        clientes: [],
        volumes: [],
        vazoes: [],
        niveis: [],
        horimetros: [],
        dataFormatada: "",
        datePickerShow: false,
        dateRange: { startDate: new Date(), endDate: new Date(), key: 'selection' },
        exportar_disabled: true,
        maior: false,
        carregando: false,
        csv: { data: [], columns: [] },
        relatorioGerado: false,
        modal: false,
        msg_erro: "",
        isCliente: auth.getUserInfo()?.cliente
    }

    componentDidMount() {
        const { isCliente, detalhes } = this.state

        if (isCliente) {
            this.getMonitorado()
            delete detalhes.cliente
            this.setState({ detalhes })
        } else {
            this.getClientes()
        }
    }

    getClientes = () => {
        let userInfo = auth.getUserInfo()
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }

        axios.get(cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/acesso/modulo/2/revenda/', config_me).then((res) => {
            let optionsCliente = []
            res.data.forEach((c) => {
                optionsCliente.push({ "value": c.cliente__id, "label": c.cliente__nome_fantasia })
            })

            this.setState({ clientes: optionsCliente })
        })
    }

    getMonitorado = (id_cliente) => {
        let userInfo = auth.getUserInfo()
        let bodyFormData = new FormData()

        bodyFormData.append('cliente_id', id_cliente ?? userInfo.id)

        axios({
            method: 'POST',
            url: cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/monitorado/filtro/',
            data: bodyFormData,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        }).then((response) => {
            let optionsMonitorado = []
            response.data.forEach((c) => {
                optionsMonitorado.push({ "value": c.id, "label": c.nome })
            })
            this.setState({ monitorados: optionsMonitorado })
        })
            .catch(error => console.log(error))
    }

    handleChangeCliente = (selectedOption, field) => {
        let obj = this.state.detalhes
        obj[field] = selectedOption.value

        this.getMonitorado(selectedOption.value)
    }

    handleChangeMonitorado = (selectedOption, field) => {
        let obj = this.state.detalhes
        obj[field] = selectedOption.value

        let userInfo = auth.getUserInfo()

        axios({
            method: 'GET',
            url: cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + `/monitorado-parametro/?limit=100&monitorado=${selectedOption.value}`,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        })
            .then((res) => {
                let optionsItem = []

                res.data.results.forEach((field) => {
                    optionsItem.push({ label: field.campo.descricao, value: field.campo.id, id: field.campo.identificacao })
                })

                this.setState({ volumes: optionsItem, vazoes: optionsItem, horimetros: optionsItem, niveis: optionsItem, detalhes: obj })
            }).catch(console.error)
    }

    handleChangeSelect = (selectedOption, field) => {
        let optionObj = Object.assign({}, this.state.detalhes)
        let validacao = Object.assign({}, this.state.validacao)

        optionObj[field] = selectedOption

        if (field === 'tipo') {
            let delete_field = selectedOption.value === 'vazao' ? 'nivel' : 'vazao'

            delete optionObj?.[delete_field]
            delete validacao?.[delete_field]

            optionObj[selectedOption.value] = null
            validacao[selectedOption.value] = true
        }

        this.setState({ detalhes: optionObj, validacao: validacao })
    }

    handleChangeHora = (e) => {
        let detalhes = Object.assign({}, this.state.detalhes)

        detalhes[e.target.id] = e.target.value

        this.setState({ detalhes: detalhes })
    }

    handleChangePeriodo = (ranges) => {
        let detalhes = Object.assign({}, this.state.detalhes)

        detalhes["periodo"] = ranges.selection.startDate.toLocaleDateString() + "-" + ranges.selection.endDate.toLocaleDateString()
        this.setState({
            dateRange: {
                startDate: ranges.selection.startDate,
                endDate: ranges.selection.endDate,
                key: 'selection',
            },
            dataFormatada: ranges.selection.startDate.toLocaleDateString() + " - " + ranges.selection.endDate.toLocaleDateString(),
            detalhes: detalhes
        })
    }

    showDatePicker = () => {
        this.setState({ datePickerShow: !this.state.datePickerShow })
    }

    gerarRelatorio = (e) => {
        e.preventDefault()

        const { detalhes, maior } = this.state
        let validacao = Object.assign({}, this.state.validacao)
        let erro = false

        for (let i in detalhes) {
            if (detalhes[i] === null) {
                validacao[i] = false
                erro = true
            } else {
                validacao[i] = true
            }
        }

        this.setState({ validacao: validacao })

        if (erro) {
            return
        }

        this.setState({ carregando: true, exportar_disabled: true })

        let userInfo = auth.getUserInfo()
        let bodyFormData = new FormData()

        let periodo = detalhes.periodo.split("-")

        bodyFormData.append('horimetro', JSON.stringify(detalhes.horimetro))
        bodyFormData.append('volume', JSON.stringify(detalhes.volume))
        bodyFormData.append('tipo', detalhes.tipo.value)
        bodyFormData.append('data_inicio', periodo[0].replaceAll("/", "-"))
        bodyFormData.append('data_fim', periodo[1].replaceAll("/", "-"))
        bodyFormData.append('hora_inicio', detalhes.inicio)
        bodyFormData.append('hora_fim', detalhes.fim)
        bodyFormData.append('m900', maior)

        if (detalhes.tipo.value === "vazao") {
            bodyFormData.append('vazao', JSON.stringify(detalhes.vazao))
        } else {
            bodyFormData.append('nivel', JSON.stringify(detalhes.nivel))
        }

        axios({
            method: 'POST',
            url: cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/relatorio-ambiental-inema/',
            data: bodyFormData,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        })
            .then((response) => {
                this.formatarCsv(response)
            })
            .catch(error => {
                this.setState({ carregando: false, exportar_disabled: true, msg_erro: error.response.data.detail, modal: true })
            })
    }

    formatarCsv = (response) => {
        this.setState({ carregando: false, exportar_disabled: false, relatorioGerado: true })

        const { detalhes, maior } = this.state
        let reportes = response.data
        let csv = { data: [], columns: [] }

        csv.columns = [
            { id: 'c0', displayName: 'Data' },
            { id: 'c1', displayName: 'Hora Inicial' },
            { id: 'c2', displayName: detalhes.tipo.label + " " + "Inicial" },
            { id: 'c3', displayName: 'Hora Final' },
            { id: 'c4', displayName: detalhes.tipo.label + " " + "Final" },
            { id: 'c5', displayName: "Horímetro" }
        ]

        if (maior) {
            csv.columns.push({ id: 'c6', displayName: 'Volume' })
        }

        let format_response = this.format_response_csv(reportes)

        for (let i in format_response) {
            let data_reporte = i

            let tipo_key = Object.keys(format_response[data_reporte][detalhes.tipo.value])
            let tipo_value = Object.values(format_response[data_reporte][detalhes.tipo.value])
            let horimetro = Object.values(format_response[data_reporte]["horimetro"])[1]

            let row = {
                c0: data_reporte,
                c1: tipo_key[0],
                c2: tipo_value[0],
                c3: tipo_key[1],
                c4: tipo_value[1],
                c5: horimetro
            }

            maior ? row['c6'] = format_response[data_reporte]["volume"].m900 : null

            csv.data.push(row)
        }

        this.setState({ csv: csv })
    }

    format_response_csv = (reportes) => {
        let format_response = {}

        reportes.forEach((item) => {
            let name_field = Object.keys(item)[0]
            let data = item[name_field]

            for (let i in data) {
                if (format_response[i]) {
                    format_response[i][name_field] = data[i]
                } else {
                    format_response[i] = {}
                    format_response[i][name_field] = data[i]
                }
            }
        })

        return format_response
    }

    exportarPDF = async () => {
        const infoEmpresa = auth.getUserInfo()
        const { csv } = this.state

        const columns = csv.columns.map(item => item.displayName)
        let table = [columns]
        let widths = []

        csv.data.map((item, key) => {
            let data = []

            for (let i in item) {
                data.push(String(item[i]))
                key === 0 ? widths.push('*') : ''
            }

            table.push(data)
        })

        const pdfOptions = {
            content: [
                {
                    table: {
                        widths: ['*', 80],
                        body: [
                            [
                                {
                                    text: ['\n', '\n', infoEmpresa.email],
                                    border: [false, false, false, true]
                                },
                                { image: 'logo', style: 'logo', width: 80, border: [false, false, false, true] }
                            ]
                        ],
                    },

                },

                '\n\n\n',

                {
                    text: 'Relatório Inema',
                    style: 'header',
                    alignment: 'center'
                },

                '\n',

                {
                    table: {
                        widths: widths,
                        body: table
                    }
                }
            ],
            images: { 'logo': document.querySelector('div.hdv-logomarca img')?.src, },
        }

        const win = window.open('', '_blank')
        pdfMake.createPdf(pdfOptions).open({}, win)
    }


    render() {
        const { clientes, monitorados, volumes, vazoes, niveis, horimetros, exportar_disabled, maior, validacao, detalhes, relatorioGerado, csv, msg_erro, isCliente } = this.state

        return (
            <div className="hdv-render-div" style={{ marginBottom: '65px' }}>

                <Modal isOpen={this.state.modal} toggle={() => this.setState({ modal: false })}>
                    <ModalHeader toggle={() => this.setState({ modal: false })} >Aviso</ModalHeader>
                    <ModalBody>
                        <strong>{msg_erro}</strong>
                    </ModalBody>
                    <ModalFooter>
                        <button onClick={() => this.setState({ modal: false })} className="hdv-btn-forms hdv-btn-red">Fechar</button>
                    </ModalFooter>
                </Modal>

                <div className="col-md-12 hdv-bloco-top-space hdv-zera-padding-left-right-boostrap">
                    <div>
                        <div style={{ paddingBottom: '0px' }} className="hdv-default-header mx-3">
                            <span className="screen-menu-desc">
                                <h4>Relatório Inema</h4>
                                <div className="gray-background">
                                    <i className="fa fa-file-alt fa-2x"></i>
                                </div>
                            </span>
                            <ReactLoading className="hdv-report-loading" type="cylon" color={this.state.carregando ? "#589bd4" : "transparent"} width={50} />
                            <div style={{ display: 'flex' }} className="hdv-btn-group">
                                <button onClick={this.gerarRelatorio} className="hdv-btn-forms hdv-btn-blue">Gerar Relatório</button>

                                <CsvDownloader
                                    style={{ border: 'none' }}
                                    columns={this.state.csv.columns}
                                    datas={this.state.csv.data}
                                    filename={`relatorio_inema.csv`}
                                    separator=";">
                                    <button disabled={exportar_disabled} className="hdv-btn-forms hdv-btn-green ">
                                        Exportar CSV
                                    </button>
                                </CsvDownloader>
                                <button onClick={this.exportarPDF} disabled={exportar_disabled} className="hdv-btn-forms hdv-btn-red ">Exportar PDF</button>
                            </div>
                        </div>

                        <details open={true} className='create-profile-heading'>
                            <summary>Filtros</summary>
                            <form style={{ padding: "20px" }} className="hdv-form-default">
                                <div className="row">
                                    <div style={{ display: isCliente ? "none" : "block" }} className="col-md-3">
                                        <label className="required mt-0">Cliente</label>
                                        <Select
                                            name="cliente"
                                            options={clientes}
                                            onChange={(e) => { this.handleChangeCliente(e, "cliente") }}
                                        />
                                        {!validacao.cliente ? <span id='validacao-descricao'>Campo Obrigatório</span> : null}
                                    </div>
                                    <div className="col-md-3">
                                        <label className="required mt-0">Monitorado</label>
                                        <Select
                                            name="monitorado"
                                            options={monitorados}
                                            onChange={(e) => this.handleChangeMonitorado(e, 'monitorado')}
                                        />
                                        {!validacao?.monitorado ? <span id='validacao-descricao'>Campo Obrigatório</span> : null}
                                    </div>
                                </div>

                                <br />

                                <div className="row">
                                    <div className="col-md-3">
                                        <label className="required mt-0">Tipo</label>
                                        <Select
                                            name="tipo"
                                            value={[
                                                'vazao' in detalhes ?
                                                    { label: "Vazão", value: "vazao" }
                                                    :
                                                    { label: "Nível", value: "nivel" }
                                            ]}
                                            options={[
                                                { label: "Vazão", value: "vazao" },
                                                { label: "Nível", value: "nivel" }
                                            ]}
                                            onChange={(e) => this.handleChangeSelect(e, 'tipo')}
                                        />
                                        {!validacao.tipo ? <span id='validacao-descricao'>Campo Obrigatório</span> : null}
                                    </div>
                                    <div className="col-md-3">
                                        <label className="required mt-0">Volume</label>
                                        <Select
                                            name="volume"
                                            options={volumes}
                                            onChange={(e) => this.handleChangeSelect(e, 'volume')}
                                        />
                                        {!validacao.volume ? <span id='validacao-descricao'>Campo Obrigatório</span> : null}
                                    </div>

                                    {
                                        'vazao' in detalhes ?

                                            <div className="col-md-3">
                                                <label className="required mt-0">Vazão</label>
                                                <Select
                                                    name="vazao"
                                                    options={vazoes}
                                                    value={detalhes.vazao}
                                                    onChange={(e) => this.handleChangeSelect(e, 'vazao')}
                                                />
                                                {!validacao.vazao ? <span id='validacao-descricao'>Campo Obrigatório</span> : null}
                                            </div>

                                            : 'nivel' in detalhes ?

                                                <div className="col-md-3">
                                                    <label className="required mt-0">Nível</label>
                                                    <Select
                                                        name="nivel"
                                                        options={niveis}
                                                        value={detalhes.nivel}
                                                        onChange={(e) => this.handleChangeSelect(e, 'nivel')}
                                                    />
                                                    {!validacao.nivel ? <span id='validacao-descricao'>Campo Obrigatório</span> : null}
                                                </div>
                                                : ""
                                    }


                                    <div className="col-md-3">
                                        <label className="required mt-0">Horimetro</label>
                                        <Select
                                            name="horimetro"
                                            options={horimetros}
                                            onChange={(e) => this.handleChangeSelect(e, 'horimetro')}
                                        />
                                        {!validacao.horimetro ? <span id='validacao-descricao'>Campo Obrigatório</span> : null}
                                    </div>
                                </div>

                                <br />

                                <div className="row">
                                    <div className="col-md-5">
                                        <label className="required mt-0">Periodo</label>
                                        <InputGroup className="hdv-force-hight">
                                            <Input className="hdv-force-hight" placeholder="Selecione o periodo" onChange={() => false} disabled value={this.state.dataFormatada} />
                                            <InputGroupAddon addonType="append">
                                                <Button onClick={this.showDatePicker} color={(this.state.datePickerShow) ? "danger" : "primary"}>
                                                    <i className={(this.state.datePickerShow) ? "fa fa-calendar-times-o" : "fa fa-calendar-check-o"}></i>
                                                    {(this.state.datePickerShow) ? "Fechar Calendário" : "Exibir Calendário"}
                                                </Button>
                                            </InputGroupAddon>
                                        </InputGroup>
                                        {!validacao.periodo ? <span id='validacao-periodo'>Campo Obrigatório</span> : null}
                                        <div style={{ position: "initial" }} className={(this.state.datePickerShow) ? "hdv-datepicker" : "hdv-datepicker-display-none"}>
                                            <DateRangePicker locale={rdrLocales.pt} ranges={[this.state.dateRange]} onChange={this.handleChangePeriodo} />
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <label className="required mt-0">Início</label>
                                        <input onChange={(e) => this.handleChangeHora(e)} id="inicio" type="time" />
                                        {!validacao.inicio ? <span id='validacao-descricao'>Campo Obrigatório</span> : null}
                                    </div>
                                    <div className="col-md-2">
                                        <label className="required mt-0">Fim</label>
                                        <input onChange={(e) => this.handleChangeHora(e)} id="fim" type="time" />
                                        {!validacao.fim ? <span id='validacao-descricao'>Campo Obrigatório</span> : null}
                                    </div>
                                    <div className="col-md-2">
                                        <label className="required mt-0">Maior que 900m³/dia</label>
                                        <input onChange={(e) => this.setState({ maior: e.target.checked })} defaultChecked={maior} type="checkbox" />
                                    </div>
                                </div>
                            </form>
                        </details>
                    </div>
                    {
                        relatorioGerado ?
                            <section id="hdv-multi-report-print" className="mb-4">
                                <br />
                                <h5 style={{ textAlign: 'center', fontWeight: 'bold' }}>Relatório Inema</h5>
                                <br />

                                <table className="table afira-table">
                                    <thead>
                                        <tr>
                                            {
                                                csv.columns.map((item, key) => {
                                                    return (
                                                        <th key={key}>{item.displayName}</th>
                                                    )
                                                })
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            csv.data.map((item, key) => {
                                                return (
                                                    <tr key={key}>
                                                        <td>{item.c0}</td>
                                                        <td>{item.c1}</td>
                                                        <td>{item.c2}</td>
                                                        <td>{item.c3}</td>
                                                        <td>{item.c4}</td>
                                                        <td>{item.c5}</td>
                                                        <td>{item?.c6}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </section>
                        : ''
                    }
                </div>
            </div>
        )
    }
}

export default Form