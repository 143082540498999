import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { JSONTree } from 'react-json-tree'
import Select from "../../../../components/Select";

class ConfiguracoesColunas extends Component {
    state = {
        modalLabel: { status: false, index: "" },
        modalCampo: { status: false, index: "" },
        modalFormula: { status: false, index: "" },
        modalFixo: { status: false, index: "" },
        colunaSelecionada: false
    }
    
    handleChangeEstrutura = (field, value) => {
        let estrutura = Object.assign({}, this.props.estrutura)

        if (field === "quantidadeColunas") {
            let quantidadeColunas = estrutura['quantidadeColunas']
            let colunas = estrutura['colunas']

            if (value > quantidadeColunas) {
                let quantidadeAdicionar = value - quantidadeColunas

                for (let i = 1; i <= quantidadeAdicionar; i++) {
                    colunas.push({ coluna: colunas.length + 1, label: `coluna ${colunas.length + 1}`, formula_campo: { label: "Campo", value: "campo" }, campo: "" })
                }
            } else {
                let quantidadeSubtrair = quantidadeColunas - value

                for (let i = 1; i <= quantidadeSubtrair; i++) {
                    colunas.pop()
                }
            }
            
            estrutura['colunas'] = colunas
        }
       
        estrutura[field] = value

        this.props.setEstrutura(estrutura)
    }

    handleChangeColuna = (field, value, index) => {
        let estrutura = Object.assign({}, this.props.estrutura)
        let coluna = estrutura.colunas.filter((coluna, key_coluna) => key_coluna === index)[0]

        coluna[field] = value

        if (value.value === 'formula') {
            delete coluna?.campo
            delete coluna?.fixo

            coluna["formula"] = coluna?.formula ? coluna.formula : ""
            this.setState({ modalFormula: { status: true, index: index } })
        } else if (value.value === 'campo') {
            delete coluna?.formula
            delete coluna?.fixo

            coluna["campo"] = coluna?.campo ? coluna.campo : ""
            this.setState({ modalCampo: { status: true, index: index } })
        } else {
            delete coluna?.formula
            delete coluna?.campo

            coluna["fixo"] = coluna?.fixo ? coluna.fixo : ""
            this.setState({ modalFixo: { status: true, index: index } })
        }

        estrutura.colunas[index] = coluna

        this.props.setEstrutura(estrutura)
    }

    trocarColuna = (id) => {
        const { colunaSelecionada } = this.state

        if (id !== "") {
            if (colunaSelecionada === false) {
                this.setState({ colunaSelecionada: id })
            } else {
                let estrutura = Object.assign({}, this.props.estrutura)
                let origem = estrutura.colunas[colunaSelecionada]
                let destino = estrutura.colunas[id]

                estrutura.colunas[colunaSelecionada] = destino
                estrutura.colunas[id] = origem

                this.setState({ colunaSelecionada: false })
                this.props.setEstrutura(estrutura)
            }
        }
    }

    excluirColuna = (key) => {
        let estrutura = Object.assign({}, this.props.estrutura)
        let colunas = this.props.estrutura.colunas.filter((grupo, index) => index != key)
        let colunasCabecalho = this.props.estrutura.colunasCabecalho.filter((grupo, index) => index != key)

        estrutura.colunas = colunas
        estrutura.quantidadeColunas = colunas.length
        estrutura.colunasCabecalho = colunasCabecalho
       
        this.props.setEstrutura(estrutura)
    }

    
    changeLabelColumn = (value, index) => {
        let estrutura = Object.assign({}, this.props.estrutura)
        let coluna = estrutura.colunas.filter((coluna, key_coluna) => key_coluna === index)[0]

        coluna['label'] = value

        estrutura.colunas[index] = coluna

        this.props.setEstrutura(estrutura)
    }


    toggleModal = (modal) => {
        if (modal === 'campo') {
            this.setState({ modalCampo: { status: false, index: "" } })
        } else if (modal === 'formula') {
            this.setState({ modalFormula: { status: false, index: "" } })
        } else if (modal === 'label') {
            this.setState({ modalLabel: { status: false, index: "" } })
        } else if (modal === 'fixo') {
            this.setState({ modalFixo: { status: false, index: "" } })
        }

        this.setState({ colunaSelecionada: false })
    }


    adicionarCampoColuna = (campo) => {
        let estrutura = Object.assign({}, this.props.estrutura)
        const campos = this.props.campos
        const coluna = estrutura.colunas.filter((coluna, index) => index === this.state.modalCampo.index)[0]
        const unidade = this.props.unidades.filter((unidade) => unidade.id === campos[campo[0]])[0]
        let descricao = ""

        this.props.detalhes.campos.forEach((item) => {
            if (/*`${item.imei}_` +*/ item.identificacao === campos[campo[0]]) {
                descricao = item.descricao
            } else if (`${item.imei}_` + item.identificacao + "_inicial" === campos[campo[0]]) {
                descricao = item.descricao
            } else if (`${item.imei}_` + item.identificacao + "_final" === campos[campo[0]]) {
                descricao = item.descricao
            } else if (`${item.imei}_` + item.identificacao + "_minima" === campos[campo[0]]) {
                descricao = item.descricao
            } else if (`${item.imei}_` + item.identificacao + "_media" === campos[campo[0]]) {
                descricao = item.descricao
            } else if (`${item.imei}_` + item.identificacao + "_maxima" === campos[campo[0]]) {
                descricao = item.descricao
            }
        })

        coluna.campo = campos[campo[0]]
        coluna.label = descricao + " " + unidade.unidade

        this.props.setEstrutura(estrutura)
    }

    adicionarFormulaColuna = (campo) => {
        let estrutura = Object.assign({}, this.props.estrutura)
        const campos = this.props.campos
        const coluna = estrutura.colunas.filter((coluna, index) => index === this.state.modalFormula.index)[0]

        coluna.formula = coluna.formula + `[${campos[campo[0]]}]`

        this.props.setEstrutura(estrutura)
    }

    handleChangeFormula = (value) => {
        let estrutura = Object.assign({}, this.props.estrutura)
        const modalFormula = this.state.modalFormula

        estrutura.colunas[modalFormula.index].formula = value

        this.props.setEstrutura(estrutura)
    }

    handleChangeFixo = (value) => {
        let estrutura = Object.assign({}, this.props.estrutura)
        const modalFixo = this.state.modalFixo

        estrutura.colunas[modalFixo.index].fixo = value

        this.props.setEstrutura(estrutura)
    }

    render() {
        const { colunaSelecionada, modalLabel, modalCampo, modalFixo, modalFormula } = this.state
        const { styleFieldset, estrutura, campos  } = this.props

        const theme = {
            scheme: 'monokai',
            author: 'wimer hazenberg (http://www.monokai.nl)',
            base00: '#F2F2F2',
            base01: '#212529',
            base02: '#212529',
            base03: '#212529',
            base04: '#212529',
            base05: '#212529',
            base06: '#212529',
            base07: '#212529',
            base08: '#212529',
            base09: '#212529',
            base0A: '#212529',
            base0B: '#212529',
            base0C: '#212529',
            base0D: '#212529',
            base0E: '#212529',
            base0F: '#212529',
        }

        return(
            <>
                <Modal isOpen={modalLabel.status} toggle={() => this.toggleModal("label")}>
                    <ModalHeader>
                        Coluna {String(modalLabel.index + 1)}
                    </ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="col-md-8">
                                <label className="required">Nome da coluna</label>
                                <input value={estrutura.colunas[modalLabel.index]?.label ?? ""} onChange={(e) => this.changeLabelColumn(e.target.value, modalLabel.index)} type="text" />
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button onClick={() => this.toggleModal("label")} className="hdv-btn-forms hdv-btn-red">Fechar</button>
                    </ModalFooter>
                </Modal>

                <Modal className='modal-md' isOpen={modalCampo.status} toggle={() => this.toggleModal("campo")}>
                    <ModalHeader>
                        Campo
                    </ModalHeader>
                    <ModalBody>
                        <div style={{ justifyContent: "space-between" }} className="row">
                            <div className="col-md-6">
                                <label className="required">Campo</label>
                                <input value={estrutura.colunas[modalCampo.index]?.campo ?? ""} disabled={true} type="text" />
                            </div>
                            <div style={{ maxHeight: '300px', overflow: 'auto', width: "400px", padding: "10px" }}>
                                <JSONTree
                                    data={{
                                        campos: campos
                                    }}
                                    labelRenderer={([key]) => <span>{key}: </span>}
                                    valueRenderer={(valueAsString, value, ...keyPath) =>
                                        <>
                                            <button
                                                onClick={() => { this.adicionarCampoColuna(keyPath) }}
                                                style={{
                                                    backgroundColor: "#44bb52",
                                                    color: "white",
                                                    borderRadius: "5px",
                                                }}
                                            >
                                                {valueAsString}
                                            </button>
                                        </>
                                    }
                                    hideRoot={true}
                                    theme={theme}
                                />
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button onClick={() => this.toggleModal("campo")} className="hdv-btn-forms hdv-btn-red">Fechar</button>
                    </ModalFooter>
                </Modal>

                <Modal className='modal-md' isOpen={modalFormula.status} toggle={() => this.toggleModal("formula")}>
                    <ModalHeader>
                        Fórmula
                    </ModalHeader>
                    <ModalBody>
                        <div style={{ justifyContent: "space-between" }} className="row">
                            <div className="col-md-6">
                                <label className="required">Fórmula</label>
                                <input value={estrutura.colunas[modalFormula.index]?.formula ?? ""} onChange={(e) => this.handleChangeFormula(e.target.value)} type="text" />
                            </div>
                            <div style={{ maxHeight: '300px', overflow: 'auto', width: "400px", padding: "10px" }}>
                                <JSONTree
                                    data={{
                                        campos: campos
                                    }}
                                    labelRenderer={([key]) => <span>{key}: </span>}
                                    valueRenderer={(valueAsString, value, ...keyPath) =>
                                        <>
                                            <button
                                                onClick={() => { this.adicionarFormulaColuna(keyPath) }}
                                                style={{
                                                    backgroundColor: "#44bb52",
                                                    color: "white",
                                                    borderRadius: "5px",
                                                }}
                                            >
                                                {valueAsString}
                                            </button>
                                        </>
                                    }
                                    hideRoot={true}
                                    theme={theme}
                                />
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button onClick={() => this.toggleModal("formula")} className="hdv-btn-forms hdv-btn-red">Fechar</button>
                    </ModalFooter>
                </Modal>

                <Modal className='modal-md' isOpen={modalFixo.status} toggle={() => this.toggleModal("fixo")}>
                    <ModalHeader>
                        Valor fixo
                    </ModalHeader>
                    <ModalBody>
                        <div style={{ justifyContent: "space-between" }} className="row">
                            <div className="col-md-6">
                                <label className="required">Valor fixo</label>
                                <input value={estrutura.colunas[modalFixo.index]?.fixo ?? ""} onChange={(e) => this.handleChangeFixo(e.target.value)} type="text" />
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button onClick={() => this.toggleModal("fixo")} className="hdv-btn-forms hdv-btn-red">Fechar</button>
                    </ModalFooter>
                </Modal>

                <fieldset style={styleFieldset.fieldset}>
                    <legend style={styleFieldset.legend}>Configurações das colunas</legend>

                    <div className="row">
                        <div className="col-md-2">
                            <label className="required">Quantidade de colunas</label>
                            <input value={estrutura.quantidadeColunas} min={0} onChange={(e) => this.handleChangeEstrutura("quantidadeColunas", Number(e.target.value))} type="number"/>
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        {
                            estrutura.colunas.length > 0 ?
                                estrutura.colunas.map((coluna, key) => {
                                    return (
                                        <div
                                            key={key}
                                            style={
                                                {
                                                    margin: "0px 15px 15px 15px",
                                                    borderRadius: "10px",
                                                    padding: "10px",
                                                    background: key === colunaSelecionada ? "rgb(186 185 185 / 66%)" : "rgb(245 245 245 / 66%)",
                                                    cursor: "pointer"
                                                }
                                            }
                                            className="col-md-3"
                                        >
                                            <label style={{ display: "flex", justifyContent: "space-between", alignItems: "center", cursor: "pointer" }} className="required">
                                                <div>{coluna.label}</div>

                                                <div style={{ display: "flex" }}>
                                                    <div style={{ padding: "2px 3px", cursor: "pointer", marginRight: "3px", opacity: "0" }}>
                                                        <input checked={colunaSelecionada === key} onChange={() => this.trocarColuna(key)} type="checkbox" />
                                                    </div>

                                                    <div style={{ background: "#f4be1a", borderRadius: "10px", padding: "2px 3px", cursor: "pointer", marginRight: "3px" }} onClick={() => { this.setState({ modalLabel: { status: true, index: key } }) }} >
                                                        <i title={`Editar nome da coluna ${key + 1}`} style={{ fontSize: "22px" }} className="las la-pencil-alt"></i>
                                                    </div>

                                                    <div onClick={() => this.excluirColuna(key)} title={`Excluir coluna ${key + 1}`} style={{ background: "#e64444", borderRadius: "10px", padding: "2px 3px", cursor: "pointer" }}>
                                                        <i style={{ color: "white", fontSize: "22px" }} className="las la-times"></i>
                                                    </div>
                                                </div>
                                            </label>
                                            <Select
                                                onChange={(e) => { this.handleChangeColuna("formula_campo", e, key) }}
                                                value={coluna.formula_campo}
                                                options={[
                                                    { label: "Campo", value: 'campo' },
                                                    { label: "Fórmula", value: 'formula' },
                                                    { label: "Valor fixo", value: 'fixo' }
                                                ]}
                                            />
                                        </div>
                                    )
                                })
                            : null
                        }
                    </div>
                </fieldset>
            </>
        )
    }
}

export default ConfiguracoesColunas