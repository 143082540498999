import React, { Component } from 'react'
import { Switch } from 'react-router-dom'
import PrivateRoute from '../../containers/PrivateRoute'
import { RelatorioContextProvider } from '../../contexts/Relatorio'
import Grid from './grid'
import Form from './form'
import FormNovo from './novo'

class RelatorioCustomizado extends Component {
	render() {
		return (
			<RelatorioContextProvider>
				<div className="hdv-render-div" style={{ marginBottom: '65px' }}>
					<div className="col-md-12 hdv-bloco-top-space hdv-zera-padding-left-right-boostrap">
						<Switch>
							<PrivateRoute exact path='/relatorio-customizado' component={Grid} />
							<PrivateRoute path='/relatorio-customizado/novo' component={FormNovo} />
							<PrivateRoute path='/relatorio-customizado/:id' component={Form} />
						</Switch>
					</div>
				</div>
			</RelatorioContextProvider>
		)
	}
}

export default RelatorioCustomizado
