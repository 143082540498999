import React, { Component } from 'react'
import { Link, Redirect } from "react-router-dom"
import ConfirmDelete from '../../components/ConfirmDelete'
import ButtonsGrid from '../../components/ButtonsGrid'
import Table from '../../components/Table'
import auth from '../../utils/auth'
import cfg from '../../utils/config'
import axios from 'axios'

class Grid extends Component {
    baseUrl = cfg.base_api_url + cfg.api_v2
    userInfo = auth.getUserInfo()
    config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }

    state = {
        data: [],
        loading: true,
        redirectToEdit: false
    }

    getData = (state) => {
        this.setState({ loading: true })
        let url = cfg.base_api_url + cfg.api_v2 + `/${this.userInfo.empresa}/relatorio/?limit=${state?.lineCount}` + state.filter

        if (state.url) {
            url = state.url
        }

        axios.get(url, this.config_me).then((res) => {
            let { next, previous, count } = res.data
            let results = res.data?.results

            this.setState({ data: results, loading: false, next, previous, count })
        })
    }

    deletar = () => {
        let requestArray = []
        this.setState({ loading: true })

        this.state.table?.state.selected?.map((value, index) =>
            requestArray.push(axios.delete(`${this.baseUrl}/${this.userInfo.empresa}/relatorio/${value}/`, this.config_me))
        )

        axios.all(requestArray).then(axios.spread((acct, perms) => this.state?.table?.fetch()))
    }

    editar = () => {
        let toGo = `relatorio-customizado/${this.state.table?.state.selected[0]}`
        let from = { pathname: toGo }
        this.setState({ redirectToEdit: from })
    }

    render() {
        const { redirectToEdit, table } = this.state

        if (redirectToEdit) {
            return <Redirect to={redirectToEdit} />
        }

        const columns = [{
            Header: 'Descrição',
            Accessor: 'descricao',
            Cell: (original) => <Link to={`/relatorio-customizado/${original.id}`}>{original?.descricao}</Link>
        }, {
            Header: '',
            Accessor: '',
            sortable: false,
            Cell: () => <div></div>
        }]

        return (
            <div>
                <div className="hdv-default-header mx-3">
                    <span className="screen-menu-desc">
                        <h4>Cadastro de Relatórios Customizados</h4>
                        <div className="gray-background">
                            <i className="fa fa-chart-bar fa-2x"></i>
                        </div>
                    </span>
                </div>

                <div className="hdv-btn-group">
                    <ButtonsGrid
                        route="/relatorio"
                        buttons={[
                            {
                                method: "post",
                                button: <Link to="/relatorio-customizado/novo"><button className="hdv-btn-forms hdv-btn-green ">Novo</button></Link>
                            },

                            {
                                method: "patch",
                                button: <button
                                    onClick={() => { if (table?.state.enableEditar) { this.editar() } }}
                                    className={"hdv-btn-forms hdv-btn-yellow " + (table?.state.enableEditar ? "" : "hdv-btn-disable")}>
                                    Editar / Gerar Relatório
                                </button>
                            },

                            {
                                method: "delete",
                                button: <ConfirmDelete enabled={table?.state?.enableDeletar} action={this.deletar} />
                            }
                        ]}
                    />
                </div>

                <Table
                    checkbox
                    data={this.state.data}
                    loading={this.state.loading}
                    count={this.state.count}
                    next={this.state.next}
                    previous={this.state.previous}
                    columns={columns}
                    onFetchData={this.getData}
                    onUpdate={(table) => this.setState({ table })}
                />
            </div>
        )
    }
}

export default Grid
