import React from "react"
import Select from "../../../components/Select"
import { RelatorioContext } from "../../../contexts/Relatorio"
import cfg from "../../../utils/config"
import auth from "../../../utils/auth"
import axios from "axios"

class SelectCampo extends React.Component {
    static contextType = RelatorioContext

    userInfo = auth.getUserInfo()
    configMe = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }

    state = {
        cliente: "",
        monitorado: "",
        campo: "",
        monitorados: [],
        campos: [],
    }

    handleChangeCliente = (selectedOption) => {
        let bodyFormData = new FormData()
        bodyFormData.append('cliente_id', selectedOption.value)

        this.setState({ cliente: selectedOption })

        axios({
            method: 'POST',
            url: cfg.base_api_url + cfg.api_v2 + '/' + this.userInfo.empresa + '/monitorado/filtro/',
            data: bodyFormData,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        })
            .then(res => {
                console.log(res)
                let optionsMonitorado = []
                res.data.forEach((c) => {
                    optionsMonitorado.push({ "value": c.id, "label": c.nome })
                })

                this.setState({ monitorados: optionsMonitorado })
            })
            .catch(error => console.log(error))
    }

    handleChangeMonitorado = async (selectedOption) => {
        this.setState({ monitorado: selectedOption })

        const requestURL = cfg.base_api_url + cfg.api_v2 + '/' + this.userInfo.empresa + `/monitorado-parametro/?monitorado=${selectedOption.value}`

        await axios.get(requestURL, this.configMe).then(res => {
            if (Array.isArray(res.data?.results)) {
                let campos = []
                res.data?.results?.forEach(campo => campos.push({
                    label: `${campo.campo.descricao} (${campo.campo.identificacao})`,
                    value: campo.campo.id,
                    identificacao: campo.campo.identificacao,
                }))

                this.setState({ campos })
            }
        }).catch(console.error)
    }

    handleChangeCampo = (selectedOption) => {
        const campo = structuredClone(selectedOption)
        this.props?.onChange?.(campo)
        this.setState({ campo: selectedOption })

        let details = document.querySelector("details.afira-select-cf")
        details.open = false
    }

    aplicar = () => {
        let details = document.querySelector("details.afira-select-cf")
        details.open = false

        if (this.state.campo) {
            const campo = structuredClone(this.state.campo)
            this.props?.onChange?.(campo)
            this.setState({ cliente: "", monitorado: "", campo: "" })
        }
    }

    render() {
        let campo = { identificacao: "Selecione..." }

        if (this.state.campo) {
            campo = this.state.campo
        } else if (this.props.value) {
            campo = this.props.value
        }

        return (
            <details className="afira-select-cf">
                <summary>{campo?.identificacao}</summary>
                <div className="afira-select-cf-content">
                    <div>
                        <label>Cliente</label>
                        <Select
                            value={this.state.cliente}
                            onChange={(e) => this.handleChangeCliente(e)}
                            options={this.context.clientes ?? []}
                        />
                    </div>

                    <div className="hdv-top15">
                        <label>Monitorado</label>
                        <Select
                            value={this.state.monitorado}
                            onChange={(e) => this.handleChangeMonitorado(e)}
                            options={this.state.monitorados}
                        />
                    </div>

                    <div className="hdv-top15">
                        <label>Campo</label>
                        <Select
                            value={this.state.campo}
                            onChange={(e) => this.handleChangeCampo(e)}
                            options={this.state.campos}
                        />
                    </div>
                </div>
            </details>
        )
    }
}

export default SelectCampo