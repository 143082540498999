import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'

import './assets/new.css'
import './assets/style.css'
import PrivateRoute from './containers/PrivateRoute'
import Login from './containers/Login'
import Recuperar from './containers/Login/recuperar'
import Cliente from './containers/Login/cliente'
import Layout from './containers/Layout'
import { SupervisorioLink, Supervisorio } from './containers/Supervisorio'
import { AvisoContextProvider } from './contexts/Aviso'
import RedirectRoute from './containers/RedirectRoute'

class App extends Component {
	render() {
		return (
			<AvisoContextProvider>
				<div className="hdv-maxheight">
					<Switch>
						<Route exact path='/redirect' component={RedirectRoute} />
						<Route exact path='/login' component={Login} />
						<Route path="/customer" component={Cliente} />
						<Route path="/lost-password" component={Recuperar} />
						<PrivateRoute exact path='/supervisorio' component={Supervisorio} />
						<Route exact path='/supervisorio/:hash' component={SupervisorioLink} />
						<PrivateRoute path='/' component={Layout} />
					</Switch>
				</div>
			</AvisoContextProvider>
		);
	}
}

export default App;
