import React, { Component } from 'react'
import Select from '../../../components/Select'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import auth from '../../../utils/auth'
import cfg from '../../../utils/config'
import axios from 'axios'

class HeaderConsumo extends Component {
   userInfo = auth.getUserInfo()
   config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }

   state = {
      mounted: 0,
      detalhes: { data: "" },
      clientes: [],
      monitorados: [],
      addCampo: {
         modal: false,
         filtrados: [],
         selecionados: [],
         campo: null,
         posicao: null,
         filtros: { cliente: null, monitorado: null },
      }
   }

   componentDidMount = () => {
      let { addCampo } = this.state
      let campos = []
      let requestArray = []
      let userInfo = auth.getUserInfo()
      let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }

      this.props.DataChange.campos?.map((campo) => {
         campos.push({
            label: campo.descricao,
            value: campo.id
         })
      })

      addCampo['selecionados'] = campos
      this.setState({ addCampo })

      requestArray.push(axios.get(cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/cliente/', config_me))

      axios.all(requestArray).then(axios.spread((cliente) => {
         let optionsCliente = []

         optionsCliente.push({ label: 'Nenhum', value: null })
         if (cliente.data?.results.length > 0) {
            cliente.data?.results?.forEach((c) => {
               optionsCliente.push({ "value": c.id, "label": c.nome_fantasia })
            })

            if(userInfo.tipo === 'cliente') {
               const cliente = optionsCliente.find(c => c.value === userInfo.cliente)

               this.handleChangeCliente(cliente)
            }
            /*if (this.props.DataChange.cliente?.value) {
               const cliente = optionsCliente.find(c => c.value === this.props.DataChange.cliente?.value)
               if (cliente && this.state.mounted > 0) {
                  this.handleChangeCliente(cliente)
               }
            }*/
         }

         this.setState({ "detalhes": this.props.DataChange, "clientes": optionsCliente })
      }))
   }

   handleChangeCliente = (selectedOption) => {
      let { addCampo } = this.state
      addCampo['filtros']['cliente'] = selectedOption
      addCampo['filtros']['monitorado'] = null
      this.props.handleChange('cliente', selectedOption)

      if (selectedOption.value !== null) {
         let userInfo = auth.getUserInfo()
         const bodyFormData = new FormData()
         const filterData = new FormData()

         bodyFormData.append('cliente_id', selectedOption.value)
         filterData.append('item__monitorado__cliente_id', selectedOption.value)

         axios({
            method: 'POST',
            url: cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/monitorado/filtro/',
            data: bodyFormData,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
         })
            .then((response) => {
               let optionsMonitorado = []
               optionsMonitorado.push({ label: 'Nenhum', value: null })

               response.data.forEach((c) => {
                  optionsMonitorado.push({ "value": c.id, "label": c.nome })
               })
               this.setState({ "monitorados": optionsMonitorado })
            })
            .catch(console.error)

         this.handleChangeMonitorado({ label: 'Nenhum', value: null })
      } else {
         let { addCampo } = this.state
         addCampo['filtrados'] = []
         this.setState({ addCampo })
      }
   }

   handleChangeMonitorado = (selectedOption) => {
      let { addCampo } = this.state
      addCampo['filtros']['monitorado'] = selectedOption
      this.setState({ addCampo })

      if (selectedOption.value !== null) {
         let userInfo = auth.getUserInfo()

         axios({
            method: 'GET',
            url: cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + `/monitorado-parametro/?monitorado=${selectedOption.value}`,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
         }).then(res => {
            let { addCampo } = this.state
            let filtrados = []

            res.data?.results?.map(item => {
               filtrados.push({ label: item?.campo?.descricao ?? "---", value: item.campo.id })
            })

            addCampo['filtrados'] = filtrados
            this.setState({ addCampo })
         }).catch(console.error)
      } else {
         let { addCampo } = this.state
         addCampo['filtros']['monitorado'] = null
         this.setState({ addCampo })
      }
   }

   handleCampos = (e, item) => {
      e.stopPropagation()
      let { addCampo } = this.state

      const check = addCampo.selecionados.find((c) => c.value == item.value)

      if (!check) {
         addCampo.selecionados.push({
            label: item.label,
            value: item.value,
         })
      }

      this.setState({ addCampo })
   }

   selecionarTodos = () => {
      let { addCampo } = this.state
      let filtrados = addCampo.filtrados
      let selecionados = addCampo.selecionados

      filtrados.map(item => {
         if (selecionados.filter(s => s.label === item.label).length === 0) {
            selecionados.push(item)
         }
      })

      addCampo['selecionados'] = selecionados
      this.setState({ addCampo })
   }

   adicionar = () => {
      let { addCampo } = this.state
      this.props.adicionar(addCampo.selecionados)
   }

   cancelar = () => {
      this.props.toggleModal()
   }

   deletarCampo = (index) => {
      let { addCampo } = this.state
      addCampo.selecionados.splice(index, 1)
      this.setState({ addCampo })
   }

   isClienteDisabed = () => {
      const { DataChange } = this.props
      const { addCampo } = this.state
      let isDisabled = false

      if (Boolean(addCampo.filtros.cliente?.value) && addCampo.selecionados.length > 0) {
         isDisabled = true
      }

      if (DataChange.disponivel_cliente_final && !addCampo.filtros.cliente) {
         isDisabled = false
      }

      return isDisabled
   }

   render() {
      const { addCampo } = this.state
      const isCliente = auth.getUserInfo().tipo === 'cliente' ? true : false

      return (
         <>
            <Modal isOpen={this.props.modal} toggle={this.props.toggleModal} className="modal-large">
               <ModalHeader toggle={this.props.toggleModal}>
                  Gerenciar Campos
               </ModalHeader>
               <ModalBody>
                  <div className="row">
                     <div className="col-md-6">
                        <div className="row">
                           <div className="col-md-6">
                              <label>Cliente</label>
                              <Select
                                 disabled={isCliente}
                                 value={addCampo.filtros.cliente}
                                 onChange={(e) => this.handleChangeCliente(e)}
                                 options={this.state.clientes}
                              />
                           </div>
                           <div className="col-md-6">
                              <label>Monitorado</label>
                              <Select
                                 value={addCampo.filtros.monitorado}
                                 onChange={(e) => this.handleChangeMonitorado(e)}
                                 options={this.state.monitorados}
                              />
                           </div>
                           <div className="col-md-12">
                              <label className="mt-2">Campos</label>
                              <ul id="multi-item-list" style={{ height: '300px' }}>
                                 {addCampo.filtrados.map((campo, index) => {
                                    const selected = addCampo.selecionados?.filter(i => i.id === campo.value).length > 0

                                    return (
                                       <React.Fragment key={index}>
                                          {
                                             index === 0
                                                ?
                                                <button key="button" onClick={this.selecionarTodos}>Selecionar todos</button>
                                                :
                                                null
                                          }
                                          <label className="d-flex" style={{ margin: '0' }}>
                                             <span className={selected ? "multi-item-selected" : ""}>
                                                {campo.label}
                                             </span>
                                             <button className="multi-item-add" onClick={(e) => this.handleCampos(e, campo)}>
                                                +
                                             </button>
                                          </label>
                                       </React.Fragment>
                                    )
                                 })
                                 }
                              </ul>
                           </div>
                        </div>
                     </div>

                     <div className="col-md-6 row">
                        <div className="col-md-12" id="multi-item-selected">
                           <label>Selecionados</label>
                           {!this.props.validacao.campos ?
                              <>
                                 <br />
                                 <span className={"hdv-required-field"}>* Campo(s) inválido(s)</span>
                              </>
                              :
                              null
                           }
                           {
                              this.state.addCampo.selecionados.map((campo, index) => (
                                 <div className="hdv-multi-report-item" key={index}>
                                    <p>{campo.label}</p>
                                    <div className="d-flex">
                                       <button
                                          onClick={() => this.deletarCampo(index)}
                                          className="hdv-automacao-delete-button">
                                          <i className="fa fa-times fa-2x"></i>
                                       </button>
                                    </div>
                                 </div>
                              ))
                           }
                        </div>
                     </div>
                  </div>
               </ModalBody>
               <ModalFooter>
                  <Button color="default" onClick={this.cancelar}>Cancelar</Button>
                  <Button color="success" onClick={this.adicionar}>Concluir</Button>
               </ModalFooter>
            </Modal>
         </>
      )
   }
}

export default HeaderConsumo