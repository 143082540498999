import React, { Component } from "react"
import Select from "../../components/Select"
import { Redirect } from "react-router-dom"
import auth from '../../utils/auth'
import cfg from '../../utils/config'
import helper from "../../utils/helper"
import axios from 'axios'

class Form extends Component {
    state = {
        detalhes: { descricao: "", rele: "", acao: "", campo: "", operador: "", valor: "" },
        validacao_campo: { descricao: true, rele: true, acao: true, campo: true, operador: true, condicao: true, valor: true },
        redirect: false,
        path: '',
        rele: [],
        campo: [],
        acao: [
            { label: "Ligar", value: true },
            { label: "Desligar", value: false }
        ],
        operador: [
            { label: "Maior ( > )", value: "maior" },
            { label: "Menor ( < )", value: "menor" },
            { label: "Maior ou Igual ( >= )", value: "maior-igual" },
            { label: "Menor ou Igual ( <= )", value: "menor-igual" },
            { label: "Igual ( = )", value: "igual" }
        ],
        automacoes: [],
        edit: false,
        key_selected: '',
        cooldown: false,
        send_disabled: false
    }

    componentWillMount() {
        this.getFields()
        this.getAutomacoes()
    }

    getAutomacoes = () => {
        let userInfo = auth.getUserInfo()

        axios({
            method: 'GET',
            url: cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + "/automacao/afiralink/" + `${this.props.match.params.id}/`,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        })
        .then((res) => {
            let { results } = res.data

            this.setState({ automacoes: results })
        }).catch(error => console.log(error))
    }

    getFields = () => {
        axios({
            method: 'GET',
            url: cfg.base_api_url + cfg.api_v2 + `/${this.props.match.params.id}` + '/automacao/campos-equipamento/',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        })
        .then((data) => {
            let optionsCampo = []
            let optionsRele = []
            let keys = Object.keys(data.data)

            data.data[keys[1]].forEach((field, key) => {
                field['value'] = 'id_' + key

                optionsCampo.push(field)
            })

            for (let i = 1; i <= data.data[keys[0]]; i++) {
                optionsRele.push({ label: `Relé ${i}`, value: i - 1 })
            }

            this.setState({ campo: optionsCampo, rele: optionsRele })
        })
    }

    handleChange = (value, id) => {
        let detalhes = Object.assign({}, this.state.detalhes)

        detalhes[id] = value

        if (id === 'campo') {
            let field = detalhes.campo.field.split('.')[1]

            if (field === 'status') {
                detalhes['valor'] = false
            } else {
                detalhes['valor'] = ''
            }
        }

        this.setState({ detalhes: detalhes })
    }

    changePrioridade = (data, direction) => {
        let { automacoes } = this.state
        let origin = data.prioridade - 1
        let destiny = direction === 'up' ? data.prioridade - 2 : data.prioridade
        let element_origin = automacoes[origin]
        let element_destiny = automacoes[destiny]

        element_destiny['prioridade'] = origin + 1
        element_origin['prioridade'] = destiny + 1

        automacoes[destiny] = element_origin
        automacoes[origin] = element_destiny

        this.setState({ automacoes: automacoes })
    }

    changeCheckBox = (e, key) => {
        this.setState(
            {
                key_selected: e.target.checked ? key : '',
                detalhes: { descricao: "", rele: "", acao: "", campo: "", operador: "", valor: "" },
                validacao_campo: { descricao: true, rele: true, acao: true, campo: true, operador: true, valor: true },
                edit: false,
            }
        )
    }

    addAutomacao = (e) => {
        e.preventDefault()

        const { detalhes, edit, key_selected, automacoes } = this.state
        let validacao = Object.assign({}, this.state.validacao_campo)
        let erro = false

        for (let i in detalhes) {
            if (detalhes[i] === "") {
                validacao[i] = false
                erro = true
            } else {
                validacao[i] = true
            }
        }

        this.setState({ validacao_campo: validacao })

        if (erro) {
            return
        }

        let obj = {
            descricao: detalhes.descricao,
            rele: detalhes.rele.value,
            acao: detalhes.acao.value,
            campo: detalhes.campo.field,
            posicao_campo: detalhes.campo.pos,
            operador: detalhes.operador.value,
            valor: detalhes.valor,
            prioridade: edit ? detalhes.prioridade : automacoes.length + 1
        }

        if (edit) {
            automacoes[key_selected] = obj
        } else {
            automacoes.push(obj)
        }

        this.setState({
            automacoes: automacoes,
            detalhes: { descricao: "", rele: "", acao: "", campo: "", operador: "", valor: "" },
            validacao_campo: { descricao: true, rele: true, acao: true, campo: true, operador: true, valor: true },
            edit: false,
            key_selected: '',
            send_disabled: true
        })

        document.getElementById('descricao').focus()
    }

    editAutomacao = () => {
        let { automacoes, rele, operador, acao, campo, key_selected } = this.state
        let data = automacoes.filter((item, index) => index === key_selected)[0]

        this.setState({
            detalhes: {
                descricao: data.descricao,
                rele: rele.filter((item) => item.value === data.rele)[0],
                acao: acao.filter((item) => item.value === data.acao)[0],
                campo: campo.filter((item) => item.field === data.campo && item.pos === data.posicao_campo)[0],
                operador: operador.filter((item) => (item.value === data.operador))[0],
                valor: data.valor,
                prioridade: data.prioridade
            },
            edit: true
        })
    }

    deleteAutomacao = () => {
        let { automacoes, key_selected } = this.state

        let excluded = automacoes.filter((item, key) => key != key_selected)

        excluded.forEach((item, key) => { item.prioridade = key + 1 })

        this.setState({ automacoes: excluded, edit: false, key_selected: '', send_disabled: true })
    }

    cancelarAutomacao = () => {
        this.setState({
            detalhes: { descricao: "", rele: "", acao: "", campo: "", operador: "", valor: "" },
            validacao_campo: { descricao: true, rele: true, acao: true, campo: true, operador: true, valor: true },
            edit: false,
            key_selected: ''
        })
    }

    messageSend = () => {
        helper.dispatchEvent("showAviso", {
            message: "Tem certeza que deseja enviar as automações? pois caso tenha, as automações que já estão no equipamento serão sobrescritas pelas novas e perderão suas configurações.",
            callback: () => this.send(),
            callbackClose: () => {
                setTimeout(() => {
                    helper.dispatchEvent("showAviso", {
                        message: "Automações não enviadas!",
                    })
                }, 1000)
            }
        })
    }

    send = async () => {
        this.setState({ cooldown: true })
        let userInfo = auth.getUserInfo()

        await axios({
            method: 'POST',
            url: cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + "/enviar-automacao-local/" + `${this.props.match.params.id}/`,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        })
        .then((res) => {
            helper.dispatchEvent("showAviso", {
                message: res.data[0],
                callback: () => this.setState({ redirect: true, path: "/afiralink" }),
            })
        })
        .catch((error) => {
            console.log(error)
        })

        this.setState({ cooldown: false })
    }

    saveAction = async () => {
        this.setState({ cooldown: true })

        let bodyFormData = new FormData()
        let userInfo = auth.getUserInfo()

        bodyFormData.append('automacoes', JSON.stringify(this.state.automacoes))

        await axios({
            method: 'POST',
            url: cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + "/automacao/afiralink/" + `${this.props.match.params.id}/`,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() },
            data: bodyFormData
        })
        .then((res) => {
            helper.dispatchEvent("showAviso", {
                message: "Automações salvas com sucesso.",
                callback: () => this.setState({ redirect: true, path: "/afiralink" }),
            })
        })
        .catch((error) => {
            console.log(error)
        })

        this.setState({ cooldown: false, send_disabled: false })
    }

    render() {
        const { redirect, path, validacao_campo, rele, campo, acao, operador, cooldown, detalhes, edit, automacoes, key_selected, send_disabled } = this.state

        if (redirect) {
            return <Redirect to={path} />
        }

        const field = detalhes.campo != '' ? detalhes.campo.field.split('.')[1] : ''

        return (
            <>
                <div className="hdv-default-header mx-3">
                    <span className="screen-menu-desc">
                        <h4>Automações: {this.props.match.params.imei}</h4>
                        <div className="gray-background">
                            <i className="fa fa-robot fa-2x"></i>
                        </div>
                    </span>
                </div>

                <form className="hdv-form-default">
                    <div className="row">
                        <div className="col-md-4">
                            <label className="required">Descrição</label>
                            <input
                                id="descricao"
                                value={detalhes.descricao}
                                type="text"
                                placeholder="Descrição..."
                                onChange={(e) => this.handleChange(e.target.value, 'descricao')}
                            />
                            <span id="validacao-campo" className={!validacao_campo.descricao ? "" : "hdv-noshow-item"}>
                                Campo não pode ser nulo!
                            </span>
                        </div>
                        <div className="col-md-4">
                            <label className="required">Relé</label>
                            <Select
                                value={detalhes.rele === '' ? {} : detalhes.rele}
                                options={rele}
                                onChange={(e) => this.handleChange(e, 'rele')}
                            />
                            <span id="validacao-valor" className={!validacao_campo.rele ? "" : "hdv-noshow-item"}>
                                Campo não pode ser nulo!
                            </span>
                        </div>
                        <div className="col-md-4">
                            <label className="required">Ação</label>
                            <Select
                                value={detalhes.acao === '' ? {} : detalhes.acao}
                                options={acao}
                                onChange={(e) => this.handleChange(e, 'acao')}
                            />
                            <span id="validacao-valor" className={!validacao_campo.acao ? "" : "hdv-noshow-item"}>
                                Campo não pode ser nulo!
                            </span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <label className="required">Campo</label>
                            <Select
                                value={detalhes.campo === '' ? {} : detalhes.campo}
                                onChange={(e) => this.handleChange(e, 'campo')}
                                options={campo}
                            />
                            <span id="validacao-campo" className={!validacao_campo.campo ? "" : "hdv-noshow-item"}>
                                Campo não pode ser nulo!
                            </span>
                        </div>
                        <div className="col-md-4">
                            <label className="required">Condição</label>
                            <Select
                                value={detalhes.operador === '' ? {} : detalhes.operador}
                                onChange={(e) => this.handleChange(e, 'operador')}
                                options={operador}
                            />
                            <span id="validacao-condicao" className={!validacao_campo.operador ? "" : "hdv-noshow-item"}>
                                Campo não pode ser nulo!
                            </span>
                        </div>
                        <div className="col-md-4">
                            <div style={{ display: field === 'status' ? 'block' : 'none' }}>
                                <label className="required">{detalhes.valor ? 'Ligado' : 'Desligado'}</label>
                                <input
                                    type="checkbox"
                                    checked={detalhes.valor}
                                    onChange={(e) => this.handleChange(e.target.checked, 'valor')}
                                />
                            </div>
                            <div style={{ display: field === 'status' ? 'none' : 'block' }}>
                                <label className="required">Valor</label>
                                <input
                                    value={detalhes.valor}
                                    type="text"
                                    placeholder="Valor..."
                                    onChange={(e) => this.handleChange(e.target.value, 'valor')}
                                />
                                <span id="validacao-valor" className={!validacao_campo.valor ? "" : "hdv-noshow-item"}>
                                    Campo não pode ser nulo!
                                </span>
                            </div>
                        </div>
                    </div>
                </form>

                <div style={{ display: 'flex', justifyContent: 'space-between' }} className="hdv-btn-group">
                    <div>
                        <button
                            onClick={this.addAutomacao}
                            className="hdv-btn-forms hdv-btn-green">
                            {edit ? "Atualizar" : "Adicionar"}
                        </button>
                        <button
                            disabled={key_selected === '' || edit}
                            onClick={this.editAutomacao}
                            className="hdv-btn-forms hdv-btn-yellow">
                            Editar
                        </button>
                        <button
                            disabled={key_selected === '' || edit}
                            onClick={this.deleteAutomacao}
                            className="hdv-btn-forms hdv-btn-red">
                            Excluir
                        </button>
                        <button
                            disabled={!edit}
                            onClick={this.cancelarAutomacao}
                            className="hdv-btn-forms hdv-btn-yellow">
                            Cancelar
                        </button>
                    </div>
                    <div>
                        <button disabled={edit || cooldown} className="hdv-btn-forms hdv-btn-yellow" onClick={() => { this.setState({ path: '/afiralink', redirect: true }) }}>Voltar</button>
                        <button disabled={edit || cooldown} className="hdv-btn-forms hdv-btn-green" onClick={this.saveAction}>Salvar</button>
                        <button style={{ marginRight: '0px' }} onClick={this.messageSend} className="hdv-btn-forms hdv-btn-blue" disabled={edit || cooldown || send_disabled}>Enviar para o equipamento</button>
                    </div>
                </div>

                <div>
                    <table className="table afira-table">
                        <thead>
                            <tr>
                                <th>Descrição</th>
                                <th>Relé</th>
                                <th>Ação</th>
                                <th>Campo</th>
                                <th>Condição</th>
                                <th>Valor</th>
                                <th>Prioridade</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                automacoes.map((item, key) => {
                                    let valor = item.valor === true ? "Ligado" : item.valor === false ? "Desligado" : item.valor

                                    return (
                                        <tr key={key}>
                                            <td title={item.descricao}>
                                                <input
                                                    style={{ marginRight: "20px" }}
                                                    onChange={(e) => this.changeCheckBox(e, key)}
                                                    type="checkbox"
                                                    disabled={edit}
                                                    checked={key_selected === key ? true : false}
                                                />
                                                {item.descricao}
                                            </td>
                                            <td title={item.rele + 1}>{item.rele + 1}</td>
                                            <td title={item.acao ? 'Ligar' : 'Desligar'}>{item.acao ? 'Ligar' : 'Desligar'}</td>
                                            <td title={item.campo}>{item.campo}</td>
                                            <td title={item.operador}>{item.operador}</td>
                                            <td title={valor}>{valor}</td>
                                            <td title={item.prioridade}>{item.prioridade}</td>
                                            <td>
                                                <div>
                                                    <button
                                                        onClick={() => this.changePrioridade(item, 'up')}
                                                        disabled={item.prioridade === 1 || edit}
                                                        style={{ color: 'white', background: '#44bb52', borderRadius: '5px', marginRight: '5px' }}>
                                                        Subir
                                                    </button>
                                                    <button
                                                        onClick={() => this.changePrioridade(item, 'down')}
                                                        disabled={item.prioridade === automacoes.length || edit}
                                                        style={{ color: 'white', background: '#e64444', borderRadius: '5px' }}>
                                                        Descer
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </>
        )
    }
}

export default Form