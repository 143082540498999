import React, {Component} from "react";

class ConfiguracoesCabecalho extends Component {
    
    handleChangeEstrutura = (field, value) => {
        let estrutura = Object.assign({}, this.props.estrutura)
        estrutura[field] = value
        this.props.setEstrutura(estrutura)

        if(field === "quantidadeLinhasCabecalho") {
            this.changeRows(estrutura, value)
        } else {
            this.changeColumns(estrutura, value)
        }
    }

    changeRows = (estrutura, rows) => {
        const colunasCabecalho = estrutura.colunasCabecalho

        if(rows > colunasCabecalho.length) {
            let add = rows - colunasCabecalho.length
            
            for(let linha = 1; linha <= add; linha++) {
                let row = []
                
                for(let coluna = 1; coluna <= estrutura.quantidadeColunasCabecalho; coluna++) {
                    row.push({informacao: "", linha: colunasCabecalho.length, coluna: coluna - 1, rowspan: 1, colspan: 1, disabled: false})
                }

                colunasCabecalho.push(row)
            }
        } else {
            let remove = colunasCabecalho.length - rows

            for(let i = 1; i <= remove; i++) {
                colunasCabecalho.pop()
            }
        }
    }

    changeColumns = (estrutura, columns) => {
        const colunasCabecalho = estrutura.colunasCabecalho
        
        if(colunasCabecalho.length > 0) {
            colunasCabecalho.forEach((linha, indexLinha) => {

                if(columns > linha.length) {
                    let add = columns - linha.length

                    for(let i = 1; i <= add; i++) {
                        linha.push({informacao: "", linha: indexLinha, coluna: linha.length, rowspan: 1, colspan: 1, disabled: false})
                    }

                } else {
                    let remove = linha.length - columns

                    for(let i = 1; i <= remove; i ++) {
                        linha.pop()
                    }
                }

            })
        }
    }

    handleChange = (linha, coluna, value, type) => {
        const estrutura = Object.assign({}, this.props.estrutura)

        if(value === 0) {
            value = 1
        }

        if(type === 'rowspan') {
            this.updateRowSpan(linha, coluna, value)
        } else if(type === 'colspan') {
            this.updateColSpan(linha, coluna, value)
        }

        estrutura.colunasCabecalho[linha][coluna][type] = value

        this.props.setEstrutura(estrutura)
    }

    updateRowSpan = (i, j, value) => {
        const estrutura = Object.assign({}, this.props.estrutura)
        let count = value

        estrutura.colunasCabecalho.forEach((linha, index_linha) => {
            let colspan = estrutura.colunasCabecalho[i][j].colspan - 1

            if(index_linha > i) {
                count -= 1

                if(count < 1) {
                    linha.forEach((coluna, index_coluna) => {
                        if(index_coluna > j) {
                            if(colspan > 0) {
                                coluna.disabled = false
                            }

                            colspan -= 1
                        }                       
                    })

                    linha[j].disabled = false
                } else {
                    linha.forEach((coluna, index_coluna) => {
                        if(index_coluna > j) {
                            if(colspan > 0) {
                                coluna.disabled = true
                            }

                            colspan -= 1
                        }                       
                    })
                    linha[j].disabled = true
                }   
            }
        })
        
        this.props.setEstrutura(estrutura)
    }

    updateColSpan = (i, j, value) => {
        const estrutura = Object.assign({}, this.props.estrutura)
        let count = value

        estrutura.colunasCabecalho[i].forEach((coluna, index_coluna) => {            
            if(index_coluna > j) {
                count -= 1

                if(count < 1) {
                    coluna.disabled = false
                } else {
                    coluna.disabled = true
                }
            }
        })

        let rowspan = estrutura.colunasCabecalho[i][j].rowspan 
        if(rowspan > 1) {
            for(let linha = i; linha < (i + rowspan); linha ++) {
                if(linha !== i) {
                    let colspan = value - 1

                    estrutura.colunasCabecalho[linha].forEach((coluna, index_coluna) => {
                        if(coluna.coluna > j)  {  
                            if(colspan > 0) {
                                coluna.disabled = true
                            } else {
                                coluna.disabled = false
                            }
                            colspan -=1
                        } 
                    })
                }
            }   
        }

        this.props.setEstrutura(estrutura)
    }   

   
    render() {
        const {styleFieldset, estrutura} = this.props

        const style_table = {
            table: {
                borderCollapse: "collapse",
                tableLayout: "auto"
            },
            th_cabecalho: {
                textAlign: "",
                background: "rgb(248 248 248)",
                color: "black",
                verticalAlign: "top",
                width: "400px",
                border: "1px solid black"
            },
            th: {
                textAlign: "center",
                background: "var(--fonte_titulo_abas)",
                color: "var(--fonte_menu_lateral)",
                verticalAlign: "middle",
            },
            th_individual: {
                background: "rgb(222 222 222)",
                textAlign: "center"
            }
        }


        return(
            <fieldset style={{...styleFieldset.fieldset}}>
                <legend style={styleFieldset.legend}>Configurações cabeçalho</legend>

                <div className="row">
                    <div className="col-md-2">
                        <label className="required">Quantidade de linhas</label>
                        <input value={estrutura.quantidadeLinhasCabecalho} min={0} onChange={(e) => {this.handleChangeEstrutura("quantidadeLinhasCabecalho", Number(e.target.value))}} type="number"/>
                    </div>
                    <div className="col-md-2">
                        <label className="required">Quantidade de Colunas</label>
                        <input value={estrutura.quantidadeColunasCabecalho} min={0} onChange={(e) => {this.handleChangeEstrutura("quantidadeColunasCabecalho", Number(e.target.value))}} type="number"/>
                    </div>
                </div>
                <br />
               
                <table style={{ ...style_table.table }} className="table">
                    <thead>
                        {
                            estrutura.colunasCabecalho.map((linha, keyLinha, array_linha) => {
                                let linha_disabled = false
                                
                                if(keyLinha === (array_linha.length - 1)) {
                                    linha_disabled = true
                                }

                                return (
                                    <tr key={keyLinha}>
                                        {
                                            linha.map((coluna, keyColuna, array_coluna) => {
                                                let coluna_disabled = false
                                
                                                if(keyColuna === (array_coluna.length - 1)) {
                                                    coluna_disabled = true
                                                }
                                                
                                                return(
                                                    <th style={style_table.th_cabecalho} key={keyColuna}>
                                                        <div style={{width: "40%", display: "flex", gap: "10px", alignItems: "center"}}>
                                                            <div>
                                                                <label>Linhas</label>
                                                                <input disabled={
                                                                    coluna.disabled || linha_disabled
                                                                } onChange={(e) => this.handleChange(keyLinha, keyColuna, Number(e.target.value), 'rowspan')} style={{height: "30px"}}  min={1} value={coluna?.rowspan} type="number"/>
                                                            </div>
                                                            <div>
                                                                <label>Colunas</label>
                                                                <input disabled={
                                                                    coluna.disabled || coluna_disabled
                                                                } onChange={(e) => this.handleChange(keyLinha, keyColuna, Number(e.target.value), 'colspan')} style={{height: "30px"}} min={1} value={coluna?.colspan} type="number"/>
                                                            </div>
                                                        </div>
                                                        <textarea disabled={coluna.disabled} value={coluna.informacao} style={{width: "100%", marginTop: "15px"}} onChange={(e) => this.handleChange(keyLinha, keyColuna, e.target.value, 'informacao')}></textarea>
                                                    </th>
                                                )
                                            })
                                        }
                                    </tr>
                                )
                            })
                        }
                    </thead>
                </table>
            </fieldset>
        )
    }
}

export default ConfiguracoesCabecalho