import React, { Component } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Badge } from 'reactstrap'
import { Link, Redirect } from "react-router-dom"
import ButtonsGrid from '../../components/ButtonsGrid'
import ConfirmDelete from '../../components/ConfirmDelete'
import Table from '../../components/Table'
import auth from '../../utils/auth'
import cfg from '../../utils/config'
import axios from 'axios'


class Grid extends Component {

    state = {
        data: [],
        loading: true,
        redirectToEdit: false,
        redirectToParam: false,
        modalVirtual: false,
        equipamentoVirtual: false,
        table: null,
    }

    getData = (state) => {
        this.setState({ loading: true })
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }
        let userInfo = auth.getUserInfo()
        let url = cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + `/equipamento/?limit=${state?.lineCount}` + state.filter

        if (state.url) {
            url = state.url
        }

        axios.get(url, config_me).then((res) => {
            let { count, results, next, previous } = res.data

            this.setState({
                loading: false,
                data: results,
                count,
                next,
                previous,
            })
        })
    }

    deletar = () => {
        let requestArray = []
        let userInfo = auth.getUserInfo()
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }

        this.state.table?.state.selected.map((value, index) =>
            requestArray.push(axios.delete(cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/equipamento/' + value + '/', config_me))
        )

        axios.all(requestArray).then(axios.spread((acct, perms) => {
            this.state.table?.fetch()
            this.state.table?.unselect()
        }))
    }

    editar = () => {
        this.setState({
            redirectToEdit: { pathname: "equipamento/" + this.state.table?.state.selected[0] + "/editar" }
        })
        this.state.table?.unselect()
    }

    criarEquipamentoVirtual = () => {
        let userInfo = auth.getUserInfo()

        axios({
            url: cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/equipamento-virtual/',
            method: 'POST',
            headers: { 'Authorization': 'Bearer ' + auth.getToken() }
        }).then((res) => this.setState({ equipamentoVirtual: res.data }))
    }

    render() {
        const { redirectToEdit, equipamentoVirtual, redirectToParam, table } = this.state

        if (redirectToEdit) {
            history.pushState({}, "", location.href)
            return <Redirect to={redirectToEdit} />
        }

        if (redirectToParam) {
            history.pushState({}, "", location.href)
            return <Redirect to={redirectToParam} />
        }

        const columns = [{
            Header: 'Imei',
            Accessor: 'imei',
            Cell: (original) => <Link to={`/equipamento/${original.id}/editar`}>{original?.imei}</Link>
        }, {
            Header: 'Fabricante',
            Accessor: 'fabricante.nome',
        }, {
            Header: 'Modelo',
            Accessor: 'modelo.modelo'
        }, {
            Header: 'Operadora',
            Accessor: 'operadora.nome'
        }, {
            Header: "Gateway",
            Accessor: 'gateway',
            sortable: false,
            Cell: (original) => {
                if (original.gateway) {
                    return <Badge href="#" color="success">Sim</Badge>
                } else {
                    return <Badge href="#" color="secondary">Não</Badge>
                }
            }
        }, {
            Header: 'Status',
            Accessor: 'status',
            sortable: false,
            Cell: (original) => {
                if (original.status === "ativo") {
                    return <Badge href="#" color="success">Ativo</Badge>
                } else if (original.status === "cancelado") {
                    return <Badge href="#" color="secondary">Cancelado</Badge>
                } else if (original.status === "bloqueado") {
                    return <Badge href="#" color="danger">Bloqueado</Badge>
                }
            }
        }, {
            Header: 'Comunicação',
            Accessor: 'tipo_comunicacao',
            sortable: false,
            Cell: ({ tipo_comunicacao }) => {
                let color = ""
                let label = ""

                if (tipo_comunicacao === "outro") {
                    label = "OUTRO"
                    color = "secondary"
                } else if (tipo_comunicacao === "mqtt") {
                    label = "MQTT"
                    color = "primary"
                } else if (tipo_comunicacao === "via_gateway") {
                    label = "GATEWAY"
                    color = "warning"
                } else if (tipo_comunicacao === "http") {
                    label = "HTTP"
                    color = "success"
                } else {
                    return <span>---</span>
                }

                return <Badge href="#" color={color}>{label}</Badge>
            }
        }, {
            Header: 'Data de Criação',
            Accessor: 'data_criado',
            Cell: (original) => {
                let data_formatada = new Date(original.data_criado)
                return data_formatada.toLocaleString('pt-BR')
            }
        }, {
            Header: 'Última Atualização',
            Accessor: 'data_atualizado',
            Cell: (original) => {
                let data_formatada = new Date(original.data_atualizado)
                return data_formatada.toLocaleString('pt-BR')
            }
        }]

        return (
            <>
                <Modal isOpen={this.state.modalVirtual} toggle={() => this.setState({ modalVirtual: false })}>
                    <ModalHeader toggle={() => this.setState({ modalVirtual: false })}>Novo Hidrohub Virtual</ModalHeader>
                    <ModalBody>
                        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                            {equipamentoVirtual
                                ?
                                <>
                                    <strong>Aviso</strong>
                                    Equipamento {equipamentoVirtual.id} criado!
                                </>
                                :
                                <div style={{ height: '35px' }}></div>
                            }
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        {Boolean(this.state.equipamentoVirtual)
                            ?
                            <button className="hdv-btn-forms hdv-btn-blue" onClick={() => { this.state?.table?.fetch(); this.setState({ modalVirtual: false }) }}>
                                Concluído
                            </button>
                            :
                            <button className="hdv-btn-forms hdv-btn-green" onClick={this.criarEquipamentoVirtual}>Criar</button>
                        }
                    </ModalFooter>
                </Modal>

                <div>
                    <div className="hdv-default-header mx-3">
                        <span className="screen-menu-desc">
                            <h4>Cadastro de Equipamentos</h4>
                            <div className="gray-background">
                                <i className="fa fa-hdd fa-2x"></i>
                            </div>
                        </span>
                    </div>

                    <div className="hdv-btn-group" style={{ display: 'flex', alignItems: 'center' }}>
                        <ButtonsGrid
                            route="/equipamento"
                            buttons={[
                                {
                                    method: "post",
                                    button: <Link to="/equipamento/novo"><button className="hdv-btn-forms hdv-btn-green">Novo</button></Link>
                                },

                                {
                                    method: "patch",
                                    button: <button onClick={() => { if (table?.state.enableEditar) this.editar() }} className={"hdv-btn-forms hdv-btn-yellow " + (table?.state.enableEditar ? "" : "hdv-btn-disable")}>Editar</button>
                                },

                                {
                                    method: "delete",
                                    button: <ConfirmDelete enabled={table?.state.enableDeletar} action={this.deletar} />
                                }
                            ]}
                        />

                        <button
                            onClick={() => this.setState({ modalVirtual: true, equipamentoVirtual: false })}
                            className="hdv-btn-forms hdv-btn-blue">
                            Novo Equipamento Virtual
                        </button>
                    </div>

                    <Table
                        checkbox
                        data={this.state.data}
                        loading={this.state.loading}
                        count={this.state.count}
                        next={this.state.next}
                        previous={this.state.previous}
                        columns={columns}
                        onFetchData={this.getData}
                        onUpdate={(table) => this.setState({ table })}
                    />
                </div>
            </>
        )
    }
}

export default Grid
