import React from "react"
import { Link, Redirect } from "react-router-dom"
import auth from '../../utils/auth'
import cfg from '../../utils/config'
import helper from "../../utils/helper"
import axios from 'axios'

class FormNovo extends React.Component {
    userInfo = auth.getUserInfo()
    configMe = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }

    state = {
        detalhesDefault: { descricao: "" },
        validacao_formulario: { descricao: true },
        redirect: false,
    }

    handleChangeRelatorio = (e, field) => {
        const { detalhesDefault } = this.state
        detalhesDefault[field] = e
        this.setState({ detalhesDefault })
    }

    saveAction = () => {
        let { detalhesDefault } = this.state
        let not_validate = false
        let validate_form = this.state.validacao_formulario

        for (let key in validate_form) {
            if (!detalhesDefault[key]) {
                validate_form[key] = false
                not_validate = true
            } else
                validate_form[key] = true
        }

        this.setState({ validacao_formulario: validate_form, carregandoDados: false })

        if (not_validate) {
            window.scrollTo(0, 50)
            return
        }

        let verb = 'POST'
        let url = cfg.base_api_url + cfg.api_v2 + `/${this.userInfo.empresa}/relatorio/`
        if (this.props.match.params.id) {
            verb = 'PATCH'
            url = cfg.base_api_url + cfg.api_v2 + `/${this.userInfo.empresa}/relatorio/${this.props.match.params.id}/`
        }

        const bodyFormData = new FormData()

        bodyFormData.append("descricao", detalhesDefault.descricao)
        bodyFormData.append("disponivel_cliente_final", detalhesDefault.disponivel_cliente_final ? '1' : '0')

        axios({ method: verb, url: url, data: bodyFormData, ...this.configMe }).then(res => {
            helper.dispatchEvent("showAviso", {
                message: 'Relatorio salvo com sucesso',
                callback: () => this.setState({ redirect: true, path: "/relatorio-customizado" })
            })
        })
    }

    render() {
        let { detalhesDefault } = this.state
        if (this.state.redirect)
            return <Redirect to="/relatorio-customizado" />

        return (
            <>
                <div className="hdv-default-header mx-3">
                    <span className="screen-menu-desc">
                        <h4>Relatório Customizado</h4>
                        <div className="gray-background">
                            <i className="fa fa-file-alt fa-2x"></i>
                        </div>
                    </span>
                </div>
                <form className="row">
                    <div className="col-md-12">
                        <label className="required ">Descrição</label>
                        <input type="text" onChange={(e) => this.handleChangeRelatorio(e.target.value, 'descricao')} value={detalhesDefault.descricao} />
                        <span className={(this.state.validacao_formulario.descricao === false) ? "hdv-required-field" : "hdv-required-field hdv-noshow-item"}>Campo Obrigatório</span>
                    </div>
                </form>

                <div className="hdv-btn-group mt-5">
                    <button onClick={this.saveAction} className="hdv-btn-forms hdv-btn-green" disabled={this.state.cooldown || this.state.relatorioErro}>
                        Salvar
                    </button>
                    <Link to="/relatorio-customizado">
                        <button className="hdv-btn-forms hdv-btn-yellow">Cancelar</button>
                    </Link>
                </div>
            </>
        )
    }
}

export default FormNovo