import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import Select from './Select'
import auth from '../utils/auth'
import cfg from '../utils/config'
import axios from 'axios'

import {
    Collapse,
    Navbar,
    NavbarToggler,
    Nav,
    NavItem,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Modal,
    ModalHeader,
    ModalBody,
} from 'reactstrap'

class MenuBar extends Component {
    constructor(props) {
        super(props)
        this.toggle = this.toggle.bind(this)

        let empresaAtual, empresaPai

        try {
            const me = localStorage.getItem('__hidroview__me')
            empresaAtual = JSON.parse(me)?.empresa
            empresaPai = JSON.parse(me)?.pai
        } catch (err) {
            console.error(err)
        }

        this.state = {
            isOpen: false,
            alertCount: 0,
            intervalFn: false,
            search: { value: "", open: false, equipamentos: [], monitorados: [] },
            modal: false,
            empresas: [],
            empresaSelecionada: false,
            redirect: false,
            path: "",
            empresaAtual: empresaAtual,
            empresaPai: empresaPai,
            userInfo: auth.getUserInfo()
        }
    }

    componentWillMount = () => {
        let userInfo = auth.getUserInfo()
        this.setState({ imagem_perfil: userInfo['imagem_perfil'] })
        let endpoints = auth.getUserInfo()?.menu?.find(m => m.item === "base")?.endpoints ?? []

        if (auth.isMasterOrAdmin() || endpoints.includes("/alerta")) {
            this.checarAlertas()
            let intervalFn = setInterval(this.checarAlertas.bind(this), 60_000)
            this.setState({ intervalFn })
        }
    }

    componentWillUnmount = () => {
        clearInterval(this.state.intervalFn)
    }

    getEmpresas = () => {
        let empresaId = auth.getUserInfo().empresa
        let empresaPai = JSON.parse(localStorage.getItem('__hidroview__me'))
        if (empresaPai.pai)
            empresaId = empresaPai.pai

        const config = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }
        axios.get(cfg.base_api_url + cfg.api_v2 + `/${empresaId}/empresa/`, config)
            .then(res => this.setState({ empresas: res.data?.results ?? [] }))
            .catch(console.error)
    }

    acessarEmpresa = () => {
        let { empresaSelecionada } = this.state
        if (!empresaSelecionada) return

        let mudanca = JSON.parse(localStorage.getItem('__hidroview__me'))
        mudanca.empresa = empresaSelecionada.id
        auth.setUserInfo(mudanca, true)
        window.location.reload()
    }

    retornarAoPai = () => {
        let mudanca = JSON.parse(localStorage.getItem('__hidroview__me'))
        mudanca.empresa = this.state.empresaPai
        auth.setUserInfo(mudanca, true)
        window.location.reload()
    }

    toggle() {
        this.setState({ isOpen: !this.state.isOpen })
    }

    toggleModal = () => {
        this.setState({ modal: !this.state.modal })
    }

    checarAlertas = () => {
        let userInfo = auth.getUserInfo()
        let requestCfg = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }

        if (document.hasFocus()) {
            if (userInfo?.['empresa']) {
                axios.get(cfg.base_api_url + cfg.api_v2 + '/' + userInfo?.['empresa'] + '/checar-alerta/', requestCfg)
                    .then(res => this.setState({ alertCount: res.data.count }))
                    .catch(console.error)
            }
        }
    }

    buscar = (e) => {
        if (e.target.value.length >= 2 && e.keyCode === 13) {
            let userInfo = auth.getUserInfo()
            let configMe = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }
            const requestUrl = cfg.base_api_url + cfg.api_v2 + '/' + userInfo['empresa'] + '/buscar-recurso/' + e.target.value

            axios.get(requestUrl, configMe).then((res) => {
                this.setState({ search: { value: e.target.value, open: true, ...res.data } })
            })
        }
    }

    limpaPesquisa = () => {
        this.setState({ search: { value: "", open: false, equipamentos: [], monitorados: [] } })
    }

    redirect = (path) => {
        this.setState({ redirect: true, path })
    }

    getToken = () => {
        navigator.clipboard.writeText(auth.getToken())
    }

    render() {
        let { redirect, path } = this.state

        if (redirect) {
            return <Redirect to={{ pathname: '/redirect', state: { pathto: path } }} />
        }

        const { empresaAtual, empresaPai, search } = this.state
        const colourStyles = {
            option: (styles) => {
                return {
                    ...styles,
                    color: '#000',
                    display: 'flex',
                    justifyContent: 'space-between'
                }
            },
        }

        let empresaOption = []
        if (this.state.empresas.length > 0) {
            this.state.empresas.map((empresa, index) => {
                if (empresa.id !== empresaAtual) {
                    empresaOption.push(
                        {
                            value: index,
                            label: empresa.nome_fantasia,
                            empresa: empresa
                        }
                    )
                }
                return null
            })
        }

        return (
            <div className="hdv-top-menu-position">
                <Navbar className="hdv-menubar" color="" light expand="md">
                    <Nav>
                        <NavItem>
                            <div className="search-bar">
                                <input
                                    className="hdv-search-home-field"
                                    type="text"
                                    name="search"
                                    id="search"
                                    value={search.value}
                                    placeholder="Pesquisar..."
                                    onKeyDown={this.buscar}
                                    onChange={(e) => {
                                        let { search } = this.state

                                        if (e.target.value.length === 0) {
                                            this.setState({ search: { value: "", open: false, equipamentos: [], monitorados: [] } })
                                        } else {
                                            search['value'] = e.target.value
                                            this.setState({ search })
                                        }
                                    }}
                                />
                                <button onClick={this.limpaPesquisa}>
                                    <i className={search.value.length > 0 ? "fa fa-times fa-3x search-input-icon" : "fa fa-search fa-3x search-input-icon"}></i>
                                </button>
                            </div>
                            <div className={search.open ? "hdv-search-results" : "hdv-noshow-item"}>
                                {search.monitorados.length > 0 ?
                                    <ul>
                                        <span>Monitorados</span>
                                        {search.monitorados.map((monitorado, index) => (
                                            <li key={index} onClick={() => this.redirect(`/status/${monitorado.id}`)}>
                                                {monitorado.nome}
                                            </li>
                                        ))
                                        }
                                    </ul>
                                    :
                                    null
                                }
                                {search.equipamentos.length > 0 ?
                                    <ul>
                                        <span>Equipamentos</span>
                                        {search.equipamentos.map((equip, index) => (
                                            <li key={index} onClick={() => this.redirect(`/equipamento/${equip.id}/editar/`)}>
                                                {equip.imei}
                                            </li>
                                        ))
                                        }
                                    </ul>
                                    :
                                    null
                                }
                            </div>
                        </NavItem>
                    </Nav>
                    <NavbarToggler onClick={this.toggle} />
                    <Collapse isOpen={this.state.isOpen} navbar>
                        <Nav className="ml-auto d-flex align-items-center" navbar>
                            <NavItem>
                                <Link to="/lista-alertas" className="nav-link hdv-navbar-item" style={{ position: "relative" }}>
                                    <i className="fa fa-bell fa-2x hdv-menu-image-top"></i>
                                    <span
                                        style={(this.state.alertCount === 0) ? { 'backgroundColor': 'silver' } : { 'backgroundColor': '#d94545' }}
                                        className="hdv-alert-receive">{this.state.alertCount}
                                    </span>
                                </Link>
                            </NavItem>

                            <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle nav className="hdv-navbar-item">
                                    <span className="user-name">{auth.getUserInfo().nome}</span>
                                    <span className="hdv-image-round" style={{
                                        backgroundImage: "url(" + this.state.imagem_perfil + ")",
                                        backgroundPosition: 'center',
                                        backgroundSize: 'cover',
                                        backgroundRepeat: 'no-repeat',
                                        width: '50px',
                                        height: '50px',
                                    }}>
                                    </span>
                                </DropdownToggle>
                                <DropdownMenu className="mini-modal" right>
                                    {!this.state.userInfo?.cliente
                                        ?
                                        <DropdownItem tag="a" onClick={() => { this.toggleModal(); this.getEmpresas() }}>
                                            <span className="menu-bar-dropdown-item">
                                                <i className="fa fa-industry fa-2x"></i>
                                                Outras empresas
                                            </span>
                                        </DropdownItem>
                                        :
                                        null
                                    }
                                    {process.env.NODE_ENV === 'development'
                                        ?
                                        <DropdownItem tag="a" onClick={this.getToken}>
                                            <span className="menu-bar-dropdown-item">
                                                <i className="fa fa-key fa-2x"></i>
                                                Obter Token
                                            </span>
                                        </DropdownItem>
                                        :
                                        null
                                    }

                                    {!this.state.userInfo?.cliente ?
                                        <DropdownItem divider />
                                        : ""
                                    }
                                    <DropdownItem tag="a" onClick={() => { auth.clearAppStorage(); window.location.reload() }}>
                                        <span className="menu-bar-dropdown-item">
                                            <i className="fa fa-times fa-2x"></i> Sair
                                        </span>
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Nav>
                    </Collapse>
                </Navbar>
                <Modal isOpen={this.state.modal} toggle={this.toggleModal}>
                    <ModalHeader toggle={this.toggleModal}>Acessar Empresa</ModalHeader>
                    <ModalBody>
                        <label>Empresas</label>
                        <Select
                            onChange={(e) => this.setState({ empresaSelecionada: e.empresa })}
                            options={empresaOption}
                            placeholder="Selecione"
                            styles={colourStyles}
                        />

                        <div className="float-right justify-content-between" style={{ marginTop: '25px' }}>
                            <button
                                className='hdv-btn-forms mx-0 mr-1'
                                style={{
                                    padding: "0 12px",
                                    color: empresaAtual === empresaPai ? '#444' : '#fff',
                                    backgroundColor: empresaAtual === empresaPai ? "#6c757d" : "#007bff"
                                }}
                                disabled={empresaAtual === empresaPai}
                                onClick={() => this.retornarAoPai()}>
                                Retornar à Empresa Principal
                            </button>
                            {' '}
                            <button
                                className="hdv-btn-forms hdv-btn-blue mx-0 mr-1"
                                style={{ padding: "0 12px" }}
                                disabled={!Boolean(this.state.empresaSelecionada)}
                                onClick={() => this.acessarEmpresa()}>
                                Acessar
                            </button>
                            {' '}
                            <button
                                className="hdv-btn-forms mx-0 hdv-btn-default"
                                style={{ padding: "0 12px" }}
                                onClick={() => this.toggleModal()}>
                                Fechar
                            </button>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}

export default MenuBar