import React, { Component } from 'react'
import HeaderConsumo from './components/HeaderConsumo'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import ReactLoading from 'react-loading'
import CsvDownloader from 'react-csv-downloader'
import { Link, Redirect } from 'react-router-dom'
import ReactEcharts from "echarts-for-react"
import Select from '../../components/Select'
import { DateRangePicker } from 'react-date-range'
import * as rdrLocales from 'react-date-range/dist/locale'
import { InputGroup, Input, InputGroupAddon, Button } from 'reactstrap'
import CurrencyInput from 'react-currency-input-field'
import ButtonsForm from '../../components/ButtonsForm'
import domToImage from 'dom-to-image'
import pdfMake from 'pdfmake'
import helper from '../../utils/helper'
import auth from '../../utils/auth'
import cfg from '../../utils/config'
import axios from 'axios'

pdfMake.fonts = {
   Roboto: {
      normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
      bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
      italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
   }
}

const BRAZILIANREAL = new Intl.NumberFormat('pt-BR', {
   style: 'currency',
   currency: 'BRL',
})

class Form extends Component {
   userInfo = auth.getUserInfo()
   config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }

   state = {
      detalhesDefault: {
         descricao: "",
         unidade: { label: 'm3', value: 'm3' },
         data: "",
         agrupar: "",
         cliente: "",
         valor_unidade: '0',
         campos: [],
      },
      modal: false,
      dataFormatada: "",
      datePickerShow: false,
      dateRange: { startDate: new Date(), endDate: new Date(), key: 'selection' },
      hidrometrosOption: [],
      configuracaoSelecionada: null,
      validacao: { data: true, agrupar: true, descricao: true, campos: true },
      carregando: false,
      relatorioGerado: false,
      chart: null,
      table: null,
      csv: { data: [], columns: [] },
      tabIndex: 0,
   }

   componentDidMount() {
      if (this.props.match.params.id) {
         this.getData()
      }

      window.addEventListener('click', this.checarCalendarioAberto, false)
   }

   checarCalendarioAberto = (e) => {
      if (this.state.checkClose === true) {
         let retArray = e.path?.filter((path) => {
            if (path.className === "hdv-datepicker") {
               return true
            }
            return false
         })

         if (retArray?.length === 0) {
            this.setState({ "datePickerShow": false, checkClose: false })
         }
      } else {
         this.setState({ checkClose: true })
      }

      return true
   }

   getData() {
      axios({
         method: 'GET',
         url: cfg.base_api_url + cfg.api_v2 + '/' + this.userInfo.empresa + `/relatorio/consumo/${this.props.match.params.id}`,
         headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
      }).then(res => {
         let campos = []
         let { detalhesDefault } = this.state

         if (res.data?.campos) {
            res.data.campos?.map((campo) => campos.push(campo))
         }

         if (Boolean(res.data.cliente)) {
            detalhesDefault['cliente'] = { label: "", value: res.data.cliente }
         }

         detalhesDefault['campos'] = campos
         detalhesDefault['descricao'] = res.data.descricao
         this.setState({ detalhesDefault })
      })
   }

   saveAction = () => {
      let { detalhesDefault } = this.state
      let notValid = false
      let validateForm = this.state.validacao
      let campos = []
      let verb, url

      const except = ['modal', 'data', 'agrupar', 'campos', 'unidade', 'cliente', 'valor_unidade']
      for (let key in detalhesDefault) {
         if (!detalhesDefault[key] && !except.includes(key)) {
            validateForm[key] = false
            notValid = true
         } else validateForm[key] = true
      }

      if (notValid === true) {
         this.setState({ validacao: validateForm })
         return
      }

      this.setState({ cooldown: true })

      detalhesDefault.campos.map(campo => campos.push(campo.id))

      const bodyFormData = new FormData()
      bodyFormData.append('descricao', detalhesDefault.descricao)
      bodyFormData.append('campos', JSON.stringify(campos))

      if (detalhesDefault.cliente) {
         bodyFormData.append('cliente_id', detalhesDefault.cliente?.value)
      }

      if (this.props.match.params.id) {
         verb = 'PATCH'
         url = cfg.base_api_url + cfg.api_v2 + '/' + this.userInfo?.empresa + `/relatorio/consumo/${this.props.match.params.id}/`
      } else {
         verb = 'POST'
         url = cfg.base_api_url + cfg.api_v2 + '/' + this.userInfo?.empresa + `/relatorio/consumo/`
      }

      axios({
         method: verb,
         url: url,
         data: bodyFormData,
         headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
      }).then((res) => {
         helper.dispatchEvent("showAviso", {
            message: "Relatorio salvo com sucesso",
            callback: () => this.setState({
               redirect: true,
               path: "/relatorio-consumo",
               relatorioGerado: false,
               chart: null,
               table: null
            })
         })
      }).catch(console.error)

      setTimeout(() => this.setState({ cooldown: false }), 2000)
   }

   buscarDadosRelatorio = async () => {
      const { detalhesDefault } = this.state
      let dispositivos = []
      let notValid = false
      let validateForm = this.state.validacao
      let chart = { series: [], xAxis: [] }

      this.setState({ carregando: true, relatorioGerado: false, chart: null, table: null })

      const except = ['modal', 'descricao', 'campos', 'unidade', 'cliente', 'valor_unidade']

      for (let key in detalhesDefault) {
         if (!detalhesDefault[key] && !except.includes(key)) {
            validateForm[key] = false
            notValid = true
         } else validateForm[key] = true
      }

      if (notValid) {
         validateForm["campos"] = detalhesDefault.campos.length > 0
         this.setState({ validacao: validateForm, carregando: false })
         return
      }

      if (detalhesDefault.campos.length > 0) {
         detalhesDefault.campos.map(campo => {
            dispositivos.push({ id: campo.usuario_mqtt, field: campo.identificacao })
         })

         await axios({
            method: 'POST',
            url: cfg.base_api_url + cfg.api_v2 + '/' + this.userInfo?.empresa + '/reporte/consumo/',
            data: {
               "timestamp_dispositivo_ini": detalhesDefault.data?.split('-')[0].split('/').reverse().join('-') + ' 00:00:00',
               "timestamp_dispositivo_fim": detalhesDefault.data?.split('-')[1].split('/').reverse().join('-') + ' 23:59:59',
               "grupo": detalhesDefault.agrupar?.value,
               "dispositivos": dispositivos
            },
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
         })
            .then(res => {
               let data = res.data
               let chartData = []
               let csv = { data: [], columns: [] }
               let total = res.data?.data?.TOTAL
               let table = { total: total, itens: [] }
               const keys = []

               Object.keys(data.config.RANGE).map(key => keys.push({ key, ...data.config.RANGE[key] }))
               keys.sort((a, b) => {
                  return new Date(a.MYTIMEZONE.end).getTime() - new Date(b.MYTIMEZONE.end).getTime()
               })

               keys.map(key => chart.xAxis.push(Number(key.key)))
               chart.xAxis.map((key, index) => {
                  if (data.data.TOTAL_GROUP[key]) {
                     chartData.push(data.data.TOTAL_GROUP[key].diff)
                  } else {
                     chartData.push(0)
                  }
               })

               if (res.data.data.SENSOR) {
                  for (let key in res.data.data.SENSOR) {
                     let monitorados = res.data.data.SENSOR?.[key]?.place
                     let keys = Object.keys(res.data.data.SENSOR[key].value)
                     let value = Number(res.data.data.SENSOR[key].total).toFixed(2)
                     let start = res.data.data.SENSOR[key].value[keys[0]].ini
                     let end = res.data.data.SENSOR[key].value[keys[keys.length - 1]].end

                     let split = key.split("_")
                     const id = split[0]
                     let imei = detalhesDefault.campos?.find(c => c.usuario_mqtt == id)?.equipamento_imei
                     split.shift()

                     table.itens.push({
                        id: id,
                        label: id,
                        campo: `${split.join("_")}`,
                        imei: imei,
                        monitorados: monitorados,
                        consumo: value,
                        valor_inicial: parseFloat(start).toFixed(2),
                        valor_final: parseFloat(end).toFixed(2),
                        porcentagem: (Number(value) !== 0 ? (value / total) * 100 : 0).toFixed(2)
                     })

                     csv.data.push({
                        c0: `${split.join("_")}`,
                        c1: String(parseFloat(start).toFixed(2)).replace('.', ','),
                        c2: String(parseFloat(end).toFixed(2)).replace('.', ','),
                        c3: String(value).replace('.', ','),
                        c4: BRAZILIANREAL.format(Number(Number(value).toFixed(2)) * parseFloat(detalhesDefault.valor_unidade.replace('.', '').replace(',', '.')))
                     })
                  }
               }

               csv.data.push({ c3: 'Total', c4: total })
               chart.series.push({ type: 'line', data: chartData })

               csv.columns = [
                  { id: 'c0', displayName: 'Campo' },
                  { id: 'c1', displayName: 'Leitura Inicial' },
                  { id: 'c2', displayName: 'Leitura Final' },
                  { id: 'c3', displayName: 'Consumo' },
                  { id: 'c4', displayName: 'Valor' }
               ]

               this.setState({ relatorioGerado: true, carregando: false, chart, table, csv })
            })
            .catch(console.error)
      }

      this.setState({ carregando: false })
   }

   formatTooltip = (params) => {
      const { detalhesDefault } = this.state

      if (Array.isArray(params)) {
         let formatted = ""
         for (let i = 0; i < params.length; i++) {
            formatted += `<div style="display: flex; align-items: center;"><div style="background-color: ${params?.[i]?.color}; height: 14px; width: 14px; border-radius: 7px; margin-right: 5px"></div>`
            formatted += `${params[i]?.seriesName}:&nbsp; <b>${(detalhesDefault.unidade?.value == "horas") ? helper.decimalToTime(Number(Number(params[i].data).toFixed(2))) : Number(Number(params[i].data).toFixed(2)).toLocaleString("pt-BR")}</b>`
            formatted += (i !== (params.length - 1)) ? "</div>\n" : "</div>"
         }

         return formatted
      }

      return ""
   }

   handleChange = (key, value) => {
      let { detalhesDefault } = this.state
      detalhesDefault[key] = value

      this.setState({ detalhesDefault: detalhesDefault })
   }

   adicionar = (itens) => {
      let { detalhesDefault } = this.state
      let bodyFormData = new FormData()
      let campos = []

      for (let campo of itens) {
         campos.push(campo.value)
      }

      bodyFormData.append('campos', JSON.stringify(campos))

      if (this.props.match.params.id) {
         axios({
            method: 'PATCH',
            url: cfg.base_api_url + cfg.api_v2 + '/' + this.userInfo.empresa + `/relatorio/consumo/${this.props.match.params.id}/`,
            data: bodyFormData,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
         })
            .then((res) => {
               if (res.data?.campos) {
                  detalhesDefault['campos'] = itens = res.data.campos
               }

               this.setState({ detalhesDefault, relatorioGerado: false, chart: null, table: null })
               this.toggleModal()
            })
            .catch(console.error)
      } else {
         detalhesDefault['campos'] = itens
         this.setState({ detalhesDefault })
         this.toggleModal()
      }
   }

   toggleModal = () => {
      let { validacao } = this.state
      for (let key in validacao) {
         validacao[key] = true
      }

      this.setState({ validacao, modal: false })
   }

   gerarGrafico = () => {
      const { chart, detalhesDefault } = this.state
      const axisType = { day: 'Dias', month: 'Meses', hour: 'Horas', irregular: 'Período Específico', fixo: 'Período Fixo' }
      const labelDesc = { day: '(Dia)', month: '(Mês)', hour: '(Hora)', irregular: '(Período Específico)', fixo: '(Período Fixo)' }
      let series = []

      series = chart?.series?.map((serie) => {
         if (serie.data.length > 0) {
            let label = {
               show: true,
               position: 'top',
               color: "rgba(0, 0, 0, 0.85)",
               fontWeight: '600',
               textBorderColor: '#fff',
               textBorderWidth: 2,
               distance: 5,
               fontSize: 12,
               rotate: chart.xAxis.length > 20 ? 55 : 0,
               formatter: (params) => !isNaN(params.value) ? (detalhesDefault.unidade?.value == "horas") ? helper.decimalToTime(Number(Number(params.data).toFixed(2))) : Number(Number(params.data).toFixed(2)).toLocaleString("pt-BR") : (detalhesDefault.unidade?.value == "horas") ? helper.decimalToTime(Number(Number(params.value).toFixed(2))) : Number(Number(params.value).toFixed(2)).toLocaleString("pt-BR")
            }

            return {
               name: `Total ${labelDesc[detalhesDefault.agrupar?.value]}`,
               data: serie.data,
               type: serie.type,
               itemStyle: { color: '#4287f5', opacity: 1 },
               lineStyle: { width: 2 },
               labelLayout: { hideOverlap: true },
               label: label,
               areaStyle: { opacity: 0.5 }
            }
         }
         return null
      }).filter(s => s !== null)

      return (
         <div className="chart-item" style={{ padding: '3px' }}>
            <h5 style={{ textAlign: 'center' }}>Relatório de Consumo</h5>

            <div className="d-flex justify-content-between mt-2">
               <strong style={{ fontSize: '20px' }}>Consumo Total</strong>
               <span style={{ fontSize: '20px', opacity: '0.9', fontWeight: 'bold' }}>
                  {(detalhesDefault.unidade?.value == "horas") ? helper.decimalToTime(Number(Number(this.state.table.total).toFixed(2))) : Number(Number(this.state.table.total).toFixed(2)).toLocaleString("pt-BR")}
                  {detalhesDefault.unidade?.value ? ` (${detalhesDefault.unidade?.value})` : null}
               </span>
            </div>
            <div className="hdv-chart">
               {series?.length > 0 ?
                  <ReactEcharts
                     style={{ height: '360px', width: '100%' }}
                     option={{
                        series: series,
                        xAxis: {
                           data: chart?.xAxis,
                           name: axisType[detalhesDefault.agrupar.value],
                           nameTextStyle: { fontWeight: '600', fontSize: '12' },
                           nameLocation: 'center',
                           nameGap: 22
                        },
                        yAxis: {
                           type: 'value',
                           max: (value) => {
                              if (value?.max < 0)
                                 return Math.ceil((value.max + (Math.abs(value.max) * 0.1)))
                              return Math.ceil((value.max + (Math.abs(value.max) * 0.1)))
                           },
                           min: (value) => Math.floor(value.min - (Math.abs(value.min) * 0.6)),
                        },
                        grid: { left: 60, right: 20, bottom: 60 },
                        tooltip: {
                           show: true,
                           trigger: "axis",
                           axisPointer: { type: "shadow", label: { show: true } },
                           formatter: (params) => this.formatTooltip(params)
                        }
                     }} />
                  :
                  <p style={{ margin: '12px 0', textAlign: 'center', color: '#a33131', fontWeight: 'bold' }}>Erro ao carregar gráfico</p>
               }
            </div>
            <hr />
         </div>
      )
   }

   getBase64ImageFromURL = (url) => {
      return new Promise((resolve, reject) => {
         let img = new Image()
         img.setAttribute("crossOrigin", "anonymous")

         img.onload = () => {
            let canvas = document.createElement("canvas")
            canvas.width = img.width
            canvas.height = img.height

            let ctx = canvas.getContext("2d")
            ctx.drawImage(img, 0, 0)

            let dataURL = canvas.toDataURL("image/png")

            resolve(dataURL)
         }

         img.onerror = error => reject(error)

         img.src = url
      })
   }

   exportarPDF = async () => {
      const { detalhesDefault } = this.state
      let chart = true
      let logo = true

      let infoEmpresa
      await axios.get(cfg.base_api_url + cfg.api_v2 + `/${this.userInfo.empresa}/configuracao-sistema`, this.config_me).then(res => { infoEmpresa = res.data?.data })

      let prepare_data = []

      this.state.table?.itens?.forEach((evt, key) => {
         prepare_data.push([
            { text: evt.label },
            { text: evt.monitorados},
            { text: evt.campo },
            { text: String(evt.valor_inicial).replace(".", ",") },
            { text: String(evt.valor_final).replace(".", ",") },
            { text: String(evt.porcentagem).replace(".", ",") },
            { text: String((detalhesDefault.unidade?.value == "horas") ? helper.decimalToTime(Number(Number(evt.consumo).toFixed(2))) : Number(Number(evt.consumo).toFixed(2)).toLocaleString("pt-BR")) + " " + (detalhesDefault.unidade?.value ? detalhesDefault.unidade?.value : null) },
            { text: BRAZILIANREAL.format(Number(Number(evt.consumo).toFixed(2)) * parseFloat(detalhesDefault.valor_unidade.replace('.', '').replace(',', '.'))) },
         ]
         )
      })

      let total_volume = (detalhesDefault.unidade?.value == "horas") ? helper.decimalToTime(Number(Number(this.state.table.total).toFixed(2))) : Number(Number(this.state.table.total).toFixed(2)).toLocaleString("pt-BR")
      total_volume = String(total_volume) + " " + (detalhesDefault.unidade?.value ? detalhesDefault.unidade?.value : null)

      prepare_data.push([
         { text: 'Resumo do Período', colSpan: 6, fillColor: '#d5d5d5' },
         {},
         {},
         {},
         {},
         {},
         { text: total_volume, fillColor: '#d5d5d5' },
         { text: BRAZILIANREAL.format(Number(Number(this.state.table.total).toFixed(2)) * parseFloat(detalhesDefault.valor_unidade.replace('.', '').replace(',', '.'))), fillColor: '#d5d5d5' },
      ])

      let header = []
      let content = [
         {
            columns: [
               {
                  width: '*',
                  text: 'Relatório de Consumo',
                  margin: [0, 50, 0, 0],
                  fontSize: 18,
                  bold: true,
               },
               {
                  width: 360,
                  margin: [0, 0, 10, 0],
                  alignment: 'right',
                  text: [infoEmpresa?.email_contato, '\n', infoEmpresa?.endereco, '\n', infoEmpresa?.telefone, "\n"]
               },
               (logo) ? {
                  width: 100,
                  heigth: 100,
                  image: await this.getBase64ImageFromURL(document.querySelector('div.hdv-logomarca img').src),
               } : null,
            ]
         }, {
            text: ".",
            color: "white"
         }, {
            table: {
               headerRows: 1,
               widths: ["*"],
               body: [
                  ["Relatório: " + detalhesDefault?.descricao + " - Período: " + detalhesDefault.data?.replace('-', ' à ')],
               ]
            },
            layout: {
               hLineWidth: function (i, node) {
                  return (i === 0 || i === node.table.body.length) ? 2 : 1;
               },
               vLineWidth: function (i, node) {
                  return (i === 0 || i === node.table.widths.length) ? 2 : 1;
               },
               hLineColor: function (i, node) {
                  return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
               },
               vLineColor: function (i, node) {
                  return (i === 0 || i === node.table.widths.length) ? 'white' : 'white';
               },
            }
         }, {
            text: ".",
            color: "white"
         }, {
            columns: [
               (chart) ? {
                  width: 821,
                  heigth: 500,
                  image: await domToImage.toPng(document.querySelector('.chart-item')),
               } : null,
            ]
         }, {
            text: ".",
            color: "white"
         }, {
            style: 'tableConsumo',
            color: '#444',
            fontSize: 10,
            table: {
               widths: ['*', '*', '*', '*', '*', '*', '*', '*'],
               headerRows: 1,
               body: [
                  [
                     { text: 'Dispositivo', alignment: 'center', fillColor: '#b7b7b7' },
                     { text: 'Monitorado', alignment: 'center', fillColor: '#b7b7b7' },
                     { text: 'Campo', alignment: 'center', fillColor: '#b7b7b7' },
                     { text: 'Valor Inicial', alignment: 'center', fillColor: '#b7b7b7' },
                     { text: 'Valor Final', alignment: 'center', fillColor: '#b7b7b7' },
                     { text: '%', alignment: 'center', fillColor: '#b7b7b7' },
                     { text: 'Consumo (m3)', alignment: 'center', fillColor: '#b7b7b7' },
                     { text: 'Valor (R$)', alignment: 'center', fillColor: '#b7b7b7' },
                  ],
                  ...prepare_data
               ]
            }
         },
      ]
      let footer = []

      let fileDefinition = {
         pageSize: 'A4',
         pageOrientation: 'landscape',
         pageMargins: [10, 10, 10, 20],
         header: [header],
         content: [content],
         styles: { tableConsumo: { margin: [0, 120, 0, 0] }, },
         footer: [footer],
      }

      pdfMake.createPdf(fileDefinition).open()

   }

   handleSelect = (ranges) => {
      let detalhesDefault = this.state.detalhesDefault
      detalhesDefault.data = ranges.selection.startDate.toLocaleDateString() + "-" + ranges.selection.endDate.toLocaleDateString()
      this.setState({
         dateRange: {
            startDate: ranges.selection.startDate,
            endDate: ranges.selection.endDate,
            key: 'selection',
         },
         dataFormatada: ranges.selection.startDate.toLocaleDateString() + " - " + ranges.selection.endDate.toLocaleDateString(),
         detalhesDefault: detalhesDefault
      })
   }

   showDatePicker = () => {
      this.setState({ datePickerShow: !this.state.datePickerShow })
   }

   render() {
      const { detalhesDefault, validacao, relatorioGerado } = this.state

      let equipamentos = []
      detalhesDefault?.campos?.forEach(campo => {
         if (!equipamentos.includes(campo?.equipamento_imei)) {
            equipamentos.push(campo?.equipamento_imei)
         }
      })

      if (this.state.redirect)
         return <Redirect to="/relatorio-consumo" />

      return (
         <div>
            <div className="hdv-default-header mx-3">
               <span className="screen-menu-desc">
                  <h4>Relatório de Consumo</h4>
                  <div className="gray-background">
                     <i className="fa fa-history fa-2x"></i>
                  </div>
               </span>
            </div>
            <Tabs
               selectedIndex={this.state.tabIndex}
               onSelect={tabIndex => this.setState({ tabIndex })}>
               <TabList>
                  <Tab>Configuração</Tab>
                  <Tab disabled={this.props.match.params.id ? false : true}>Campos</Tab>
               </TabList>
               <TabPanel>
                  <form className="hdv-form-default">
                     <div className='row'>
                        <div className="col-md-5">
                           <label className="required mt-0">Descrição</label>
                           <input
                              type="text"
                              onChange={(e) => this.handleChange('descricao', e.target.value)}
                              value={detalhesDefault.descricao}
                           />
                           {!this.state.validacao.descricao ? <span id='validacao-descricao'>Campo Obrigatório</span> : null}
                        </div>
                        <div className="col-md-3"></div>
                        <div className="col-md-2 pl-0">
                           <label className="required mt-0">Unidade do Gráfico</label>
                           <Select
                              value={detalhesDefault.unidade}
                              onChange={(e) => this.handleChange('unidade', e)}
                              options={[
                                 { label: 'm3', value: 'm3' },
                                 { label: 'kW', value: 'kW' },
                                 { label: 'kW/h', value: 'kW/h' },
                                 { label: 'horas', value: 'horas' },
                              ]}
                           />
                        </div>
                        <div className="col-md-2 pl-0">
                           <label className="required mt-0">Custo por Unidade</label>
                           <CurrencyInput
                              id="value-unidade"
                              name="value-unidade"
                              placeholder=""
                              decimalsLimit={4}
                              groupSeparator={''}
                              decimalSeparator={','}
                              intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                              style={{ border: '1px solid #d0d0d0' }}
                              prefix={'R$ '}
                              value={detalhesDefault.valor_unidade}
                              onValueChange={(value, name) => { this.handleChange('valor_unidade', (value == undefined) ? '0' : value) }}
                           />
                        </div>
                     </div>

                     <div style={{ marginTop: "10px" }} className={this.props.match.params.id ? "row" : "hdv-noshow-item"}>
                        <div className="col-md-5">
                           <label className="required mt-0">Periodo</label>
                           <InputGroup className="hdv-force-hight">
                              <Input className="hdv-force-hight" placeholder="Selecione o periodo" onChange={() => false} disabled value={this.state.dataFormatada} />
                              <InputGroupAddon addonType="append">
                                 <Button onClick={this.showDatePicker} color={(this.state.datePickerShow) ? "danger" : "primary"}>
                                    <i className={(this.state.datePickerShow) ? "fa fa-calendar-times-o" : "fa fa-calendar-check-o"}></i>
                                    {(this.state.datePickerShow) ? "Fechar Calendário" : "Exibir Calendário"}
                                 </Button>
                              </InputGroupAddon>
                           </InputGroup>
                           {!this.state.validacao.data ? <span id='validacao-periodo'>Campo Obrigatório</span> : null}
                           <div className={(this.state.datePickerShow) ? "hdv-datepicker" : "hdv-datepicker-display-none"}>
                              <DateRangePicker locale={rdrLocales.pt} ranges={[this.state.dateRange]} onChange={this.handleSelect} />
                           </div>
                        </div>
                        <div className="col-md-2"></div>
                        <div className="col-md-5">
                           <label className="required mt-0">Agrupar por</label>
                           <Select
                              value={this.state.detalhesDefault.agrupar}
                              onChange={(e) => this.handleChange('agrupar', e)}
                              options={[
                                 { label: 'Hora', value: 'hour' },
                                 { label: 'Dia', value: 'day' },
                                 { label: 'Mês', value: 'month' },
                                 { label: 'Período Específico (Dia X do mês selecionado até o mesmo dia do mês seguinte)', value: 'irregular' },
                                 { label: 'Período Fixo', value: 'fixo' }
                              ]}
                           />
                           {!this.state.validacao.agrupar ? <span id='validacao-agrupar'>Campo Obrigatório</span> : null}
                        </div>
                     </div>
                  </form>
               </TabPanel>
               <TabPanel>
                  <div className="hdv-multi-report-dispositivos">
                     <div className="d-flex justify-content-between align-items-center">
                        <p className="hdv-required-field">{!validacao.campos ? "Nenhum campo registrado" : null}</p>
                        <button
                           className="hdv-btn-forms hdv-btn-green m-0 hdv-automacao-add-new-button d-flex align-items-center"
                           disabled={this.state.carregando}
                           onClick={() => this.setState({ modal: true })}>
                           Gerenciar Campos
                        </button>
                     </div>

                     {detalhesDefault.campos?.length > 0 ?
                        <section className="row mx-0 px-0 my-2 d-flex">
                           {detalhesDefault.campos?.map((campo, index) => {
                              let icone = ""
                              const iconStyle = { fontSize: "32px", marginRight: "10px", opacity: "0.85" }

                              try {
                                 icone = JSON.parse(campo?.icone)?.icone_on
                              } catch (err) {
                                 icone = null
                              }

                              return (
                                 <div
                                    className="hdv-multi-report-item d-flex"
                                    key={index}
                                    style={{ width: "32%", margin: "3px 6px", justifyContent: "flex-start" }}>
                                    {icone != null ? <i className={`fa ${icone}`} style={iconStyle} /> : null}
                                    <div>
                                       <b>{campo.descricao} [{campo.identificacao}]</b><br />
                                       <span>Unidade: {campo.unidade}</span>
                                    </div>
                                 </div>
                              )
                           })}
                        </section>
                        :
                        null
                     }
                  </div>
               </TabPanel>
            </Tabs>

            {detalhesDefault.descricao
               ?
               <HeaderConsumo
                  DataChange={detalhesDefault}
                  toggleModal={this.toggleModal}
                  adicionar={this.adicionar}
                  validacao={validacao}
                  modal={this.state.modal}
                  handleChange={this.handleChange}
               />
               :
               null
            }

            <div id="download-pdf"></div>

            <div className="hdv-header-filters py-0 pt-2">
               <div className="hdv-chart-options hdv-relatorio-btn-menu" style={{ top: "45px" }}>
                  <ReactLoading
                     className="hdv-report-loading"
                     type="cylon"
                     color={this.state.carregando ? "#589bd4" : "transparent"}
                     width={50}
                  />
                  <button
                     disabled={!this.state.relatorioGerado}
                     className={!this.state.relatorioGerado ? "hdv-btn-forms hdv-btn-red hdv-block-button" : "hdv-btn-forms hdv-btn-red"}
                     onClick={this.exportarPDF} > <i className="fa fa-file-pdf-o fa-20px"></i> Exportar PDF
                  </button>

                  <button
                     className={!this.state.relatorioGerado ? "hdv-btn-forms hdv-btn-red hdv-block-button mr-2" : "hdv-csv-button mr-2"}
                     disabled={!this.state.relatorioGerado}>
                     {this.state.relatorioGerado
                        ?
                        <CsvDownloader
                           style={{ border: 'none' }}
                           columns={this.state.csv.columns}
                           datas={this.state.csv.data}
                           filename={`relatorio_consumo_${detalhesDefault?.descricao}.csv`}
                           separator=";">
                           <p style={{ fontSize: '12px', margin: '0', color: '#fff', padding: '0 10px' }}>
                              Exportar CSV
                           </p>
                        </CsvDownloader>
                        :
                        <p style={{ fontSize: '12px', margin: '0', color: '#fff', padding: '0 10px' }}>
                           Exportar CSV
                        </p>
                     }
                  </button>

                  <button
                     disabled={this.state.carregando || !this.props.match.params.id}
                     className="hdv-btn-forms hdv-btn-blue hdv-automacao-add-new-button m-0 d-flex align-items-center"
                     onClick={this.buscarDadosRelatorio}>
                     Gerar Relatório
                  </button>
               </div>
               <br />
            </div>

            <hr />
            {relatorioGerado ?
               <section id="hdv-multi-report-print" className="mb-4">
                  <div className="hdv-relatorio-consumo col-md-12 mx-0 mt-3" style={{ padding: '8px 0' }}>
                     {this.gerarGrafico()}
                  </div>

                  <table className="table afira-table">
                     <thead>
                        <tr>
                           <th>Campo</th>
                           <th>Valor Inicial</th>
                           <th>Valor Final</th>
                           <th>Consumo ({detalhesDefault?.unidade?.value})</th>
                           <th>Valor (R$)</th>
                           <th colSpan="3" style={{ width: "300px", fontWeight: "bold" }}>
                              %
                           </th>
                        </tr>
                     </thead>
                     <tbody>
                        {this.state.table?.itens?.map((item, index) => {
                           let campo = item.campo

                           if (item?.monitorados) {
                              campo = `${item.campo} (${item?.monitorados})`
                           }

                           return (<React.Fragment key={index}>
                              <tr>
                                 <td>{campo}</td>
                                 <td>{String(item.valor_inicial).replace(".", ",")}</td>
                                 <td>{String(item.valor_final).replace(".", ",")}</td>
                                 <td> {(detalhesDefault.unidade?.value == "horas") ? helper.decimalToTime(Number(Number(item.consumo).toFixed(2))) : Number(Number(item.consumo).toFixed(2)).toLocaleString("pt-BR")}</td>
                                 <td>{BRAZILIANREAL.format(Number(Number(item.consumo).toFixed(2)) * parseFloat(detalhesDefault.valor_unidade.replace('.', '').replace(',', '.')))}</td>

                                 <td colSpan="3">
                                    <span className="d-flex">
                                       <progress value={item.porcentagem} max="100"></progress>
                                       <span className="ml-1"> {item.porcentagem}%</span>
                                    </span>
                                 </td>
                              </tr>

                              {index === (this.state.table.itens.length - 1)
                                 ?
                                 <tr key={index + 1}>
                                    <td colSpan="3"><strong>Total</strong></td>
                                    <td>
                                       {(detalhesDefault.unidade?.value == "horas") ? helper.decimalToTime(Number(Number(this.state.table.total).toFixed(2))) : Number(Number(this.state.table.total).toFixed(2)).toLocaleString("pt-BR")}
                                    </td>
                                    <td>
                                       {BRAZILIANREAL.format(Number(Number(this.state.table.total).toFixed(2)) * parseFloat(detalhesDefault.valor_unidade.replace('.', '').replace(',', '.')))}
                                    </td>

                                    <td colSpan="3">
                                       {String(this.state.table.total) !== '0'
                                          ?
                                          <span className="d-flex">
                                             <progress value="100" max="100"></progress> <span className="ml-1">100%</span>
                                          </span>
                                          :
                                          <span className="d-flex">
                                             <progress value="0" max="100"></progress> <span className="ml-1">100%</span>
                                          </span>
                                       }
                                    </td>
                                 </tr>
                                 :
                                 null
                              }
                           </React.Fragment>)
                        })
                        }
                     </tbody>
                  </table>
               </section>
               : null
            }

            <div className="hdv-btn-group">
               <ButtonsForm
                  route="/relatorio/consumo"
                  edit={this.props.match.params.edit ? true : false}
                  cooldown={this.state.cooldown}
                  buttons={[
                     {
                        method: "post",
                        button: <button onClick={this.saveAction} className="hdv-btn-forms hdv-btn-green">Salvar</button>
                     },
                     {
                        method: "patch",
                        button: <button onClick={this.saveAction} className="hdv-btn-forms hdv-btn-green">Salvar</button>
                     },
                  ]}
               />

               <Link to="/relatorio-consumo">
                  <button className="hdv-btn-forms hdv-btn-yellow">Cancelar</button>
               </Link>
            </div>
         </div>
      )
   }
}

export default Form