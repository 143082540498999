import React, { Component } from "react";
import Select from "../../../../components/Select";

class ConfiguracoesEscalas extends Component {
    state = {
        formularioEscala: { campo: "", escala: "", identificacao: "" },
        keyEditEscala: ""
    }

    handleChangeEscala = (field, value) => {
        let formularioEscala = Object.assign({}, this.state.formularioEscala)

        formularioEscala[field] = value

        this.setState({ formularioEscala })
    }

    editarEscala = (key) => {
        let escala = this.props.estrutura.escalas.filter((grupo, index) => index === key)[0]

        this.setState({ formularioEscala: escala, keyEditEscala: key })
    }

    atualizarEscala = (key) => {
        let formularioEscala = this.state.formularioEscala
        let estrutura = Object.assign({}, this.props.estrutura)
        let campos = [...this.props.campos]
        let unidades = [...this.props.unidades]

        let escala = formularioEscala.escala?.value
        let identificacao = formularioEscala.identificacao
        let campo = `${formularioEscala.imei}_` + identificacao + `_${escala}`
        let unidade_medida = unidades.filter((unidade) => unidade.id.includes(identificacao))[0]

        if (!campos.includes(campo) && escala != null) {
            campos.push(campo)
            unidades.push({ id: campo, unidade: unidade_medida.unidade })
        }

        let remover = []

        switch (escala) {
            case null:
                remover = ["_minima", "_media", "_maxima"]
                break
            case "minima":
                remover = ["_media", "_maxima"]
                break
            case "media":
                remover = ["_minima", "_maxima"]
                break
            case "maxima":
                remover = ["_minima", "_media"]
                break
        }

        campos = campos.filter((campo) => {
            let item = remover.every((item) => campo != `${ `${formularioEscala.imei}_` + identificacao + item}`)
            if (item) {
                return campo
            }
        })

        estrutura.escalas[key] = formularioEscala

        this.props.setEstrutura(estrutura)
        this.props.setCampos(campos)
        this.props.setUnidades(unidades)
        this.setState({ 
            keyEditEscala: "", 
            formularioEscala: { campo: "", escala: "", identificacao: "" } 
        })
    }


    render() {
        const { formularioEscala, keyEditEscala } = this.state
        const { styleFieldset, estrutura } = this.props

        return(
            <fieldset style={{ ...styleFieldset.fieldset, display: this.props.detalhes.periodoTodo ? "none" : "block" }}>
                <legend style={styleFieldset.legend}>Configurações das escalas</legend>
                <div style={{ alignItems: "center" }} className="row">
                    <div className="col-md-4">
                        <label className="required">Campo</label>
                        <input onChange={(e) => this.handleChangeEscala("campo", e.target.value)} value={formularioEscala.campo} type="text" disabled={true} />
                    </div>
                    <div className="col-md-4">
                        <label className="required">Valor da escala</label>
                        <Select
                            options={[
                                { label: "Nenhum", value: null },
                                { label: "Mínima", value: "minima" },
                                { label: "Média", value: "media" },
                                { label: "Máxima", value: "maxima" },
                            ]}
                            onChange={(e) => this.handleChangeEscala("escala", e)}
                            value={formularioEscala.escala}
                        />
                    </div>
                    <div style={{ position: "relative" }} className="col-md-3">
                        <button onClick={() => this.atualizarEscala(keyEditEscala)} disabled={keyEditEscala === "" ? true : false} style={{ position: "absolute", top: "-1px" }} className="hdv-btn-forms hdv-btn-blue">Atualizar</button>
                    </div>
                </div>
                <div style={{ padding: "20px" }} className="row">
                    <table className="table afira-table">
                        <thead>
                            <tr>
                                <th>Campo</th>
                                <th>Escala</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                estrutura.escalas.map((escala, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{escala.campo}</td>
                                            <td>{escala.escala.label}</td>
                                            <td><button onClick={() => this.editarEscala(index)} className="hdv-btn-forms hdv-btn-yellow">Editar</button></td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </fieldset>
        )
    }
}

export default ConfiguracoesEscalas