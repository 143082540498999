import { Component } from "react"
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { RelatorioContext } from "../../../contexts/Relatorio"
import Select from "../../../components/Select"
import SelectCampo from "./SelectCampo"
import { compile } from 'mathjs'
import auth from "../../../utils/auth"
import cfg from "../../../utils/config"
import axios from "axios"

class ResumoRelatorio extends Component {
    static contextType = RelatorioContext

    userInfo = auth.getUserInfo()
    configMe = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }

    state = {
        teste: { resultado: '0', invalido: false, erro: '' },
    }

    saveResumo = () => {
        const { resumoModal, graficoModal, relatorioId } = this.context
        let verb, url
        let valid = true

        if (resumoModal.novo) {
            verb = 'POST'
            url = cfg.base_api_url + cfg.api_v2 + `/${this.userInfo.empresa}/relatorio/${relatorioId}/resumo/`
        } else {
            verb = 'PATCH'
            url = cfg.base_api_url + cfg.api_v2 + `/${this.userInfo.empresa}/relatorio/${relatorioId}/resumo/${resumoModal.resumo.id}/`
        }

        if (resumoModal.tipo === 'formula') {
            if (!this.testarFormula()) return
        }

        const except = ['formula', 'personalizado', 'custo_unidade', 'custo', 'campo_referencia']
        for (let key in resumoModal.resumo) {
            if (!resumoModal.resumo[key] && !except.includes(key)) {
                valid = false
            }
        }

        if (!valid) return

        const bodyFormData = new FormData()
        bodyFormData.append('relatorio', relatorioId)
        bodyFormData.append('grafico', graficoModal.grafico.id)
        bodyFormData.append('descricao', resumoModal.resumo.descricao)
        bodyFormData.append('icone', resumoModal.resumo.icone.value)
        bodyFormData.append('custo_unidade', resumoModal.resumo.custo_unidade ?? 0)
        bodyFormData.append('campo_referencia', resumoModal.resumo?.campo_customizado?.identificacao ?? graficoModal.grafico?.campo_referencia)
        bodyFormData.append('campo_customizado', resumoModal.resumo?.campo_customizado?.id ?? null)

        if (resumoModal.resumo.tipo?.value !== 'formula') {
            bodyFormData.append('resumo_pre_definido', resumoModal.resumo.tipo.value === 'custo_unidade' ? 'custo' : resumoModal.resumo.tipo.value)
            bodyFormData.append('formula', null)
        } else {
            bodyFormData.append('formula', resumoModal.resumo.formula)
        }

        axios({
            method: verb,
            url: url,
            data: bodyFormData,
            ...this.configMe
        })
            .then(res => {
                this.context.set({
                    resumoModal: {
                        ativo: false, novo: true,
                        resumo: {
                            descricao: '', formula: '', icone: '', custo: 0.00, campo_customizado: '',
                            tipo: { label: 'Mínimo, Médio e Máximo', value: 'min_med_max' }
                        }
                    }
                })

                this.context.getData?.(false)
            })
            .catch(console.error)
    }

    handleChange = (e, field) => {
        let { resumoModal } = this.context
        resumoModal.resumo[field] = e
        this.context.set({ resumoModal })
        if (field === 'tipo')
            setTimeout(this.context.reloadState(), 120)
    }

    handleChangeCusto = (e) => {
        let { resumoModal } = this.context
        let v = e.target.value.replace(/\D/g, '')
        v = (v / 100).toFixed(2) + ''
        v = v.replace(" ", ",")
        v = v.replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2,$3,")
        v = v.replace(/(\d)(\d{3}),/g, "$1.$2,")

        resumoModal.resumo['custo_unidade'] = v
        this.context.set({ resumoModal })
    }

    testarFormula = () => {
        let { resumoModal } = this.context
        let { teste } = this.state
        const { ultimoReporte } = this.staste.resource

        teste['invalido'] = false
        teste['erro'] = ''
        let valid = true
        this.setState({ teste })

        let formula = resumoModal.resumo?.['formula']
        formula = formula.replace(/,/g, '.')
        if (formula.length === 0) return

        try {
            let variaveis = Array.from(formula.matchAll(/\[(.*?)\]/g)).map(v => {
                let split = v[1].split('_')
                let ultimo = split[split.length - 1]
                let value = null

                if (/^\d+$/.test(ultimo)) {
                    split.pop()
                    let index = split.join('_')
                    value = ultimoReporte[index][ultimo - 1]
                }
                else
                    value = ultimoReporte[v[1]]

                return { id: `[${v[1]}]`, value: Number(value) }
            })

            variaveis.map(v => formula = formula.replace(v.id, v.value.toString()))

            const code = compile(formula)
            const resultado = code.evaluate()
            if (isNaN(resultado) || resultado === Infinity) {
                teste['invalido'] = true
                teste['erro'] = 'Fórmula Inválida!'
                valid = false
            }

            else {
                teste['invalido'] = false
                valid = true
            }

            this.setState({ teste })
        } catch (e) {
            teste['invalido'] = true
            teste['erro'] = 'Fórmula Inválida!'
            valid = false
            this.setState({ teste })
        }
        return valid
    }

    render() {
        let { resumoModal, graficoModal, resource } = this.context
        let { teste } = this.state

        return (
            <Modal
                isOpen={resumoModal.ativo} toggle={() => this.context.set({ resumoModal: { ativo: false, resumo: null } })}
                className="modal-md">
                <ModalHeader toggle={() => this.context.set({ resumoModal: { ativo: false, resumo: null } })}>
                    {resumoModal.novo ? 'Novo resumo' : 'Editar resumo'}
                </ModalHeader>
                <ModalBody>
                    <div className="hdv-relatorio-details hdv-form-default row" style={{ border: 'none' }}>
                        <div className="col-md-6">
                            <label>Descrição</label>
                            <input
                                type="text"
                                value={resumoModal.resumo?.descricao}
                                onChange={(e) => this.handleChange(e.target.value, 'descricao')}
                            />
                        </div>
                        <div className="col-md-6">
                            <label>Ícone</label>
                            <Select
                                value={resumoModal.resumo?.icone}
                                onChange={(e) => this.handleChange(e, 'icone')}
                                options={resource.resumoIconOptions}
                            />
                        </div>
                        <div className="col-md-6 hdv-form-default hdv-top15">
                            <label>Tipo do resumo</label>
                            <Select
                                value={resumoModal.resumo?.tipo ?? { label: 'Mínimo, Médio e Máximo', value: 'min_med_max' }}
                                onChange={(e) => this.handleChange(e, 'tipo')}
                                options={resource.tiposResumo}
                            />
                        </div>
                        <div className="col-md-6 hdv-form-default hdv-top15">
                            <SelectCampo
                                value={resumoModal.resumo?.campo_customizado}
                                onChange={(value) => this.handleChange({ ...value, id: value.value }, 'campo_customizado')}
                            />
                        </div>
                        {
                            resumoModal.resumo?.tipo?.value === 'custo'
                                ?
                                <div className="col-md-6 hdv-form-default hdv-top15">
                                    <label>Custo por unidade</label>
                                    <input
                                        type="text"
                                        value={resumoModal.resumo?.custo_unidade}
                                        onChange={(e) => this.handleChangeCusto(e, false)}
                                    />
                                </div>
                                :
                                <div className="col-md-12"></div>
                        }
                        <div className="col-md-12 hdv-top15"></div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    {teste.invalido ?
                        <span style={{ margin: '0 20px' }} id="validacao-formula">{teste.erro}</span>
                        :
                        null
                    }
                    <button
                        className="hdv-btn-forms hdv-btn-green"
                        onClick={this.saveResumo}>
                        Salvar
                    </button>
                    <button
                        className="hdv-btn-forms hdv-btn-default"
                        onClick={() => this.context.set({ resumoModal: { ativo: false, resumo: null } })}>
                        Fechar
                    </button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default ResumoRelatorio