import React from "react"
import helper from "../utils/helper"
import { Redirect } from "react-router-dom"
import config from "../utils/config"

class Table extends React.Component {
   static defaultProps = {
      checkbox: false,
      search: true,
      config: true,
   }

   state = {
      columns: [],
      selected: [],
      selectAll: 0,
      lineCount: 10,
      page: 1,
      pages: 1,
      searchInput: false,
      search: "",
      filter: "",
      sorting: { field: null, mode: null },
      enableEditar: false,
      enableDeletar: false,
      routeEdit: false
   }

   componentWillReceiveProps(props) {
      let columns = []
      if (props?.columns) {
         columns = props.columns.map((column) => {
            column.show = true
            column.sorted = { sort: false, mode: "ASC" }
            if (column.sortable === false) {
               column.sortable = false
            } else {
               column.sortable = true
            }

            return column
         })
      }

      if (props?.count && props?.count > 0) {
         let pages = Math.ceil(props.count / this.state.lineCount)
         if (pages === 0) {
            pages = 1
         }

         this.setState({ pages: pages })
      }

      this.getTableConfig(columns)
   }

   componentDidMount() {
      let { lineCount, filter } = this.state
      this.props?.onFetchData?.({ lineCount, filter })

      const details = document.querySelectorAll("details.hdv-popover")
      details?.forEach((detail, index) => detail.addEventListener("click", () => {
         for (let i = details.length - 1; i >= 0; i--) {
            if (index !== i) details[i].open = false
         }
      }))

      this.update()
   }

   getTableConfig = (columns) => {
      if (localStorage.getItem("tables")) {
         let tableConfig = JSON.parse(localStorage.getItem("tables"))?.[window.location.pathname]

         if (tableConfig != undefined) {
            try {
               Object.entries(tableConfig).forEach(([key, value]) => {
                  const colIndex = columns.findIndex((c) => c.Accessor === key)
                  if (colIndex != -1) {
                     columns[colIndex].show = value
                  }
               })
            } catch (err) {
               console.error(err)
            }
         }
      }

      this.setState({ columns })
   }

   saveTableConfig = () => {
      let { columns } = this.state
      let items = {}

      columns.forEach(column => {
         items[column.Accessor] = column.show
      })

      localStorage.setItem("tables", JSON.stringify({ [window.location.pathname]: items }))
   }

   update = () => {
      setTimeout(() => this.props.onUpdate(this), 50)
   }

   toggleSelectAll = () => {
      let { selectAll } = this.state
      let selected = []

      if (selectAll === 0) {
         this.props.data?.forEach(x => selected.push(x?.id))
      }

      this.setState({
         selected,
         enableEditar: selected.length === 1,
         enableDeletar: selected.length >= 1,
         selectAll: selectAll === 0 ? 1 : 0
      })

      this.update()
   }

   toggleRow = (id) => {
      let { selected } = this.state

      if (selected.includes(id)) {
         selected = selected.filter(item => item !== id)
      } else {
         selected.push(id)
      }

      this.setState({
         selectAll: 2,
         enableEditar: selected.length === 1,
         enableDeletar: selected.length >= 1,
         selected: Array.from(new Set(selected))
      })

      this.update()
   }

   handleChangeSelect = (e) => {
      const lineCount = e.target.value
      this.setState({ lineCount })
      this.unselect()
      this.props?.onFetchData?.({ lineCount, filter: this.state.filter })
   }

   handleColumn = (column) => {
      let { columns } = this.state

      let validColumns = []
      columns.map(column => {
         if (column?.Header) validColumns.push(column)
      })

      let index = columns.findIndex(c => c.Accessor === column.Accessor)
      if (index !== -1 && validColumns.length > 1) {
         columns[index].show = !this.state.columns[index].show
      }

      if (columns.some(c => c.show)) {
         this.saveTableConfig()
         this.setState({ columns })
      }
   }

   sortColumn = (column) => {
      if (!column.sortable) return

      let { columns, lineCount, filter, sorting } = this.state
      let index = columns.findIndex((c) => c.Accessor === column.Accessor)

      if (index !== -1) {
         const selected = columns[index]

         if (sorting.field === selected.Accessor) {
            if (sorting.mode === "ASC") {
               sorting.mode = "DESC"
            } else if (sorting.mode === "DESC") {
               sorting = { field: null, mode: null }
            }
         } else {
            sorting = { field: column.Accessor, mode: "ASC" }
         }

         if (sorting.mode !== null) {
            const field = selected?.Accessor?.replace(/\./g, "__")
            if (sorting.mode === "ASC") {
               filter = `&ordering=${field}`
            } else if (sorting.mode === "DESC") {
               filter = `&ordering=-${field}`
            }

            this.props?.onFetchData?.({ lineCount, filter })
         } else {
            this.props?.onFetchData?.({ lineCount, filter: "" })
         }
      }

      this.setState({ columns, sorting, filter })
   }

   fetch = async (filter = null) => {
      this.setState({ selected: [] })

      await this.props?.onFetchData?.({
         lineCount: this.state.lineCount,
         filter: filter ?? this.state.filter,
         url: null
      })
   }

   setPage = (operator) => {
      if (operator === "+") {
         this.setState({ page: this.state.page + 1 })
      } else if (operator === "-") {
         this.setState({ page: this.state.page - 1 })
      }
   }

   fetchNext = () => {
      let url = this.props.next
      this.setState({ selected: [] })
      this.setPage("+")

      if (config.base_api_url.includes("https")) {
         url = url.replace("http", "https")
      }

      this.props?.onFetchData?.({
         lineCount: this.state.lineCount,
         filter: this.state.filter,
         url: url
      })
   }

   fetchPrevious = () => {
      let url = this.props.previous
      this.setState({ selected: [] })
      this.setPage("-")

      if (config.base_api_url.includes("https")) {
         url = url.replace("http", "https")
      }

      this.props?.onFetchData?.({
         lineCount: this.state.lineCount,
         filter: this.state.filter,
         url: url
      })
   }

   unselect = () => {
      this.setState({
         page: 1,
         selectAll: 0,
         selected: [],
         enableEditar: false,
         enableDeletar: false
      })
   }

   toggleSearchInput = () => {
      const input = document.querySelector("#table-search-input")
      this.setState({ searchInput: !this.state.searchInput })
      setTimeout(() => input.focus(), 100)
   }

   search = (e) => {
      let { search, filter } = this.state

      if (e.key === "Enter") {
         if (search.length === 0) {
            let query = new URLSearchParams(filter)
            query.delete("search")
            this.setState({ filter: "" })
            setTimeout(this.fetch, 250)
         } else {
            let query = new URLSearchParams(filter)
            query.set("search", search)
            this.setState({ filter: `&${query.toString()}` })
            this.fetch(`&${query.toString()}`)
         }

         const details = document.querySelectorAll("details.hdv-popover")
         details?.forEach((detail) => {
            detail.open = false
         })
      }
   }

   handleChangePage = (e) => {
      const page = Number(e.target.value)
      const offset = (page * 10) - 10
      let filter = this.state.filter
      this.setState({ selected: [], page: page })

      if (offset >= 10) {
         let query = new URLSearchParams(this.state.filter)
         query.set("offset", offset)
         filter += `&${query.toString()}`
      }

      this.props?.onFetchData?.({
         lineCount: this.state.lineCount,
         filter: filter.toString(),
         url: null
      })
   }

   renderPageOptions = (pages = 1) => {
      let options = []
      for (let i = 1; i <= pages; i++) {
         options.push(<option key={i} value={i}>Página {i}/{this.state.pages}</option>)
      }

      return options
   }

   clearSearch = () => {
      let query = new URLSearchParams(this.state.filter)
      query.delete("search")
      this.setState({ filter: `&${query.toString()}`, search: "", searchInput: false })
      this.fetch(`&${query.toString()}`)
   }

   gotEdit = (route) => {
      this.setState({ routeEdit: route })
   }

   renderTable = () => {
      const { columns, sorting } = this.state
      let checkbox = null

      if (this.props.checkbox) {
         checkbox = (
            <th className="afira-table-checkbox" style={{ width: "55px", maxWidth: "55px" }}>
               <input
                  type="checkbox"
                  className="checkbox"
                  checked={this.state.selectAll === 1}
                  onChange={this.toggleSelectAll}
                  ref={input => {
                     if (input) {
                        input.indeterminate = this.state.selectAll === 2
                     }
                  }}
               />
            </th>
         )
      }

      return (
         <table className="table afira-table">
            <thead>
               <tr>
                  {checkbox}
                  {columns.map((column, index) => column.show
                     ?
                     <th
                        key={index}
                        onClick={() => this.sortColumn(column)}
                        style={{ cursor: column.sortable ? "pointer" : "default" }}
                        data-hoverable={column.sortable ? "on" : "off"}
                        data-sortmode={sorting.mode ?? ""}
                        className={sorting.field === column?.Accessor ? "afira-th__sorted " : "afira-th"}>
                        <div className="d-flex justify-content-between">
                           <span>{column?.Header}</span>
                           {sorting.field === column?.Accessor
                              ?
                              <i className="fa fa-sort" style={{ fontSize: "17px" }}></i>
                              :
                              null
                           }
                        </div>
                     </th>
                     :
                     null
                  )}
               </tr>
            </thead>
            <tbody>
               {this.props.data?.map((row, index) => {
                  const rowId = helper.randomKey(8)
                  const tdStyle = { verticalAlign: "middle", cursor: this.props.onRowClick ? "pointer" : "default" }
                  row.rowId = rowId
                  const onRowClick = this.props.onRowClick ? () => this.props.onRowClick(row, this.state) : null

                  return <tr key={index} data-table-id={rowId}>
                     {this.props.checkbox
                        ?
                        <td className="hdv-aligncenter-checkboxgrid">
                           <input
                              type="checkbox"
                              className="checkbox"
                              checked={(this.state.selected.includes(row?.id))}
                              onChange={() => this.toggleRow(row?.id)}
                           />
                        </td>
                        :
                        null
                     }

                     {columns?.map((column, index) => {
                        let value = null

                        if (column.show) {
                           if (column?.Cell) {
                              value = column?.Cell(row)
                           } else {
                              const split = column?.Accessor?.split(".")
                              if (split?.length >= 1) {
                                 value = eval(`row?.${split.join("?.")}`) ?? ""
                              }
                           }

                           if (value != 0 && !value) value = "---"

                           let title = typeof value === 'object' ? value.props.children : value

                           if (index === 0 && !this.props.notEditFirstColum) {
                              let route

                              if (this.props?.pathEditFirstColum) {
                                 route = "/" + this.props?.pathEditFirstColum + `/${row.id}/` + "editar"
                              } else {
                                 route = window.location.pathname + `/${row.id}/` + "editar"
                              }

                              return (
                                 <td
                                    key={index}
                                    title={title}
                                    onClick={() => { this.gotEdit(route) }}
                                    style={{ ...tdStyle, color: "#007bff", cursor: "pointer" }}>
                                    {value}
                                 </td>
                              )
                           }
                           return (<td title={title} onClick={onRowClick} style={tdStyle} key={index}>{value}</td>)
                        }

                        return value
                     })}
                  </tr>
               })}
            </tbody>
         </table>
      )
   }

   render() {
      const { loading, next, previous } = this.props
      const { columns, searchInput, routeEdit } = this.state

      if (routeEdit) {
         history.pushState({}, "", routeEdit)
         return <Redirect to={routeEdit} />
      }

      return (
         <div className="afira-table-parent">
            <div className="d-flex float-right align-items-center afira-table-search">
               <section className={searchInput ? "search-input-toggle-on" : "search-input-toggle-off"}>
                  <div className="d-flex justify-content-between align-items-center">
                     <input
                        type="text"
                        value={this.state.search}
                        id="table-search-input"
                        placeholder="Pesquisar..."
                        style={{ height: "39px", width: "100%" }}
                        onKeyDown={this.search}
                        onChange={(e) => this.setState({ search: e.target.value })}
                     />
                     <button className="ml-1" onClick={this.clearSearch}>
                        <i className="fa fa-times fa-2x"></i>
                     </button>
                  </div>
               </section>
               <div className={this.props.search ? "hdv-popover" : "hdv-noshow-item"} id="afira-table-config">
                  <div className="hdv-popover-button gray-background" onClick={this.toggleSearchInput}>
                     <i className="fa fa-search fa-2x"></i>
                  </div>
               </div>
               <details className={this.props.config ? "hdv-popover" : "hdv-noshow-item"} id="afira-table-config">
                  <summary className="hdv-popover-button gray-background">
                     <i className="fa fa-cog fa-2x gray-primary"></i>
                  </summary>
                  <section style={{ minWidth: "250px" }}>
                     <strong>Configurações</strong>
                     <div className="mt-2">
                        {columns.map((column, index) => {
                           if (column?.Header) {
                              return (
                                 <label style={{ width: 'fit-content' }} htmlFor={`table__config-${index}`} className="d-flex" key={index}>
                                    <input
                                       type="checkbox"
                                       checked={column.show}
                                       onChange={() => this.handleColumn(column)}
                                       id={`table__config-${index}`}
                                    />
                                    <span className="ml-1">{column?.Header}</span>
                                 </label>
                              )
                           }
                           else return null
                        }
                        )}
                     </div>
                  </section>
               </details>
            </div>

            <div
               className={loading ? "afira-table-content afira-table-loading" : "afira-table-content"}
               data-message={loading ? "Carregando..." : ""}>
               {this.renderTable()}
            </div>
            {this.props?.noFooter
               ?
               null
               :
               <div className="afira-table-options">
                  <button onClick={this.fetchPrevious} disabled={loading || !previous}>Anterior</button>
                  <div className={this.props?.count ? "afira-table-select" : "hdv-noshow-item"}>
                     <select onChange={this.handleChangePage} value={this.state.page} disabled={loading}>
                        {this.renderPageOptions(this.state.pages)}
                     </select>
                  </div>

                  <div className="afira-table-select">
                     <select onChange={this.handleChangeSelect} value={this.state.lineCount} disabled={loading}>
                        <option value="10">10 Linhas</option>
                        <option value="20">20 Linhas</option>
                        <option value="50">50 Linhas</option>
                        <option value="100">100 Linhas</option>
                     </select>
                  </div>
                  <button onClick={this.fetchNext} disabled={loading || !next}>Próximo</button>
               </div>
            }
         </div>
      )
   }
}

export default Table