import { Component } from "react"
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { RelatorioContext } from "../../../contexts/Relatorio"
import Select from "../../../components/Select"
import SelectCampo from "./SelectCampo"
import auth from "../../../utils/auth"
import cfg from "../../../utils/config"
import axios from "axios"

class ReferenciaRelatorio extends Component {
    static contextType = RelatorioContext

    userInfo = auth.getUserInfo()
    configMe = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }

    state = {
        automacaoOptions: [],
    }

    handleChange = (e, field) => {
        let { referenciaModal } = this.context
        referenciaModal.referencia[field] = e

        this.context.set({ referenciaModal })
    }

    saveReferencia = () => {
        const { referenciaModal, graficoModal, relatorioId } = this.context
        let verb, url
        let valid = true

        if (referenciaModal.novo) {
            verb = 'POST'
            url = cfg.base_api_url + cfg.api_v2 + `/${this.userInfo.empresa}/relatorio/${relatorioId}/referencia/`
        } else {
            verb = 'PATCH'
            url = cfg.base_api_url + cfg.api_v2 + `/${this.userInfo.empresa}/relatorio/${relatorioId}/referencia/${referenciaModal.referencia.id}/`
        }

        const except = ['valor', 'automacao', 'alerta', 'valido', 'calcula_como_volume', 'campo_referencia', 'automacao_id', 'automacao__descricao']
        for (let key in referenciaModal.referencia) {
            if (!referenciaModal.referencia[key] && !except.includes(key)) {
                valid = false
            }
        }

        if (!valid) return

        if (referenciaModal.referencia.tipo_da_referencia) {
            const bodyFormData = new FormData()
            bodyFormData.append('relatorio', relatorioId)
            bodyFormData.append('grafico', graficoModal.grafico.id)
            bodyFormData.append('descricao', referenciaModal.referencia.descricao)
            bodyFormData.append('campo_customizado', referenciaModal.referencia?.campo_customizado?.id)
            bodyFormData.append('cor_linha', referenciaModal.referencia.cor_linha)
            bodyFormData.append('tipo_da_referencia', referenciaModal.referencia.tipo_da_referencia?.value ?? '')
            bodyFormData.append('calcula_como_volume', referenciaModal.referencia.calcula_como_volume ? '1' : '0')

            let campos = {
                'campo_referencia': null,
                'campo_customizado': null,
                'alerta_id': null,
                'automacao_id': null,
                'valor': 0
            }

            switch (referenciaModal.referencia.tipo_da_referencia?.value) {
                default: break
                case 'fixa':
                    campos['valor'] = referenciaModal.referencia?.valor ? Number(referenciaModal.referencia?.valor) : null
                    break
                case 'dinamico':
                    campos['campo_customizado'] = referenciaModal.referencia?.campo_customizado?.id ?? null
                    break
                case 'automacao':
                    campos['automacao_id'] = referenciaModal.referencia?.automacao?.value
                    break
            }

            if (Object.values(campos).every(e => e === null || e === undefined)) return
            else {
                Object.keys(campos).map(key => bodyFormData.append(key, campos[key]))

                axios({ method: verb, url, data: bodyFormData, ...this.configMe }).then(res => {
                    this.context.set({
                        referenciaModal: {
                            ativo: false, novo: true,
                            referencia: {
                                descricao: '',
                                calcula_como_volume: false,
                                cor_linha: '#20db6e',
                                valor: '',
                                automacao: '',
                                tipo_da_referencia: null
                            },
                        },
                    })

                    this.context.getData?.(false)
                }).catch(console.error)
            }
        }
    }

    render() {
        let { referenciaModal, graficoModal, resource } = this.context
        
        return (
            <Modal
                isOpen={referenciaModal.ativo}
                className="modal-md"
                toggle={() => this.context.set({ referenciaModal: { ativo: false, referencia: null } })}>
                <ModalHeader toggle={() => this.context.set({ referenciaModal: { ativo: false, referencia: null } })}>
                    {referenciaModal.novo ? 'Nova referência' : 'Editar referência'}
                </ModalHeader>
                <ModalBody>
                    <div className="row hdv-relatorio-details" style={{ border: 'none' }}>
                        <div className="col-md-9 hdv-form-default">
                            <label>Descrição</label>
                            <input
                                type="text"
                                value={referenciaModal.referencia?.descricao}
                                onChange={(e) => this.handleChange(e.target.value, 'descricao')}
                            />
                        </div>
                        <div className="col-md-3 align-center">
                            <label className="float-right">Cor
                                <input
                                    type="color"
                                    onChange={(e) => this.handleChange(e.target.value, 'cor_linha')}
                                    value={referenciaModal.referencia?.cor_linha}
                                />
                            </label>
                        </div>
                        <div className="col-md-12 hdv-form-default hdv-top15">
                            <label>Tipo</label>
                            <Select
                                value={referenciaModal.referencia?.tipo_da_referencia}
                                onChange={(e) => this.handleChange(e, 'tipo_da_referencia')}
                                options={resource.referenciaOptions}
                            />
                        </div>
                        <div className={referenciaModal.referencia?.tipo_da_referencia?.value ? 'col-md-12 hdv-top15 hdv-form-default' : 'hdv-noshow-item'}>
                            <div className={referenciaModal.referencia?.tipo_da_referencia?.value === 'automacao' ? '' : 'hdv-noshow-item'}>
                                <label>Automação</label>
                                <Select
                                    value={referenciaModal.referencia?.automacao}
                                    onChange={(e) => this.handleChange(e, 'automacao')}
                                    options={resource.automacaoOptions}
                                />
                            </div>
                            <div className={referenciaModal.referencia?.tipo_da_referencia?.value === 'fixa' ? '' : 'hdv-noshow-item'}>
                                <label>Valor Fixo</label>
                                <input
                                    type="number"
                                    value={referenciaModal.referencia?.valor}
                                    onChange={(e) => this.handleChange(e.target.value, 'valor')}
                                />
                            </div>
                            <div className={referenciaModal.referencia?.tipo_da_referencia?.value === 'dinamico' ? '' : 'hdv-noshow-item'}>
                                <div className="hdv-form-default row">
                                    <div className="col-md-7">
                                        <SelectCampo
                                            value={referenciaModal.referencia?.campo_customizado}
                                            onChange={(value) => this.handleChange({ ...value, id: value.value }, 'campo_customizado')}
                                        />
                                    </div>
                                    <div className="col-md-5">
                                        <label className="mr-3">Calcular como volume<br />
                                            <input
                                                type="checkbox"
                                                className="mt-2"
                                                disabled={graficoModal?.grafico?.visualizacao === "tudo"}
                                                onChange={(e) => this.handleChange(e.target.checked, 'calcula_como_volume')}
                                                checked={referenciaModal.referencia?.calcula_como_volume}
                                            />
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button
                        className="hdv-btn-forms hdv-btn-green"
                        onClick={this.saveReferencia}>
                        Salvar
                    </button>
                    <button
                        className="hdv-btn-forms hdv-btn-default"
                        onClick={() => this.context.set({ referenciaModal: { ativo: false, referencia: null } })}>
                        Fechar
                    </button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default ReferenciaRelatorio