import React, { Component } from "react";
import PrivateRoute from '../../containers/PrivateRoute'
import { Switch } from 'react-router-dom'
import Grid from "./grid";
import Form from "./form";

class AfiraLink extends Component {
    render() {
        return(
            <div className='hdv-render-div'>
                <div className="col-md-12 hdv-bloco-top-space hdv-zera-padding-left-right-boostrap">
                    <Switch>
                        <PrivateRoute exact path='/afiralink' component={Grid}/>
                        <PrivateRoute path='/afiralink/:id/:imei/automacoes' component={Form}/>
                    </Switch>
                </div>
            </div>
        )
    }
}

export default AfiraLink