import axios from 'axios'
import cfg from "./config"

const TOKEN_KEY = '__hidroview__access_token'
const REFRESH_TOKEN_KEY = '__hidroview__refresh_token'
const PERSIST_CONNECT = '__hidroview__persist_connect'
const USER_INFO = '__hidroview__me'

const parse = JSON.parse
const stringify = JSON.stringify

const auth = {
    clear(key) {
        if (localStorage && localStorage.getItem(key)) {
            return localStorage.removeItem(key)
        }

        if (sessionStorage && sessionStorage.getItem(key)) {
            return sessionStorage.removeItem(key)
        }

        return null
    },

    clearAppStorage(reload = false) {
        if (localStorage) {
            localStorage.clear()
        }

        if (sessionStorage) {
            sessionStorage.clear()
        }

        if (reload) {
            window.location.reload()
        }
    },

    clearToken(tokenKey = TOKEN_KEY) {
        return auth.clear(tokenKey)
    },

    clearUserInfo(userInfo = USER_INFO) {
        return auth.clear(userInfo)
    },

    isClient() {
        let tipo = this.getUserInfo()?.tipo
        return tipo == "cliente"
    },

    isMasterOrAdmin() {
        let tipo = this.getUserInfo()?.tipo
        if (!tipo) return false

        return ["admin", "master"].includes(tipo)
    },

    get(key) {
        if (localStorage && localStorage.getItem(key)) {
            return parse(localStorage.getItem(key)) || null
        }

        if (sessionStorage && sessionStorage.getItem(key)) {
            return parse(sessionStorage.getItem(key)) || null
        }

        return null
    },

    getToken(tokenKey = TOKEN_KEY) {
        return auth.get(tokenKey)
    },

    getUserInfo(userInfo = USER_INFO) {
        return auth.get(userInfo)
    },

    set(value, key, isLocalStorage) {
        if (value.toString().length == 0) {
            return null
        }

        if (isLocalStorage && localStorage) {
            return localStorage.setItem(key, stringify(value))
        }

        if (sessionStorage) {
            return sessionStorage.setItem(key, stringify(value))
        }

        return null
    },

    setToken(value = '', isLocalStorage = false, tokenKey = TOKEN_KEY) {
        return auth.set(value, tokenKey, isLocalStorage)
    },

    setUserInfo(value = '', isLocalStorage = false, userInfo = USER_INFO) {
        return auth.set(value, userInfo, isLocalStorage)
    },

    check_to_show(codigo_permissao_checar) {

        if (auth.getUserInfo()['tipo'] === "admin" || auth.getUserInfo()['tipo'] === "master") {
            return ""
        }

        let permissoes = auth.getUserInfo()['permissoes']

        let perm_checar = ""
        let tem_permissao = permissoes.filter((permissao) => {
            for (perm_checar in codigo_permissao_checar) {
                if (permissao.codigo_permissao === codigo_permissao_checar[perm_checar]) {
                    return true
                }
            }
            return false
        })

        if (tem_permissao.length === 0) {
            return "hdv-noshow-item"
        }
    },

    async refreshToken() {
        try {
            let persistConnection = JSON.parse(localStorage.getItem(PERSIST_CONNECT))
            let refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY)

            if (persistConnection === "true" && refreshToken != null) {
                let bodyFormData = new FormData()
                bodyFormData.append("client_id", cfg.client_id)
                bodyFormData.append("client_secret", cfg.client_secret)
                bodyFormData.append("grant_type", "refresh_token")
                bodyFormData.append("refresh_token", JSON.parse(refreshToken))

                await axios({
                    method: "POST",
                    url: cfg.base_api_url + "o/token/",
                    data: {
                        "client_id": cfg.client_id,
                        "client_secret": cfg.client_secret,
                        "grant_type": "refresh_token",
                        "refresh_token": JSON.parse(refreshToken)
                    },
                    headers: { "Content-Type": "application/x-www-form-urlencoded" },
                }).then(async (res) => {
                    this.setToken(res.data.access_token, true)
                    this.set(res.data.refresh_token, REFRESH_TOKEN_KEY, true)
                    this.set("true", PERSIST_CONNECT, true)
                    window.location.reload()
                }, (err) => console.error(err))
            } else {
                this.clearAppStorage(true)
            }
        } catch (err) {
            this.clearAppStorage(true)
        }
    }
}

export default auth