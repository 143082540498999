import React, { Component } from "react";
import { InputGroup, Input, InputGroupAddon, Button } from 'reactstrap'
import { DateRangePicker } from 'react-date-range'
import * as rdrLocales from 'react-date-range/dist/locale'

class Configuracao extends Component {
    state = {
        datePickerShow: false,
        dateRange: { startDate: new Date(), endDate: new Date(), key: 'selection' },
    }

    handleChangePeriodo = (ranges) => {
        let detalhes = Object.assign({}, this.props.detalhes)

        detalhes["periodo"] = ranges.selection.startDate.toLocaleDateString() + "-" + ranges.selection.endDate.toLocaleDateString()
        detalhes['dataFormatada'] = ranges.selection.startDate.toLocaleDateString() + " - " + ranges.selection.endDate.toLocaleDateString()

        this.props.setDetalhes(detalhes)

        this.setState({
            dateRange: {
                startDate: ranges.selection.startDate,
                endDate: ranges.selection.endDate,
                key: 'selection',
            }
        })
    }

    showDatePicker = () => {
        this.setState({ datePickerShow: !this.state.datePickerShow })
    }

    handleInput = (key, value) => {
        let detalhes = Object.assign({}, this.props.detalhes)

        detalhes[key] = value

        this.props.setDetalhes(detalhes)
    }

    render() {
        return (
            <div className="hdv-form-default">
                <div className="row">
                    <div className="col-md-6">
                        <label className="required">Descrição</label>
                        <input value={this.props.detalhes.descricao ?? ""} onChange={(e) => this.handleInput("descricao", e.target.value)} id="descricao" type="text" />
                        {!this.props.validacao.descricao ? <span id='validacao-descricao'>Campo Obrigatório</span> : null}
                    </div>
                </div>

                <br />

                <div style={{ display: this.props.edit ? "flex" : "none" }} className="row">
                    <div className="col-md-5">
                        <label className="required mt-0">Periodo</label>
                        <InputGroup className="hdv-force-hight">
                            <Input className="hdv-force-hight" placeholder="Selecione o periodo" onChange={() => false} disabled value={this.props.detalhes.dataFormatada} />
                            <InputGroupAddon addonType="append">
                                <Button onClick={this.showDatePicker} color={(this.state.datePickerShow) ? "danger" : "primary"}>
                                    <i className={(this.state.datePickerShow) ? "fa fa-calendar-times-o" : "fa fa-calendar-check-o"}></i>
                                    {(this.state.datePickerShow) ? "Fechar Calendário" : "Exibir Calendário"}
                                </Button>
                            </InputGroupAddon>
                        </InputGroup>
                        {!this.props.validacao.periodo ? <span id='validacao-periodo'>Campo Obrigatório</span> : null}
                        <div style={{ position: "initial" }} className={(this.state.datePickerShow) ? "hdv-datepicker" : "hdv-datepicker-display-none"}>
                            <DateRangePicker locale={rdrLocales.pt} ranges={[this.state.dateRange]} onChange={this.handleChangePeriodo} />
                        </div>
                    </div>
                    <div className="col-md-2">
                        <label className="required mt-0">Início</label>
                        <input value={this.props.detalhes.inicio ?? ""} style={{ width: "100%" }} onChange={(e) => this.handleInput("inicio", e.target.value)} type="time" />
                        {!this.props.validacao.inicio ? <span id='validacao-descricao'>Campo Obrigatório</span> : null}
                    </div>
                    <div className="col-md-2">
                        <label className="required mt-0">Fim</label>
                        <input value={this.props.detalhes.fim ?? ""} style={{ width: "100%" }} onChange={(e) => this.handleInput("fim", e.target.value)} type="time" />
                        {!this.props.validacao.fim ? <span id='validacao-descricao'>Campo Obrigatório</span> : null}
                    </div>
                </div>
            </div>
        )
    }
}

export default Configuracao