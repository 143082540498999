import React from "react"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import Table from '../../components/Table'
import helper from "../../utils/helper"
import auth from "../../utils/auth"
import cfg from "../../utils/config"
import axios from "axios"

class ComandoCustomizado extends React.Component {
    state = {
        comandoId: null,
        dataTableComandos: [],
        detalhes: { descricao: "", estrutura_comando: "" },
        parametros: { nome_parametro: "", id: "", tipo: "text", selectValues: [] },
        descValor: { descricao: "", valor: "" },
        buttonParametrosTestar: true,
        openModal: false,
        editIndex: "",
        buttonSalvar: true,
        stringTeste: "",
        paramsCard: {}
    }

    handleChangeComandos = (e) => {
        let { detalhes, buttonParametrosTestar } = this.state

        detalhes[e.target.id] = e.target.value
        detalhes[e.target.id][0] == " " ? detalhes[e.target.id] = "" : ""

        if (detalhes["descricao"] != "" && detalhes["estrutura_comando"] != "") {
            buttonParametrosTestar = false
        } else {
            buttonParametrosTestar = true
        }

        this.setState({ detalhes, buttonParametrosTestar, comandoTest: "", buttonSalvar: true })
    }

    componentDidMount() {
        if (this.props.match.params.idComando) {
            this.setState({ comandoId: this.props.match.params.idComando })
            this.getData(this.props.match.params.idComando)
        }
    }

    getData = (comandoId) => {
        const userInfo = auth.getUserInfo()
        this.setState({ loading: true })

        axios({
            url: cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/comando-customizado/' + comandoId,
            method: "GET",
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        }).then(res => {
            let detalhes = this.state.detalhes
            detalhes["descricao"] = res.data?.descricao
            detalhes["estrutura_comando"] = res.data?.estrutura
            let parametros = typeof res.data?.parametros === "string" ? JSON.parse(res.data?.parametros) : res.data?.parametros

            this.setState({
                detalhes,
                buttonParametrosTestar: false,
                dataTableComandos: parametros,
                loading: false
            })
        }).catch(console.error)
        this.setState({ buttonParametrosTestar: false })
    }

    handleChangeParametros = (e) => {
        let parametros = Object.assign({}, this.state.parametros)
        let descValor = Object.assign({}, this.state.descValor)

        if (e.target.id == "descricao" || e.target.id == "valor") {
            descValor[e.target.id] = e.target.value
            descValor[e.target.id][0] == " " ? descValor[e.target.id] = "" : ""
        } else {
            parametros[e.target.id] = e.target.value
            parametros[e.target.id][0] == " " ? parametros[e.target.id] = "" : ""
        }

        if (parametros["tipo"] == "text") {
            descValor["descricao"] = ""
            descValor["valor"] = ""
        }

        this.setState({ parametros: parametros, descValor: descValor })
    }

    validationTableParametros = () => {
        let { descValor } = this.state

        let validated = []

        if (descValor.descricao == "") {
            document.getElementById("validacao-descricao").classList.remove("hdv-noshow-item")
        } else {
            document.getElementById("validacao-descricao").classList.add("hdv-noshow-item")
            validated.push("descricao")
        }

        if (descValor.valor == "") {
            document.getElementById("validacao-valor").classList.remove("hdv-noshow-item")
        } else {
            document.getElementById("validacao-valor").classList.add("hdv-noshow-item")
            validated.push("valor")
        }

        return validated
    }

    validationTableComandos = () => {
        let { parametros } = this.state
        let validated = []

        if (parametros.nome_parametro == "") {
            document.getElementById("validacao-nome_parametro").classList.remove("hdv-noshow-item")
        } else {
            document.getElementById("validacao-nome_parametro").classList.add("hdv-noshow-item")
            validated.push("nome_parametro")
        }

        if (parametros.id == "") {
            document.getElementById("validacao-id").classList.remove("hdv-noshow-item")
        } else {
            document.getElementById("validacao-id").classList.add("hdv-noshow-item")
            validated.push("id")
        }

        if (validated.length == 2) {
            if (parametros.tipo == "select" && parametros.selectValues.length == 0) {
                helper.dispatchEvent("showAviso", {
                    message: 'É preciso adicionar um item na tabela!',
                    callback: () => this.setState({ openModal: false })
                })
            }
            else {
                this.addTableComandos()
            }
        }
    }

    addTableParametros = (event) => {
        event.preventDefault()

        let temp = this.state.descValor
        let parametros = this.state.parametros

        if (this.validationTableParametros().length == 2) {
            parametros.selectValues.push({
                descricao: temp.descricao,
                valor: temp.valor
            })
            temp.descricao = ""
            temp.valor = ""
        }

        document.getElementById("descricao").focus()
        this.setState({ parametros: parametros, descValor: temp })
    }

    deleteTableParametros = (deleteIndex) => {
        let temp = this.state.parametros

        let deletado = temp.selectValues.filter((valor, index) => index != deleteIndex)

        temp.selectValues = deletado

        this.setState({ parametros: temp })
    }

    checkId = (dataTableComandos, parametros, index) => {
        let validatedId = false

        if (index != "" && dataTableComandos[index].id == parametros.id) {
            validatedId = true
        } else {
            let filter_id = dataTableComandos.filter((valor, index) => {
                return valor.id == parametros.id
            })

            filter_id.length > 0 ? validatedId = false : validatedId = true
        }

        return validatedId
    }

    addTableComandos = () => {
        let { dataTableComandos, parametros, editIndex } = this.state

        let temp = {
            nome_parametro: parametros.nome_parametro,
            id: parametros.id,
            tipo: parametros.tipo,
            selectValues: parametros.selectValues,
        }

        if (this.checkId(dataTableComandos, parametros, editIndex) == true) {
            editIndex != "" ? dataTableComandos[editIndex] = temp : dataTableComandos.push(temp)
            parametros.tipo == "text" ? temp.selectValues = [] : ""
            this.clearStateModal()
        } else {
            helper.dispatchEvent("showAviso", {
                message: 'Este id já está cadastrado, cadastre outro id!',
                callback: () => this.setState({ openModal: false })
            })
        }

        this.setState({ dataTableComandos, comandoTest: "", buttonSalvar: true })
    }

    deleteTableComandos = (deleteItem) => {
        let dataTableComandos = this.state.dataTableComandos
        let filter = dataTableComandos.filter((valor) => valor.id != deleteItem)

        this.setState({ dataTableComandos: filter, comandoTest: "", buttonSalvar: true })
    }

    editTableComandos = (itemEdit) => {
        let dataTableComandos = JSON.parse(JSON.stringify(this.state.dataTableComandos))

        let edit = dataTableComandos.filter((valor) => itemEdit == valor.id)
        let index = dataTableComandos.findIndex((indexItem) => indexItem == edit[0])

        if (edit[0].tipo == "select") {
            edit[0].valor = ""
        }

        this.setState({ parametros: edit[0], editIndex: String(index), openModal: true })
    }

    clearStateModal = () => {
        this.setState({
            parametros: { nome_parametro: "", id: "", tipo: "text", selectValues: [] },
            descValor: { descricao: "", valor: "" },
            openModal: false,
            editIndex: "",
        })
    }

    saveAction = () => {
        let { detalhes, dataTableComandos, comandoId } = this.state
        let requestURL = ''
        let method = ''
        let userInfo = auth.getUserInfo()

        if (comandoId) {
            requestURL = cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + `/comando-customizado/${comandoId}/`
            method = "PATCH"
        } else {
            requestURL = cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/comando-customizado/'
            method = "POST"
        }

        let bodyFormData = new FormData()

        bodyFormData.append("descricao", detalhes.descricao)
        bodyFormData.append("estrutura", detalhes.estrutura_comando)
        bodyFormData.append("parametros", JSON.stringify(dataTableComandos))
        bodyFormData.append("equipamento", this.props.match.params.id)

        axios({
            method: method,
            url: requestURL,
            data: bodyFormData,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        })
            .then(() => {
                helper.dispatchEvent("showAviso", {
                    message: `Comando ${method === "POST" ? 'Criado' : 'Editado'} com sucesso`,
                    callback: this.props.history.goBack
                })
            })
            .catch(console.error)
    }

    test = () => {
        let { buttonSalvar, dataTableComandos, detalhes } = this.state
        let string_formula = detalhes.estrutura_comando
        let paramsCard = {}

        if (dataTableComandos.length > 0) {
            dataTableComandos.map((valor) => {
                if (string_formula.includes(`{${valor.id}}`)) {
                    string_formula = string_formula.replace(`{${valor.id}}`, document.getElementById(valor.id).value)
                    paramsCard[valor.id] = document.getElementById(valor.id).value

                    if (valor.tipo === 'text') {
                        document.getElementById(valor.id).value === "" ? string_formula = "" : null
                    }
                } else {
                    string_formula = ""
                }
            })
        } else {
            string_formula = string_formula.replace(/[{}]/g, "")
        }

        if (string_formula == "") {
            string_formula = "Fórmula inválida!"
            buttonSalvar = true
        } else {
            buttonSalvar = false
        }

        this.setState({ stringTeste: string_formula, buttonSalvar, paramsCard: paramsCard })
    }

    openModal = () => {
        this.setState({ openModal: true })
    }

    render() {
        let { parametros, dataTableComandos } = this.state

        const columnsComandos = [
            {
                Header: "Descrição",
                Accessor: "nome_parametro",
            },
            {
                Header: "Tipo Valor",
                Accessor: "tipo",
                Cell: (value) => {
                    if (value.tipo === "text") return <span>Valor Único</span>
                    else return <span>Multiplos Valores</span>
                }
            },
            {
                Header: "id",
                Accessor: "id",
            },
            {
                Header: "",
                Accessor: "",
                Cell: (item) => {
                    return (
                        <div className="d-flex justify-content-end">
                            <button
                                onClick={() => this.editTableComandos(item.id)}
                                className="hdv-chart-edit hdv-automacao-delete-button d-flex align-items-center"
                                style={{ color: '#333', width: '60px', border: '1px solid #cacaca' }}>
                                <i className="fa fa-pen" style={{ fontSize: '22px' }}></i> Editar
                            </button>
                            <button
                                style={{ color: '#fff', width: '65px' }}
                                onClick={() => this.deleteTableComandos(item.id)}
                                className="hdv-chart-close  hdv-btn-red hdv-automacao-delete-button d-flex align-items-center">
                                <i className="fa fa-times" style={{ fontSize: '22px' }}></i> Excluir
                            </button>
                        </div>
                    )
                }
            }
        ]

        return (
            <>
                <Modal size="lg" isOpen={this.state.openModal}>
                    <ModalHeader>Parâmetros</ModalHeader>
                    <ModalBody>
                        <form className="hdv-form-default" id="formParametros">
                            <div className="row">
                                <div className="col-md-6">
                                    <label htmlFor="nome_parametro" className="required">Nome</label>
                                    <input value={this.state.parametros.nome_parametro} id="nome_parametro" type="text" onChange={this.handleChangeParametros}></input>
                                    <span id="validacao-nome_parametro" className="hdv-noshow-item">Campo não pode ser nulo!</span>
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="id" className="required">Id</label>
                                    <input min={0} value={this.state.parametros.id} id="id" onChange={this.handleChangeParametros} type="text" placeholder=""></input>
                                    <span id="validacao-id" className="hdv-noshow-item">Campo não pode ser nulo!</span>
                                </div>
                            </div>
                            <label htmlFor="" className="required">Tipo do valor</label>
                            <select value={this.state.parametros.tipo} id="tipo" onChange={this.handleChangeParametros}>
                                <option value="text">Valor único</option>
                                <option value="select">Múltiplos valores</option>
                            </select>
                            {
                                this.state.parametros.tipo === "select" ?
                                    <div className="row d-flex align-items-center" style={{ borderTop: '1px solid #cacaca', marginTop: '14px' }}>
                                        <div className="col-md-5 hdv-form-default">
                                            <label htmlFor="descricao" className="required">Descrição</label>
                                            <input value={this.state.descValor.descricao} id="descricao" onChange={this.handleChangeParametros} type="text"></input>
                                            <span id="validacao-descricao" className="hdv-noshow-item">Campo não pode ser nulo!</span>
                                        </div>
                                        <div className="col-md-5 hdv-form-default">
                                            <label htmlFor="valor" className="required">Valor</label>
                                            <input value={this.state.descValor.valor} type="text" id="valor" onChange={this.handleChangeParametros} placeholder=""></input>
                                            <span id="validacao-valor" className="hdv-noshow-item">Campo não pode ser nulo!</span>
                                        </div>
                                        <div className="col-md-2" style={{ margin: "20px 0px" }}>
                                            <label className="required">Adicionar</label>
                                            <button onClick={this.addTableParametros} className="btn-cadastro-parametros">
                                                <i className="fa fa-plus fa-2x"></i>
                                            </button>
                                        </div>
                                    </div>
                                    :
                                    null
                            }
                        </form>
                        <div style={{ height: '200px', display: this.state.parametros.tipo == "text" ? "none" : "block", color: "#979797" }} className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Descrição</th>
                                        <th>Valor</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        parametros.selectValues.map((valor, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{valor.descricao}</td>
                                                    <td>{valor.valor}</td>
                                                    <td>
                                                        <button onClick={() => this.deleteTableParametros(index)}
                                                            style={{ color: '#fff', width: '65px' }}
                                                            className="hdv-chart-close  hdv-btn-red hdv-automacao-delete-button d-flex align-items-center">
                                                            <i className="fa fa-times" style={{ fontSize: '22px' }}></i>
                                                            Excluir
                                                        </button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button id="button-cadastrar-parametros" onClick={this.validationTableComandos} color="success">{this.state.editIndex != "" ? "Atualizar" : "Adicionar"}</Button>
                        <Button onClick={() => { this.setState({ openModal: false }); this.clearStateModal() }} color="default">Fechar</Button>
                    </ModalFooter>
                </Modal>

                <div className="hdv-render-div">
                    <div>
                        <div>
                            <div className="hdv-default-header mx-3">
                                <span className="screen-menu-desc">
                                    {this.state.comandoId ? <h4>Editar Comando</h4> : <h4>Cadastro De Comandos</h4>}
                                    <div className="gray-background">
                                        <i className="fa fa-hdd fa-2x"></i>
                                    </div>
                                </span>
                            </div>
                        </div>

                        <Tabs className="tabs-style">
                            <TabList>
                                <Tab>Comando</Tab>
                                <Tab>Parâmetros</Tab>
                            </TabList>

                            <TabPanel>
                                <div className="form-flex">
                                    <div className="col-md-9">
                                        <form className="hdv-form-default">
                                            <label htmlFor="descricao" className="required mt-0">Nome do Comando</label>
                                            <input id="descricao" value={this.state.detalhes.descricao} onChange={this.handleChangeComandos} type="text" placeholder="Digite o nome do comando..."></input>
                                            <span className="hdv-noshow-item">Campo não pode ser nulo!</span>
                                            <label htmlFor="estrutura_comando" className="required">Estrutura do Comando</label>
                                            <input id="estrutura_comando" value={this.state.detalhes.estrutura_comando} onChange={this.handleChangeComandos} type="text" placeholder="Exemplo: teste{ id }"></input>
                                            <span className="hdv-noshow-item">Campo não pode ser nulo!</span>
                                            <label className="required">Fórmula do comando</label>
                                            <input style={{ fontWeight: 'bold', color: this.state.stringTeste != 'Fórmula inválida!' ? 'rgb(63, 217, 89)' : 'tomato' }} value={this.state.stringTeste} type="text" disabled placeholder="teste para ver a fórmula ..."></input>
                                        </form>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="comandos-btn-editar py-2" style={{ width: "100%" }}>
                                            <div className="comandos-parametros">
                                                {dataTableComandos.map((valor, index) => (
                                                    <div key={index}>
                                                        <label style={{ margin: "0", fontSize: '13px', display: "flex", flexDirection: "column", fontWeight: "bold" }}>
                                                            {valor.nome_parametro}
                                                        </label>
                                                        {(valor.tipo == "select")
                                                            ?
                                                            <select defaultValue={valor.id in this.state.paramsCard ? this.state.paramsCard[valor.id] : null} id={valor.id}>
                                                                {
                                                                    valor.selectValues.map((valor, optionIndex) => (
                                                                        <option value={valor.valor} key={optionIndex}>
                                                                            {valor.descricao} ({valor.valor})
                                                                        </option>
                                                                    ))
                                                                }
                                                            </select>
                                                            :
                                                            <input defaultValue={valor.id in this.state.paramsCard ? this.state.paramsCard[valor.id] : null} onChange={() => this.setState({ buttonSalvar: true })} id={valor.id} placeholder="Digite o valor..."></input>
                                                        }
                                                    </div>
                                                )
                                                )}
                                            </div>
                                        </div>
                                        <div className="mt-1 d-flex flex-column justify-content-center">
                                            <button id="btn-testar" disabled={this.state.buttonParametrosTestar} onClick={this.test}>Testar</button>
                                        </div>
                                    </div>

                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div>
                                    <button
                                        disabled={this.state.buttonParametrosTestar}
                                        onClick={this.openModal}
                                        className="hdv-btn-forms hdv-btn-green mb-2">
                                        Novo Parâmetro
                                    </button>
                                    <Table
                                        notEditFirstColum={true}
                                        search={false}
                                        config={false}
                                        data={dataTableComandos}
                                        loading={this.state.loading}
                                        columns={columnsComandos}
                                        onUpdate={(table) => this.setState({ table })}
                                    />
                                </div>
                            </TabPanel>
                        </Tabs>
                    </div>

                    <div className="hdv-btn-group">
                        <button disabled={this.state.buttonSalvar} onClick={this.saveAction} className="hdv-btn-forms hdv-btn-green">Salvar e continuar</button>
                        <button onClick={this.props.history.goBack} className="hdv-btn-forms hdv-btn-orange">Cancelar</button>
                    </div>
                </div>

            </>
        )
    }
}

export default ComandoCustomizado