import React, { Component } from "react"
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import config from '../../../utils/config'
import auth from '../../../utils/auth'
import axios from 'axios'

class ModalFormObservacao extends Component {
    state = {
        motivos: [],
        dataObservacao: { observacao: "", motivo: "" },
        descricaoMotivo: "",
        erros: []
    }

    componentWillReceiveProps({ open, edit }) {
        if (open) {
            this.buscarMotivos()

            if (edit != "") {
                let dataEdit = {
                    observacao: edit[0].observacao,
                    motivo: edit[0].motivo == null ? "" : edit[0].motivo.id
                }

                this.setState({ dataObservacao: dataEdit })
            }
        }
    }

    buscarMotivos = () => {
        const userInfo = auth.getUserInfo()
        axios({
            method: 'GET',
            url: config.base_api_url + `${config.api_v2}/${userInfo.empresa}/observacao/motivo/`,
            headers: { 'Authorization': 'Bearer ' + auth.getToken() }
        }).then(res => {
            let motivos = []
            if (res.data)
                res.data.results.map(motivo => motivos.push({ label: motivo.descricao, value: motivo.id }))
            this.setState({ motivos })
        })
    }

    handleChangeObservacao = (e) => {
        let data = { ...this.state.dataObservacao }
        let value = e.target.value[0] == " " ? e.target.value.replace(" ", "") : e.target.value

        data[e.target.id] = value

        this.setState({ dataObservacao: data })
    }

    criarObservacao = (e) => {
        e.preventDefault()

        let { dataObservacao } = this.state
        let { monitorado } = this.props
        let erros = []
        let method
        let url
        const userInfo = auth.getUserInfo()
        let bodyFormData = new FormData()

        if (this.props.edit != "") {
            method = "PATCH"
            url = config.base_api_url + `${config.api_v2}/${userInfo.empresa}/observacao/${this.props.id}/`
        } else {
            method = "POST"
            url = config.base_api_url + `${config.api_v2}/${userInfo.empresa}/observacao/`
        }

        for (let i in dataObservacao) {
            if (dataObservacao[i] === "") erros.push(i)
        }

        this.setState({ erros: erros })

        if (erros.length > 0) return

        bodyFormData.append('observacao', dataObservacao.observacao)
        bodyFormData.append('motivo', dataObservacao.motivo)
        bodyFormData.append('monitorado', monitorado)

        axios({
            method: method,
            url: url,
            data: bodyFormData,
            headers: { 'Authorization': 'Bearer ' + auth.getToken() }
        })
            .then((data) => {
                this.setState({ dataObservacao: { observacao: "", motivo: "" } })
                this.props.closed()
            })
            .catch(err => console.log(err))
    }

    handleChangeMotivo = (e) => {
        let value = e.target.value[0] == " " ? e.target.value.replace(" ", "") : e.target.value

        this.setState({ [e.target.id]: value })
    }

    criarMotivo = (e) => {
        e.preventDefault()

        let { descricaoMotivo } = this.state
        let erros = []

        if (descricaoMotivo.length === 0) {
            erros.push('novo_motivo')
        }

        this.setState({ erros })

        if (erros.length > 0) return

        const userInfo = auth.getUserInfo()
        let data = new FormData()

        data.append('descricao', descricaoMotivo)
        axios({
            method: 'POST',
            url: config.base_api_url + `${config.api_v2}/${userInfo.empresa}/observacao/motivo/`,
            data: data,
            headers: { 'Authorization': 'Bearer ' + auth.getToken() }
        }).then(() => {
            this.setState({ descricaoMotivo: '' })
            this.buscarMotivos()
        }).catch(err => console.log(err))
    }

    excluirMotivo = (id) => {
        const userInfo = auth.getUserInfo()
        axios({
            method: 'DELETE',
            url: config.base_api_url + `${config.api_v2}/${userInfo.empresa}/observacao/motivo/${id}/`,
            headers: { 'Authorization': 'Bearer ' + auth.getToken() }
        }).then(() => this.buscarMotivos())
    }

    render() {
        const { motivos, erros } = this.state

        return (
            <>
                <Modal isOpen={this.props.open} className="hdv-observacao-modal modal-md">
                    <ModalHeader
                        toggle={() => {
                            this.props.closed()
                            this.setState({ dataObservacao: { observacao: "", motivo: "" }, erros: [] })
                        }}>
                        Observação
                    </ModalHeader>
                    <ModalBody style={{ padding: '12px' }}>
                        <Tabs>
                            <TabList>
                                <Tab>{this.props.edit != "" ? "Editar Observação" : "Nova Observação"}</Tab>
                                <Tab>Motivos</Tab>
                            </TabList>
                            <TabPanel>
                                <form className="hdv-form-default">
                                    <div className="row px-0" style={{ display: 'flex', alignItems: 'center' }}>
                                        <div className="col-md-12">
                                            <label htmlFor="motivo">Motivo</label>
                                            <select value={this.state.dataObservacao.motivo} onChange={this.handleChangeObservacao} id="motivo">
                                                <option value=""></option>
                                                {
                                                    motivos.map((motivo, key) => {
                                                        return (
                                                            <option key={key} value={motivo.value}>{motivo.label}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                            {erros.includes('motivo') ? <span id="validacao-nova-obs">Preencha todos os campos</span> : null}
                                        </div>
                                        <div className="col-md-12">
                                            <label htmlFor="observacao">Descrição</label>
                                            <textarea
                                                className="hdv-textarea"
                                                style={{ minHeight: "120px" }}
                                                type="text"
                                                name="observacao"
                                                id="observacao"
                                                value={this.state.dataObservacao.observacao}
                                                onChange={this.handleChangeObservacao}
                                            />
                                            {erros.includes('observacao') ?
                                                <span style={{ marginTop: "-3px" }} id="validacao-nova-descricao">Preencha todos os campos</span>
                                                : null}
                                        </div>
                                    </div>

                                    <div className="hdv-btn-group float-right">
                                        <button onClick={this.criarObservacao} className="hdv-btn-forms hdv-btn-green">
                                            {this.props.edit != "" ? "Atualizar Observação" : "Criar Observação"}
                                        </button>
                                    </div>
                                </form>
                            </TabPanel>
                            <TabPanel>
                                <div style={{ minHeight: '520px' }} className="hdv-form-default">
                                    <details className="create-profile-heading">
                                        <summary>Novo Motivo</summary>

                                        <div className="row row px-1 m-0 pb-3" style={{ display: 'flex', alignItems: 'center' }}>
                                            <div className="col-md-9">
                                                <label>Descrição do Motivo</label>
                                                <input
                                                    type="text"
                                                    id="descricaoMotivo"
                                                    value={this.state.descricaoMotivo}
                                                    onChange={this.handleChangeMotivo}
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <label style={{ opacity: "0" }}>Salvar</label>
                                                <button
                                                    onClick={this.criarMotivo}
                                                    className="hdv-btn-forms hdv-btn-green w-100">
                                                    Salvar
                                                </button>
                                            </div>
                                        </div>
                                    </details>

                                    {erros.includes('novo_motivo') ? <span id="validacao-novomotivo">Descrição não pode estar vazio!</span> : null}

                                    {motivos.length > 0 ?
                                        <div style={{ maxHeight: '380px', overflow: 'auto' }}>
                                            <table className="table" style={{ marginTop: '20px' }}>
                                                <thead>
                                                    <tr>
                                                        <th colSpan="2">Descrição do Motivo</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {motivos.map(motivo => (
                                                        <tr key={motivo.value}>
                                                            <td colSpan="2">{motivo.label}</td>
                                                            <td style={{ textAlign: 'right' }}>
                                                                <button
                                                                    className="hdv-btn-forms hdv-btn-red compact-btn"
                                                                    onClick={(e) => { this.excluirMotivo(motivo.value) }}
                                                                    style={{ color: '#DD5353' }}>
                                                                    Excluir
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                        : null
                                    }
                                </div>
                            </TabPanel>
                        </Tabs>
                    </ModalBody>
                </Modal>
            </>
        )
    }
}

export default ModalFormObservacao