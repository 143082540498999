import { Tabs, Tab, TabList, TabPanel } from 'react-tabs'
import { Link, Redirect } from 'react-router-dom'
import Select from '../../components/Select'
import { Component } from 'react'
import auth from '../../utils/auth'
import cfg from '../../utils/config'
import axios from 'axios'

export default class EquipamentoVirtual extends Component {

    constructor() {
        super()
        this.state = {
            data: new Date().toLocaleDateString().split('/').reverse().join('-'),
            hora: new Date().toLocaleTimeString().substring(0, 5),
            entradas_analogica: [],
            entradas_digital: [],
            camposAnalogicos: {},
            corrente_trifasica: '',
            tensao_trifasica: '',
            pulsoCalculado: '',
            camposDigitais: {},
            vazoes: [],
            status: [{}, {}, {}, {}, {}, {}, {}, {}],
            tempo: [],
            redirect: false,
            path: '',
        }
    }

    componentDidMount() {
        const imei = this.props.match.params.imei
        this.getData(imei)
    }

    getData = (imei) => {
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }
        let userInfo = auth.getUserInfo()

        axios.get(cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + `/lancamento-manual-dados/${imei}`, config_me)
            .then((res) => {
                let vazoes = []
                let tempo = []

                res.data.entradas_digital?.map(entrada => {
                    vazoes.push({ canal: entrada.canal, value: "" })
                    tempo.push({ canal: entrada.canal, value: "" })
                })

                this.setState({ entradas_analogica: res.data.entradas_analogica, entradas_digital: res.data.entradas_digital, vazoes: vazoes, tempo })
            })
            .catch(err => console.log(err))
    }

    handleChange = (e, tipoCampo) => {
        const campos = this.state[tipoCampo]
        campos[e.target.name] = e.target.value
        this.setState({ [tipoCampo]: campos })
    }

    handleChangeInput = e => this.setState({ [e.target.name]: e.target.value })

    handleChangePulso = (e, tipoCampo) => {
        const campos = this.state[tipoCampo]
        let pulso = this.state.entradas_digital.find(entrada => Number(entrada.tipo) === 1).pulso
        let calc = 1

        if (pulso === 1) {
            campos[e.target.name] = this.formatarPulso(e.target.value, 1000, 3)
            calc = 1000
        } else if (pulso === 10) {
            campos[e.target.name] = this.formatarPulso(e.target.value, 100, 2)
            calc = 100
        } else if (pulso === 100) {
            campos[e.target.name] = this.formatarPulso(e.target.value, 10, 1)
            calc = 10
        } else if (pulso === 1000)
            campos[e.target.name] = Math.floor(e.target.value)

        this.setState({ [tipoCampo]: campos, pulsoCalculado: campos[e.target.name] * calc })
    }

    formatarPulso = (value, multi, fixed) => {
        let v = value.replace(/\D/g, '')
        v = (v / multi).toFixed(fixed) + ''
        v = v.replace(" ", ",")
        v = v.replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2,")
        v = v.replace(/(\d)(\d{3}),/g, "$1.$2,")
        return v
    }

    handleChangeStatus = (e, entrada, tipoCampo) => {
        const campo = this.state[tipoCampo]
        const status = this.state.status
        status[entrada.canal - 1] = e
        campo[entrada.descricao.toLowerCase()] = e
        this.setState({ status: status, [tipoCampo]: campo })
    }

    handleChangeVazao = (e, canal) => {
        let { vazoes } = this.state

        const index = vazoes?.findIndex(v => v.canal === canal)
        if (index !== -1) {
            vazoes[index].value = e.target.value
            this.setState({ vazoes: vazoes })
        }
    }

    handleChangeTempo = (e, canal) => {
        let { tempo } = this.state
        const index = tempo?.findIndex(t => t.canal === canal)

        if (index !== -1) {
            tempo[index].value = e.target.value
            this.setState({ tempo: tempo })
        }
    }

    saveAction = () => {
        const { entradas_analogica, entradas_digital, camposAnalogicos, camposDigitais, vazoes, data, hora, tempo } = this.state
        const imei = this.props.match.params.imei
        const userInfo = auth.getUserInfo()
        let entradas_an = []
        let entradas_dg = []
        let dadosVazoes = []
        let status = []
        const formData = new FormData()
        let nivelCalculado

        for (let campo in camposAnalogicos) {
            if (camposAnalogicos[campo]) {
                const pos = entradas_analogica.find(entrada => entrada.descricao.toLowerCase() === campo)
                if (pos)
                    entradas_an[pos.canal - 1] = Number(camposAnalogicos[campo])
                else if (pos && !Number.isNaN(camposAnalogicos[campo]))
                    entradas_an[pos.canal - 1] = Number(camposAnalogicos[campo])

                if (pos.tipo === 9) {
                    nivelCalculado = ((1600 * ((pos.inverter - camposAnalogicos[campo]) - 1600 * pos.valor_minimo) / (pos.valor_maximo - pos.valor_minimo)) + 400).toFixed(0)
                    entradas_an[pos.canal - 1] = Number(nivelCalculado)
                } else if (pos.tipo === 1) {
                    nivelCalculado = ((1600 * ((camposAnalogicos[campo]) - 1600 * pos.valor_minimo) / (pos.valor_maximo - pos.valor_minimo)) + 400).toFixed(0)
                    entradas_an[pos.canal - 1] = Number(nivelCalculado)
                }
            }
        }

        for (let campo in camposDigitais) {
            if (camposDigitais[campo]) {
                const pos = entradas_digital.find(entrada => entrada.descricao.toLowerCase() === campo)

                if (pos) {
                    if (pos.tipo === 1)
                        entradas_dg[pos.canal - 1] = Number(this.state.pulsoCalculado)
                    else if (Number(pos.tipo) === 3) {
                        status[pos.canal - 1] = Number(camposDigitais[campo].value)
                        entradas_dg[pos.canal - 1] = 0
                    }
                    else
                        entradas_dg[pos.canal - 1] = Number(camposDigitais[campo])
                }
            }
        }

        for (let i = 0; i < vazoes.length; i++) {
            const vazao = tempo.find(t => t.canal === vazoes[i].canal)

            if (vazao) {
                const valor = String(vazao.value).length > 0 ? vazao.value : 0
                dadosVazoes[vazoes[i].canal - 1] = vazoes[i].value * valor
            }
        }

        status = Array.from(status, item => item || 0)
        dadosVazoes = Array.from(dadosVazoes, item => item || 0)
        entradas_an = Array.from(entradas_an, item => item || 0)
        entradas_dg = Array.from(entradas_dg, item => item || 0)

        let utc = new Date(data + ' ' + hora + ':00')
        utc = Date.UTC(utc.getFullYear(), utc.getMonth(), utc.getDate(), utc.getHours(), utc.getMinutes(), utc.getMilliseconds())

        formData.append('entradas_digitais', JSON.stringify(entradas_dg))
        formData.append('vazoes', JSON.stringify(dadosVazoes))
        formData.append('data', utc / 1000)
        formData.append('entradas_analogicas', JSON.stringify(entradas_an))
        formData.append('corrente_trifasica', this.state.corrente_trifasica || 0)
        formData.append('tensao_trifasica', this.state.tensao_trifasica || 0)
        formData.append('status', JSON.stringify(status))

        axios({
            method: 'POST',
            url: cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + `/lancamento-manual-dados/${imei}/`,
            data: formData,
            headers: { 'Authorization': 'Bearer ' + auth.getToken() }
        })
            .then((res) => this.setState({ redirect: true, path: "/virtual" }))
            .catch(err => console.log(err))
    }

    handleData = data => this.setState({ data })

    handleHora = hora => this.setState({ hora })

    render() {
        if (this.state.redirect === true) {
            return <Redirect to={this.state.path} />
        }

        const { entradas_analogica, entradas_digital, camposDigitais, vazoes, camposAnalogicos, tempo } = this.state
        const status = [
            { label: 'Desligado', value: '0' },
            { label: 'Ligado', value: '1' }
        ]

        return (
            <>
                <div className="hdv-default-header mx-3">
                    <span className="screen-menu-desc">
                        <h4>Reporte Manual - Equipamento Virtual</h4>
                        <div className="gray-background">
                            <i className="fa fa-mobile fa-2x"></i>
                        </div>
                    </span>
                </div>

                <Tabs className="tabs-style">
                    <TabList>
                        <Tab>Entradas Analógicas</Tab>
                        <Tab>Entradas Digitais</Tab>
                    </TabList>
                    <TabPanel>
                        <form className="hdv-form-default row">
                            {entradas_analogica.map((entrada, index) => (
                                <div className="col-md-6" key={index}>
                                    <label className="required" htmlFor="descricao">{entrada.descricao} {entrada.unidade ? `(${entrada.unidade})` : null}</label>
                                    <input
                                        type="number"
                                        id={entrada.descricao}
                                        name={entrada.descricao.toLowerCase()}
                                        placeholder={`${entrada.valor_minimo} - ${entrada.valor_maximo}`}
                                        value={camposAnalogicos[entrada.descricao.toLowerCase()]}
                                        onChange={(e) => this.handleChange(e, 'camposAnalogicos')}
                                        min={entrada.valor_minimo}
                                        max={entrada.valor_maximo}
                                    />
                                </div>
                            ))}
                            <div className="col-md-6" >
                                <label className="required" htmlFor="corrente_trifasica">Corrente Trifásica</label>
                                <input
                                    type="number"
                                    name="corrente_trifasica"
                                    id="corrente_trifasica"
                                    value={this.state.corrente_trifasica}
                                    onChange={(e) => this.handleChangeInput(e)}
                                />
                            </div>
                            <div className="col-md-6" >
                                <label className="required" htmlFor="corrente_trifasica">Tensão Trifásica</label>
                                <input
                                    type="number"
                                    name="tensao_trifasica"
                                    id="tensao_trifasica"
                                    value={this.state.tensao_trifasica}
                                    onChange={(e) => this.handleChangeInput(e)}
                                />
                            </div>
                        </form>
                    </TabPanel>

                    <TabPanel>
                        <form className="hdv-form-default row">
                            {entradas_digital.map((entrada, index) =>
                                entrada.tipo === 1 ?
                                    <React.Fragment key={index}>
                                        <div className="col-md-6">
                                            <label className="required" htmlFor={entrada.descricao}>{entrada.descricao} {entrada.unidade ? `(${entrada.unidade})` : null}</label>
                                            <input
                                                type="number"
                                                id={entrada.descricao}
                                                name={entrada.descricao.toLowerCase()}
                                                value={camposDigitais[entrada.descricao.toLowerCase()]}
                                                onChange={(e) => this.handleChangePulso(e, 'camposDigitais')}
                                            />
                                        </div>
                                        <div className="col-md-6" >
                                            <label className="required" htmlFor="vazao">Vazão</label>
                                            <input
                                                type="number"
                                                name="vazao"
                                                value={vazoes?.find(v => v.canal === (entrada.canal))?.value || ''}
                                                onChange={(e) => this.handleChangeVazao(e, entrada.canal, entrada.descricao)}
                                            />
                                        </div>
                                        <div className="col-md-6" >
                                            <label className="required" htmlFor="tempo">Tempo (segundos)</label>
                                            <input
                                                type="number"
                                                name="tempo"
                                                value={tempo?.find(t => t.canal === (entrada.canal))?.value || ''}
                                                onChange={(e) => this.handleChangeTempo(e, entrada.canal, entrada.descricao)}
                                            />
                                        </div>
                                    </React.Fragment>
                                    : entrada.tipo === 3
                                        ?
                                        <div className="col-md-6" key={index}>
                                            <label className="required" htmlFor={entrada.descricao}>{entrada.descricao} {entrada.unidade ? `(${entrada.unidade})` : null}</label>
                                            <Select
                                                value={camposDigitais[entrada.descricao.toLowerCase()] || { label: 'Selecione o Status', value: "0" }}
                                                onChange={(e) => this.handleChangeStatus(e, entrada, 'camposDigitais')}
                                                options={status}
                                            />
                                        </div>
                                        :
                                        <div className="col-md-6" key={index}>
                                            <label className="required" htmlFor={entrada.descricao}>{entrada.descricao} {entrada.unidade ? `(${entrada.unidade})` : null}</label>
                                            <input
                                                type="number"
                                                name={entrada.descricao.toLowerCase()}
                                                value={camposDigitais[entrada.descricao.toLowerCase()]}
                                                onChange={(e) => this.handleChange(e, 'camposDigitais')}
                                            />
                                        </div>
                            )}
                        </form>
                    </TabPanel>

                    <div className="row">
                        <div className="col-md-3 hdv-form-default">
                            <label className="required" htmlFor="data">Data<br />
                                <input type="date" value={this.state.data} onChange={(e) => this.handleData(e.target.value)} />
                            </label>
                        </div>
                        <div className="col-md-3 hdv-form-default">
                            <label className="required">Hora<br />
                                <input type="time" style={{ width: '100%' }} value={this.state.hora} onChange={(e) => this.handleHora(e.target.value)} />
                            </label>
                        </div>
                    </div>
                    <div className="hdv-btn-group">
                        <button onClick={() => this.saveAction()} className="hdv-btn-forms hdv-btn-green">Salvar</button>
                        <Link to="/virtual">
                            <button className="hdv-btn-forms hdv-btn-orange">Cancelar</button>
                        </Link>
                    </div>
                </Tabs>
            </>
        )
    }
}