import { Component } from "react"
import {
    Button,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    UncontrolledDropdown
} from "reactstrap"
import config from "../../../utils/config"
import auth from "../../../utils/auth"
import axios from "axios"


export class SupervisorioLinkForm extends Component {
    state = {
        editMode: { ativo: false, link: null },
        modal: false,
        link: null,
        links: [],
        descricao: "",
        expiraEm: "",
        erros: [],
        newLinkModal: false,
    }

    getLinks = () => {
        let userInfo = auth.getUserInfo()

        axios({
            url: `${config.base_api_url + config.api_v2}/${userInfo.empresa}/supervisorio-link`,
            method: "GET",
            headers: { 'Authorization': 'Bearer ' + auth.getToken() }
        }).then(res => {
            let links = []

            res.data?.results.forEach((link) => {
                if (link.supervisorio === this.props.supervisorioSelecionado.id) {
                    link.expiraEm = new Date(link.timestamp_limite * 1000).toLocaleDateString("pt-BR", { hour: "numeric", minute: "numeric" })
                    links.push(link)
                }
            })

            this.setState({ links })
        })
    }

    toggleEdit = (value, dados = null) => {
        dados.expiraEm = dados.expiraEm.split(" ")[0]?.replace(",", "").split("/").reverse().join("-")

        this.setState({
            newLinkModal: true,
            editMode: { ativo: value, link: dados.link },
            descricao: dados.descricao,
            expiraEm: dados.expiraEm
        })
    }

    cancelEdit = () => {
        this.setState({ editMode: { ativo: false, link: null }, descricao: "", expiraEm: "" })
    }

    saveAction = () => {
        const { supervisorioSelecionado } = this.props
        let userInfo = auth.getUserInfo()
        let compartilhar = this.state
        let erros = []
        let timestampLimite
        let url = `${config.base_api_url + config.api_v2}/${userInfo.empresa}/supervisorio-link/`
        let method = "POST"

        if (this.state.editMode.ativo) {
            url = `${config.base_api_url + config.api_v2}/${userInfo.empresa}/supervisorio-link/${this.state.editMode.link.id}/`
            method = "PATCH"
        }

        const bodyFormData = new FormData()

        if (!compartilhar.descricao) {
            erros.push("Digite uma descrição!")
        } else if (!compartilhar.descricao || compartilhar.descricao?.length <= 3) {
            erros.push("Descrição muito curta!")
        }

        if (!compartilhar.expiraEm) {
            erros.push("Insira uma data válida!")
        } else {
            const limitDate = new Date(`${compartilhar.expiraEm} 23:59:59`).getTime()
            const now = new Date().getTime() - 1

            if (limitDate < now) {
                erros.push("Insira uma data válida!")
            } else {
                timestampLimite = limitDate
            }
        }

        compartilhar.erros = erros
        this.setState({ compartilhar })

        if (erros.length > 0) return

        bodyFormData.append("supervisorio", supervisorioSelecionado.id)
        bodyFormData.append("descricao", compartilhar.descricao)
        bodyFormData.append("timestamp_limite", timestampLimite / 1000)

        axios({
            url: url,
            method: method,
            data: bodyFormData,
            headers: { 'Authorization': 'Bearer ' + auth.getToken() }
        })
            .then(res => {
                this.getLinks()
                this.setState({
                    newLinkModal: false,
                    editMode: { ativo: false, link: null },
                    descricao: "",
                    expiraEm: "",
                })
            })
    }

    deleteLink = (link) => {
        this.closeDetails()
        let userInfo = auth.getUserInfo()

        axios({
            url: `${config.base_api_url + config.api_v2}/${userInfo.empresa}/supervisorio-link/${link.id}`,
            method: "DELETE",
            headers: { 'Authorization': 'Bearer ' + auth.getToken() }
        })
            .then(res => this.getLinks())
    }

    copy = (link) => {
        this.closeDetails()
        navigator.clipboard.writeText(`${window.location.href}/${link.id_hash}/`)
    }

    toggleModal = () => {
        if (!this.state.modal) {
            this.getLinks()
        }

        this.setState({ modal: !this.state.modal })
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    shareSupervisory = () => {
        document.querySelector('details.hdv-super-popup').open = false
        this.toggleModal()
    }

    goTo = (link) => {
        window.open(`/supervisorio/${link.id_hash}`)
        this.closeDetails()
    }

    closeDetails = () => {
        document.querySelectorAll('details.hdv-popover').forEach(e => { e.open = false })
    }

    createTableRow = (link, index) => {
        return (
            <tr className="link-item" key={index}>
                <td>{link.descricao}</td>
                <td>
                    {(link.timestamp_limite * 1000) >= new Date().getTime()
                        ?
                        <span style={{ fontSize: "11px" }} className="badge text-light bg-success">Sim</span>
                        :
                        <span style={{ fontSize: "11px" }} className="badge text-light bg-secondary">Não</span>
                    }
                </td>
                <td>{link.expiraEm}</td>
                {/* <td>{link.id_hash}</td> */}
                <td style={{ width: "40px" }}>
                    <UncontrolledDropdown>
                        <DropdownToggle className="dropdown-item">
                            <i className="fa fa fa-ellipsis-v fa-2x" style={{ marginRight: "0" }}></i>
                        </DropdownToggle>
                        <DropdownMenu className="mini-modal" right>
                            <DropdownItem tag="a" onClick={() => this.goTo(link)}>
                                <span className="menu-bar-dropdown-item">
                                    <i className="fa fa-external-link-alt fa-2x"></i>
                                    Visualizar
                                </span>
                            </DropdownItem>
                            <DropdownItem tag="a" onClick={() => this.copy(link)}>
                                <span className="menu-bar-dropdown-item">
                                    <i className="fa fa-copy fa-2x"></i>
                                    Copiar link
                                </span>
                            </DropdownItem>
                            <DropdownItem
                                tag="a"
                                onClick={() => this.toggleEdit(true, { descricao: link.descricao, expiraEm: link.expiraEm, link })}>
                                <span className="menu-bar-dropdown-item">
                                    <i className="fa fa-pen fa-2x"></i>
                                    Editar
                                </span>
                            </DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem tag="a" onClick={() => this.deleteLink(link)}>
                                <span className="menu-bar-dropdown-item">
                                    <i className="fa fa-times fa-2x"></i>
                                    Deletar
                                </span>
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </td>
            </tr>
        )
    }

    render() {
        const { newLinkModal, editMode } = this.state

        return (
            <>
                <Modal size="lg" isOpen={this.state.modal} toggle={this.toggleModal}>
                    <ModalHeader style={{ borderBottom: 'none' }} toggle={this.toggleModal}>Gerenciar Links</ModalHeader>
                    <ModalBody className="py-0 px-3 mb-3">
                        <div className="hdv-links">
                            {this.state.links.length === 0
                                ?
                                <p style={{ textAlign: 'center', marginTop: "90px" }}>Não há links para esse supervisório</p>
                                :
                                <table
                                    className={this.state.links.length > 0 ? "table table-sm table-borderless table-striped-columns" : "hdv-noshow-item"}>
                                    <thead>
                                        <tr>
                                            <th>Descrição</th>
                                            <th>Válido</th>
                                            <th>Expira em</th>
                                            {/* <th>Hash</th> */}
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.links?.map((link, index) => this.createTableRow(link, index))}
                                    </tbody>
                                </table>
                            }
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button
                            className="hdv-btn-forms hdv-btn-green float-right"
                            onClick={() => this.setState({ newLinkModal: true })}>
                            Novo Link
                        </button>
                        <Button color="default" onClick={this.toggleModal}>Fechar</Button>
                    </ModalFooter>
                </Modal>

                <Modal size="lg" isOpen={newLinkModal} toggle={() => this.setState({ newLinkModal: !newLinkModal })}>
                    <ModalHeader style={{ borderBottom: 'none' }} toggle={() => this.setState({ newLinkModal: !newLinkModal })}>
                        {editMode.ativo ? "Editar Link" : "Novo Link"}
                    </ModalHeader>
                    <ModalBody className="py-0 px-3 mb-3">
                        <div className="row hdv-widget-popup">
                            <label className="col-md-6">
                                Descrição
                                <input
                                    type="text"
                                    value={this.state.descricao}
                                    onChange={this.handleChange}
                                    name="descricao"
                                />
                            </label>

                            <label className="col-md-6">
                                Data de expiração do link
                                <input
                                    type="date"
                                    value={this.state.expiraEm}
                                    onChange={this.handleChange}
                                    name="expiraEm"
                                />
                            </label>
                            {this.state.link ? "Link copiado para a àrea de transferência" : null}
                        </div>

                        {this.state.erros?.map((error, index) => (
                            <span key={index} style={{ color: "#c4333a", display: "block", fontSize: "14px" }}>{error}</span>
                        ))
                        }
                        <br />

                    </ModalBody>
                    <ModalFooter>
                        {this.state.editMode.ativo
                            ?
                            <div>
                                <Button color="primary" onClick={this.saveAction} className="float-right ml-2">Salvar</Button>
                                <Button color="secondary" onClick={this.cancelEdit} className="float-right ml-2">Cancelar</Button>
                            </div>
                            :
                            <button onClick={this.saveAction} className="float-right ml-2 hdv-btn-forms hdv-btn-blue">Gerar Link</button>
                        }
                        <Button color="default" onClick={() => this.setState({ newLinkModal: false })}>Fechar</Button>
                    </ModalFooter>
                </Modal>

                <button
                    style={{ display: (auth?.getUserInfo()?.['tipo'] === "cliente") ? "none" : "inline" }}
                    disabled={(this.props.supervisorioSelecionado) ? false : true}
                    onClick={this.shareSupervisory}
                    className="hdv-barsuper-default hdv-popup-button">
                    <i className="fa fa-external-link-alt fa-2x"></i>
                    Gerenciar Links
                </button>
            </>
        )
    }
}
