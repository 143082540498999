import React, { Component } from 'react'
import ReactECharts from 'echarts-for-react'
import cfg from '../../../utils/config'
import auth from '../../../utils/auth'
import moment from 'moment'
import axios from 'axios'

class GraficoLinha extends Component {
    rid = "chart-" + (Math.random() * 16).toString().slice(-6)

    state = {
        chart: null,
        lastUpdate: null,
        params: {
            descricao: "",
            values: [],
            dates: [],
            valores_anteriores: false,
            valor_minimo: false,
            valor_maximo: false
        },
    }

    componentDidMount = () => {
        if (this.props.dataWidget) {
            let { params } = this.state
            let { dataWidget } = this.props

            params.descricao = decodeURI(dataWidget['descricao'])
            params.valor_minimo = dataWidget.params.valor_minimo
            params.valor_maximo = dataWidget.params.valor_maximo
            params.periodo = dataWidget.params.periodo
            params.valores_anteriores = dataWidget.params.valores_anteriores

            if (params.valores_anteriores && !dataWidget?.shared) {
                this.getChartData(params)
            }

            params.values.push(dataWidget.value)
            params.dates.push(dataWidget.date)
            this.setState({ params })
            this.updateChart(params.values, params.dates)
        }
    }

    convertTimezone = (dataUTC, format) => {
        const systemTZ = Intl.DateTimeFormat().resolvedOptions().timeZone
        try {
            let timezone = auth.getUserInfo()?.timezone ?? this.props.timezone
            if (!timezone) {
                timezone = systemTZ
            }

            return moment.tz(dataUTC, 'UTC').tz(timezone).format(format)
        } catch (err) {
            return moment.tz(new Date().getTime(), 'UTC').tz(systemTZ).format(format)
        }
    }

    getChartData = (params) => {
        let { equipamento, campo } = this.props.dataWidget
        let userInfo = auth.getUserInfo()
        let bodyForm = new FormData()

        bodyForm.append("equipamento", equipamento)
        bodyForm.append("campo", campo)
        bodyForm.append("data_inicio", new Date().toLocaleDateString().split("/").reverse().join("-"))
        bodyForm.append("data_fim", new Date().toLocaleDateString().split("/").reverse().join("-"))

        axios({
            url: `${cfg.base_api_url + cfg.api_v2}/${userInfo.empresa}/monitorado-status-grafico/`,
            method: "POST",
            data: bodyForm,
            headers: { 'Authorization': 'Bearer ' + auth.getToken() }
        }).then((res) => {
            let values = []
            let dates = []

            try {
                const dataPerHour = (params.periodo ?? 1) * 60
                let { data } = res.data

                Object.keys(data)?.forEach((key) => {
                    if (Array.isArray(data?.[key])) {
                        data?.[key]?.forEach((item) => {
                            dates.push(this.convertTimezone(item?.timestamp_dispositivo * 1000, 'DD/MM HH:mm'))
                            values.push(item[campo])
                        })
                    }
                })

                if (dates?.length >= dataPerHour) {
                    dates = dates.slice(-dataPerHour)
                    values = values.slice(-dataPerHour)
                }

                this.setState({ params: { values, dates } })
                this.updateChart(values, dates)
            } catch (err) {
                console.error(err)
            }
        }).catch(console.error)
    }

    componentWillReceiveProps = (nextProps) => {
        let { lastUpdate } = this.state

        try {
            if (new Date().getTime() - lastUpdate > 60_000) {
                let { params } = this.state
                let { dataWidget } = this.props

                if (params.periodo && params.dates.length > 0) {
                    let dateHour = (params.periodo ?? 1) * 60

                    if (params.dates.length >= dateHour) {
                        params.values.shift()
                        params.dates.shift()
                    }
                }

                params.values.push(dataWidget.value)
                params.dates.push(dataWidget.date)

                this.setState({ params })
                this.updateChart(params.values, params.dates)
            }
        } catch (err) {
            console.error(err)
        }
    }

    updateChart = (values, dates) => {
        let { params } = this.state
        let series = [{
            type: 'line', data: values, name: params.descricao,
            label: { show: true, position: 'top', opacity: '0.8' }
        }]

        if (Boolean(Number.parseInt(params.valor_minimo))) {
            let minData = []
            values.forEach(v => minData.push(params.valor_minimo))
            series.push({ type: 'line', data: minData, name: "Valor Mínimo", color: "#44dba6" })
        }

        if (Boolean(Number.parseInt(params.valor_maximo))) {
            let maxData = []
            values.forEach(v => maxData.push(params.valor_maximo))
            series.push({ type: 'line', data: maxData, name: "Valor Máximo", color: "#d48a3b" })
        }

        const options = {
            legend: { show: true },
            grid: { top: 30, right: 20, bottom: 20, left: 60 },
            series: series,
            xAxis: {
                data: dates,
                nameTextStyle: { fontWeight: '600', fontSize: '12' },
                nameLocation: 'center',
                nameGap: 25
            },
            yAxis: { type: 'value' },
            tooltip: {
                show: true,
                trigger: "axis",
                axisPointer: { label: { show: true } },
            },
        }

        this.setState({ chart: null })
        setTimeout(() => {
            try {
                const parent = document.querySelector(`#${this.rid}`).parentElement
                const style = { height: `${parent.clientHeight - 80}px`, width: '100%', marginTop: '10px' }

                this.setState({
                    lastUpdate: new Date().getTime(),
                    chart: <ReactECharts option={options} style={style} />
                })
            } catch (err) { }
        }, 10)
    }

    render() {

        return (
            <div className="hdv-bloco-informacao hdv-chart-bloco-reactgridlayout">
                {this.props.dataWidget?.ultimoEvento({ showUnity: false })}
                <div id={this.rid}>
                    {this.state.chart}
                </div>
            </div>
        )
    }
}

export default GraficoLinha