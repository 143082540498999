import React, { Component } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { Redirect } from 'react-router-dom'
import ButtonsForm from '../../components/ButtonsForm'
import Select from '../../components/Select'
import ModalImage from '../../components/ModalImage'
import helper from '../../utils/helper'
import Mapa from '../../components/Mapa'
import auth from '../../utils/auth'
import cfg from '../../utils/config'
import axios from 'axios'


class Form extends Component {

    state = {
        mapObject: false,
        address: "",
        marker: false,
        location: false,
        detalhes: { "nome": "", "tipo": "", "latitude": 0, "longitude": 0, "point": "", "cliente": "" },
        formerrors: [],
        redirect: false,
        path: "",
        setores: [],
        tipos: [],
        clientes: [],
        item: { "equipamento_id": "", "entrada_tipo": "", "entrada_id": "" },
        optionSelected: { "tipo": {}, "cliente": {}, "equipamento_id": {}, "parametro_id": {}, "comando_id": {} },
        parametros_selecionados: [],
        equipamentos: [],
        parametros_vinculados: [],
        comandos_vinculados: [],
        fotos_vinculados: [],
        cooldown: false,
        foto_file: { foto_file: null, foto_descricao: "" },
        foto_temp: "https://rs485project.s3.amazonaws.com/sem-imagem-10.jpg",
        open_image: false,
        image: ""
    }

    updateMapObject = (mapObj) => {
        mapObj.google_map.maps.event.addListener(mapObj.map, 'click', (event) => {
            this.placeMarker(event.latLng)
        })

        this.setState({ mapObject: mapObj })

        if (this.props.match.params.id) {
            this.getData(mapObj)
        }
    }

    updatePoint = (location) => {
        this.setState({
            location: location,
            address: location.toJSON().lat.toFixed(6) + ", " + location.toJSON().lng.toFixed(6)
        })
    }

    placeMarker = (location) => {
        if (this.state.marker) {
            this.state.marker.setMap(null)
        }

        let marker = new this.state.mapObject.google_map.maps.Marker({
            map: this.state.mapObject.map,
            position: location,
            draggable: true
        })

        marker.addListener('dragend', (event) => this.updatePoint(event.latLng))

        this.setState({ marker: marker, location: location, address: location.toJSON().lat.toFixed(6) + ", " + location.toJSON().lng.toFixed(6) })
    }

    componentWillMount = () => {
        let requestArray = []
        let userInfo = auth.getUserInfo()
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }

        requestArray.push(axios.get(cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/cliente/', config_me))
        requestArray.push(axios.get(cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/monitorado-tipo/', config_me))
        requestArray.push(axios.get(cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/equipamento/', config_me))

        if (this.props.match.params.id) {
            const filter = `?monitorado=${this.props.match.params.id}`

            requestArray.push(axios.get(cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/monitorado-comando/' + filter, config_me))
            requestArray.push(axios.get(cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/monitorado-parametro/' + filter, config_me))
            requestArray.push(axios.get(cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/monitorado-foto/' + filter, config_me))
        }

        axios.all(requestArray).then(axios.spread((clientes, tipos, equipamentos, comandos_monitorado, parametros_monitorado, fotos_monitorado) => {
            let optionsCliente = []
            let optionsTipos = []
            let optionsEquipamentos = []
            let equipamentos_includes = []
            let comando_monitorado = []
            let parametro_monitorado = []
            let foto_monitorado = []

            if (clientes.status === 200) {
                clientes.data?.results?.forEach((a) => {
                    optionsCliente.push({ "value": a.id, "label": a.nome_fantasia })
                })
            }

            if (tipos.status === 200) {
                tipos.data.forEach((a) => optionsTipos.push({ "value": a.id, "label": a.nome }))
            }

            if (equipamentos.status === 200) {
                let equipamentos_sem_duplicacao = equipamentos.data?.results?.filter((equipamento_check) => {
                    if (equipamentos_includes.includes(equipamento_check.id)) {
                        return false
                    } else {
                        equipamentos_includes.push(equipamento_check.id)
                        return true
                    }
                })
                equipamentos_sem_duplicacao.forEach((a) => {
                    optionsEquipamentos.push({ "value": a.id, "label": a.imei })
                })
            }

            if (comandos_monitorado) {
                if (comandos_monitorado.status === 200) {
                    comando_monitorado = comandos_monitorado.data.results
                }
            }

            if (parametros_monitorado) {
                if (parametros_monitorado.status === 200) {
                    parametro_monitorado = parametros_monitorado.data.results
                }
            }

            if (fotos_monitorado) {
                if (fotos_monitorado.status === 200) {
                    foto_monitorado = fotos_monitorado.data.results
                }
            }

            if (userInfo.tipo === "cliente") {
                const detalhes = Object.assign({}, this.state.detalhes)
                const optionSelected = Object.assign({}, this.state.optionSelected)
                const cliente = optionsCliente.filter((item) => item.value = userInfo.cliente)

                optionSelected['cliente'] = cliente[0]
                detalhes['cliente'] = cliente[0]

                this.setState({ optionSelected, detalhes })
            }

            this.setState({ "clientes": optionsCliente, "tipos": optionsTipos, 'equipamentos': optionsEquipamentos, 'comandos_vinculados': comando_monitorado, 'parametros_vinculados': parametro_monitorado, 'fotos_vinculados': foto_monitorado })
        }))
    }

    getData = (mapObj) => {
        let userInfo = auth.getUserInfo()
        let requestURL = cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/monitorado/' + this.props.match.params.id + '/'
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }

        axios.get(requestURL, config_me).then((res) => {
            let detalhes = res.data
            delete res.data.id

            let optionObj = this.state.optionSelected
            if (res.data.cliente) {
                optionObj["cliente"] = { "value": res.data.cliente.id, "label": res.data.cliente.nome_fantasia }
                detalhes.cliente = { "value": res.data.cliente.id, "label": res.data.cliente.nome_fantasia }
            }

            if (res.data.tipo) {
                optionObj["tipo"] = { "value": res.data.tipo.id, "label": res.data.tipo.nome }
                detalhes.tipo = { "value": res.data.tipo.id, "label": res.data.tipo.nome }
            }

            let point = new mapObj.google_map.maps.LatLng(res.data.latitude, res.data.longitude)
            let bounds = new mapObj.google_map.maps.LatLngBounds()
            bounds.extend(point)
            mapObj.map.fitBounds(bounds)

            mapObj.map.setCenter(point)
            mapObj.map?.setZoom(17)

            let marker = new mapObj.google_map.maps.Marker({
                map: mapObj.map,
                position: point,
                draggable: true
            })

            marker.addListener('dragend', (event) => { this.updatePoint(event.latLng) })
            this.setState({ detalhes, marker, location: point, optionSelected: optionObj, address: res.data.point })
        })
    }

    handleChangeSelectCliente = (selectedOption, field) => {
        let obj = this.state.detalhes
        obj[field] = selectedOption

        let optionObj = this.state.optionSelected
        optionObj["cliente"] = { "value": selectedOption.value, "label": selectedOption.label }

        this.setState({ detalhes: obj, selectedOption: optionObj })


        document.querySelector('#validacao-cliente').innerHTML = ''
    }

    handleChange = (e) => {
        let obj = this.state.detalhes
        obj[e.target.id] = e.target.value
        if (document.querySelector(`#validacao-${e.target.name}`))
            document.querySelector(`#validacao-${e.target.name}`).innerHTML = ''
        this.setState({ "detalhes": obj })
    }

    handleChangeSelect = (selectedOption, field) => {
        let obj = this.state.detalhes
        obj[field] = selectedOption
        let optionObj = this.state.optionSelected
        optionObj[field] = selectedOption
        if (document.querySelector(`#validacao-${field.slice(0, -3)}-logica`))
            document.querySelector(`#validacao-${field.slice(0, -3)}-logica`).innerHTML = ""
        this.setState({ "optionSelected": optionObj, "detalhes": obj })
    }

    saveAction = (novo_cadastro, grid = false) => {
        this.setState({ cooldown: true })

        let userInfo = auth.getUserInfo()
        let requestURL = ''
        let verb = ''
        let bodyFormData = new FormData()

        if (this.props.match.params.id) {
            requestURL = cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/monitorado/' + this.props.match.params.id + '/'
            verb = 'patch'
        } else {
            requestURL = cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/monitorado/'
            verb = 'post'
        }

        bodyFormData.set("empresa_id", userInfo.empresa)
        bodyFormData.append("nome", this.state.detalhes?.nome)
        bodyFormData.append('point', this.state.address)
        bodyFormData.append("cliente_id", this.state.detalhes?.cliente?.value)
        bodyFormData.append("tipo_id", this.state.detalhes?.tipo?.value)

        if (this.state.location) {
            bodyFormData.set('latitude', String(this.state.location.toJSON().lat).substring(0, 14))
            bodyFormData.set('longitude', String(this.state.location.toJSON().lng).substring(0, 14))
        } else {
            document.querySelector(`#validacao-location`).innerHTML = "Este campo não pode estar vazio."
        }

        axios({
            method: verb,
            url: requestURL,
            data: bodyFormData,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        }).then((res) => {
            if (novo_cadastro === true) {
                this.setState({ redirect: true, path: "/monitorado/novo" })
            } else {
                helper.dispatchEvent("showAviso", {
                    message: `Monitorado ${verb === 'patch' ? 'editado' : 'criado'} com sucesso`,
                    callback: this.props.history.goBack
                })
            }
        }).catch((error) => {
            console.error(error)
            let erros = error.response.data
            if (Array.isArray(erros)) {
                for (let i = 0; i < erros.length; i++) {
                    let span = document.querySelector(`#validacao-${erros[i][0]}`)
                    if (span)
                        span.innerHTML = erros[i][1]
                }
            }
        })

        setTimeout(() => this.setState({ cooldown: false }), 1000)
    }

    generateOption = (source, value, desc) => {
        let options = source.map((obj, idx) =>
            <option key={idx} value={obj[value]}>{obj[desc]}</option>
        )
        return options
    }

    searchPointInMap = () => {
        let geocoder = new this.state.mapObject.google_map.maps.Geocoder()
        if (this.state.marker) {
            this.state.marker.setMap(null)
        }

        geocoder.geocode({ 'address': this.state.address }, (results, status) => {
            if (status === 'OK') {
                this.state.mapObject.map.setCenter(results[0].geometry.location)
                let marker = new this.state.mapObject.google_map.maps.Marker({
                    map: this.state.mapObject.map,
                    position: results[0].geometry.location,
                    draggable: true
                })
                marker.addListener('dragend', (event) => this.updatePoint(event.latLng))
                this.setState({ marker: marker, location: results[0].geometry.location, address: results[0].geometry.location.toJSON().lat.toFixed(6) + ", " + results[0].geometry.location.toJSON().lng.toFixed(6) })
            } else {
                alert('Geocode was not successful for the following reason: ' + status)
            }
        })
    }

    handleAddress = (e) => {
        this.setState({ "address": e.target.value })
        document.querySelector('#validacao-location').innerHTML = ""
    }

    keyPressSearchAddress = (e) => {
        if (e.key === "Enter") {
            e.preventDefault()
            this.searchPointInMap()
        }
    }

    handleChangeSelectEquipamentosParametros = (selectedOption, field) => {
        let obj = this.state.item
        obj[field] = selectedOption.value
        let optionObj = this.state.optionSelected
        optionObj[field] = selectedOption

        let filter = `?equipamento=${selectedOption.value}`
        let userInfo = auth.getUserInfo()
        const requestURL = cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/campo-customizado/' + filter
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }

        axios.get(requestURL, config_me).then((response) => {
            let parametros_selecionados = []
            let vinculados = this.state.parametros_vinculados?.map(item => item?.campo?.id)

            if (response.data?.results?.length > 0) {
                parametros_selecionados.push({ "value": "all", "label": "Selecionar Todos" })
            }

            response.data?.results?.forEach((value) => {
                parametros_selecionados.push({
                    "value": value.id, "label": value.descricao,
                    "isDisabled": vinculados.includes(value.id)
                })
            })

            this.setState({ "optionSelected": optionObj, "parametro": obj, "parametros_selecionados": parametros_selecionados })
        })

        if (document.querySelector(`#validacao-${field.slice(0, -3)}`))
            document.querySelector(`#validacao-${field.slice(0, -3)}`).innerHTML = ""
    }

    handleChangeSelectParametros = (selectedOption, field) => {
        let obj = this.state.item
        obj[field] = selectedOption.value
        let optionObj = this.state.optionSelected
        optionObj[field] = selectedOption

        this.setState({ "optionSelected": optionObj, "item": obj })
    }

    handleChangeSelectEquipamentosComandos = (selectedOption, field) => {
        let obj = this.state.item
        obj[field] = selectedOption.value
        let optionObj = this.state.optionSelected
        optionObj[field] = selectedOption

        let filter = `?equipamento=${selectedOption.value}`
        let userInfo = auth.getUserInfo()
        const requestURL = cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/comando-customizado/' + filter
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }

        axios.get(requestURL, config_me).then((response) => {
            let comandos_selecionados = []
            let vinculados = this.state.comandos_vinculados?.map(item => item?.comando?.id)

            if (response.data?.results?.length > 0) {
                comandos_selecionados.push({ "value": "all", "label": "Selecionar Todos" })
            }

            response.data?.results?.forEach((value) => {
                comandos_selecionados.push({
                    "value": value.id, "label": value.descricao,
                    "isDisabled": vinculados.includes(value.id)
                })
            })

            this.setState({ "optionSelected": optionObj, "comando": obj, comandos_selecionados })
        })

        if (document.querySelector(`#validacao-${field.slice(0, -3)}`))
            document.querySelector(`#validacao-${field.slice(0, -3)}`).innerHTML = ""
    }

    handleChangeSelectComandos = (selectedOption, field) => {
        let obj = this.state.item
        obj[field] = selectedOption.value
        let optionObj = this.state.optionSelected
        optionObj[field] = selectedOption

        this.setState({ "optionSelected": optionObj, "item": obj })
    }

    openImage = (image) => {
        this.setState({ open_image: true, image: image })
    }

    createRows = (source, campo_principal, campo_secundario, recurso) => {
        return this.state[source].map((campo, i) => {
            const splitPrincipal = campo_principal.split(".")
            const splitSecundario = campo_secundario.split(".")

            let principalValue
            let secundarioValue

            if (splitPrincipal?.length >= 1) {
                principalValue = eval(`campo?.${splitPrincipal.join("?.")}`)

                if (typeof principalValue !== "undefined") {
                    principalValue = String(principalValue)
                }
            }

            if (splitSecundario?.length >= 1) {
                secundarioValue = eval(`campo?.${splitSecundario.join("?.")}`)

                if (typeof secundarioValue !== "undefined") {
                    secundarioValue = String(secundarioValue)
                }
            }

            return (
                <tr key={i}>
                    <td style={{ wordBreak: "break-all", verticalAlign: "middle" }}>
                        {campo_principal == "foto" ?
                            <div onClick={() => this.openImage(campo[campo_principal])} className='image-monitorado' style={{ backgroundImage: `url(${campo[campo_principal]})` }}></div>
                            :
                            principalValue
                        }
                    </td>

                    <td style={{ verticalAlign: "middle" }}>{secundarioValue}</td>
                    <td style={{ textAlign: "center" }} className="clean-action">
                        <button
                            onClick={() => { this.removeItem(campo['id'], recurso) }}
                            className="hdv-btn-forms hdv-btn-red">
                            Remover
                        </button>
                    </td>
                </tr>
            )
        })
    }

    addNovoParametro = (event) => {
        event.preventDefault()
        let userInfo = auth.getUserInfo()
        let url = cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/monitorado-parametro/'
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }

        if (!this.state.parametro?.parametro_id) return

        if (this.state.optionSelected.parametro_id?.value == "all") {
            let parametros = this.state.parametros_selecionados?.filter(c => c?.value != "all" && !(c?.isDisabled))
            let requestArray = []

            if (parametros.length == 0) return

            parametros.forEach(parametro => {
                let bodyFormData = new FormData()
                bodyFormData.append('monitorado_id', this.props.match.params.id)
                bodyFormData.append('campo_id', parametro?.value)
                requestArray.push(axios.post(url, bodyFormData, config_me))
            })

            axios.all(requestArray).then(axios.spread((...results) => {
                let filter = `?monitorado=${this.props.match.params.id}`

                axios.get(url + filter, config_me).then((resp) => {
                    let parametro = { "equipamento_parametro_id": "", "parametro_id": "" }
                    let selecionados = this.state.optionSelected
                    selecionados['equipamento_parametro_id'] = {}
                    selecionados['parametro_id'] = {}
                    this.setState({ "parametro": parametro, "parametros_vinculados": resp.data.results, "optionSelected": selecionados })
                })
            }))
        } else {
            let bodyFormData = new FormData()
            bodyFormData.append('monitorado_id', this.props.match.params.id)
            bodyFormData.append('campo_id', this.state.parametro?.parametro_id)

            axios({
                method: "post",
                url: url,
                data: bodyFormData,
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
            }).then((response) => {
                let filter = `?monitorado=${this.props.match.params.id}`

                axios.get(url + filter, config_me).then((resp) => {
                    let parametro = { "equipamento_parametro_id": "", "parametro_id": "" }
                    let selecionados = this.state.optionSelected
                    selecionados['equipamento_parametro_id'] = {}
                    selecionados['parametro_id'] = {}
                    this.setState({ "parametro": parametro, "parametros_vinculados": resp.data.results, "optionSelected": selecionados })
                })
            }).catch((error) => {
                console.error(error)
                let erros = error.response.data
                if (Array.isArray(erros)) {
                    for (let i = 0; i < erros.length; i++) {
                        let span = document.querySelector(`#validacao-${erros[i][0]}`)
                        if (span)
                            span.innerHTML = erros[i][1]
                    }
                }
            })
        }
    }

    addNovoComando = (event) => {
        event.preventDefault()
        let userInfo = auth.getUserInfo()
        let url = cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/monitorado-comando/'
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }

        if (!this.state.comando?.comando_id) return

        if (this.state.optionSelected.comando_id?.value == "all") {
            let comandos = this.state.comandos_selecionados?.filter(c => c?.value != "all" && !(c?.isDisabled))
            let requestArray = []

            if (comandos.length == 0) return

            comandos.forEach(comando => {
                let bodyFormData = new FormData()
                bodyFormData.append('monitorado_id', this.props.match.params.id)
                bodyFormData.append('comando_id', comando?.value)
                requestArray.push(axios.post(url, bodyFormData, config_me))
            })

            axios.all(requestArray).then(axios.spread((...results) => {
                let filter = `?monitorado=${this.props.match.params.id}`

                axios.get(url + filter, config_me).then((resp) => {
                    let comando = { "equipamento_comando_id": "", "comando_id": "" }
                    let selecionados = this.state.optionSelected
                    selecionados['equipamento_comando_id'] = {}
                    selecionados['comando_id'] = {}
                    this.setState({ "comando": comando, "comandos_vinculados": resp.data.results, "optionSelected": selecionados })
                })
            }))
        } else {
            let bodyFormData = new FormData()
            bodyFormData.append('monitorado_id', this.props.match.params.id)
            bodyFormData.append('comando_id', this.state.comando?.comando_id)

            axios({
                method: "post",
                url: url,
                data: bodyFormData,
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
            }).then((response) => {
                let filter = `?monitorado=${this.props.match.params.id}`

                axios.get(url + filter, config_me).then((resp) => {
                    let comando = { "equipamento_comando_id": "", "comando_id": "" }
                    let selecionados = this.state.optionSelected
                    selecionados['equipamento_comando_id'] = {}
                    selecionados['comando_id'] = {}
                    this.setState({ "comando": comando, "comandos_vinculados": resp.data.results, "optionSelected": selecionados })
                })
            }).catch((error) => {
                console.error(error)
                let erros = error.response.data
                if (Array.isArray(erros)) {
                    for (let i = 0; i < erros.length; i++) {
                        let span = document.querySelector(`#validacao-${erros[i][0]}`)
                        if (span)
                            span.innerHTML = erros[i][1]
                    }
                }
            })
        }
    }

    removeItem = (id, resource) => {
        let requestArray = []
        let userInfo = auth.getUserInfo()
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }

        requestArray.push(axios.delete(cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/' + resource + '/' + id + '/', config_me))
        let state = this.state
        let filter = `?monitorado=${this.props.match.params.id}`

        axios.all(requestArray).then(axios.spread((acct, perms) => {
            const requestURL = cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/' + resource + '/' + filter
            let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }

            let state_update = ""
            switch (resource) {
                case 'monitorado-comando':
                    state_update = 'comandos_vinculados'
                    break
                case 'monitorado-foto':
                    state_update = 'fotos_vinculados'
                    break
                case 'monitorado-parametro':
                    state_update = 'parametros_vinculados'
                    break
                default:
                    break
            }

            axios.get(requestURL, config_me).then((response) => {
                state[state_update] = (response.status == 200) ? response.data.results : []
                this.setState(state)
            })
        }))
    }

    handleImgPreview = (e) => {
        let obj = this.state.foto_file
        if (e.target?.files) {
            obj[e.target.id] = e.target.files[0]
            this.setState({ "foto_file": obj, foto_temp: URL.createObjectURL(e.target.files[0]) })
        } else {
            obj[e.target.id] = e.target.value
            this.setState({ "foto_file": obj })
        }
    }

    addNovoFoto = (event) => {
        let { foto_file } = this.state
        event.preventDefault()

        document.querySelector("#validacao-foto").innerHTML = !foto_file.foto_file ? "Adicione uma imagem" : ""
        document.querySelector("#validacao-descricao").innerHTML = !foto_file.foto_descricao ? "Adicione uma descrição" : ""

        if (!foto_file.foto_descricao || !foto_file.foto_file) return

        if (this.props.match.params.id) {
            let userInfo = auth.getUserInfo()
            let bodyFormData = new FormData()

            bodyFormData.append('monitorado_id', this.props.match.params.id)
            bodyFormData.append('foto', this.state.foto_file.foto_file)
            bodyFormData.append('descricao', this.state.foto_file.foto_descricao)

            axios({
                method: "post",
                url: cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/monitorado-foto/',
                data: bodyFormData,
                headers: { 'Content-Type': 'multipart/form-data', 'Authorization': 'Bearer ' + auth.getToken() }
            })
                .then((response) => {
                    let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }
                    let filter = `?monitorado=${this.props.match.params.id}`

                    axios.get(cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/monitorado-foto/' + filter, config_me)
                        .then((resp) => {
                            //let item = { "equipamento_parametro_id": "", "equipamento_comando_id": "", "parametro_id": "", "comando_id": "" }
                            let foto = { "foto_descricao": "", "foto_file": undefined }
                            this.setState({
                                "foto_file": foto,
                                "fotos_vinculados": resp.data.results,
                                "foto_temp": "https://rs485project.s3.amazonaws.com/sem-imagem-10.jpg"
                            })
                        })
                })
                .catch((error) => {
                    console.error(error)
                    let erros = error.response.data
                    if (Array.isArray(erros)) {
                        for (let i = 0; i < erros.length; i++) {
                            let span = document.querySelector(`#validacao-${erros[i][0]}`)
                            if (span) span.innerHTML = erros[i][1]
                        }
                    }
                })
        }
    }

    render() {
        const selectedTipo = this.state.optionSelected.tipo
        const selectedCliente = this.state.optionSelected.cliente
        const selectedEquipamentoParamentro = this.state.optionSelected.equipamento_parametro_id
        const selectedEquipamentoComando = this.state.optionSelected.equipamento_comando_id
        const selectedParametroSelecionado = this.state.optionSelected.parametro_id
        const selectedComandoSelecionado = this.state.optionSelected.comando_id
        const isCliente = auth.getUserInfo()?.tipo === "cliente"

        if (this.state.location && document.querySelector(`#validacao-location`) !== null)
            document.querySelector(`#validacao-location`).innerHTML = ""

        if (this.state.redirect === true) {
            return <Redirect to={{
                pathname: '/redirect',
                state: { pathto: this.state.path }
            }}
            />
        }

        return (
            <div>
                <ModalImage
                    open={this.state.open_image}
                    images={[{ foto: this.state.image }]}
                    toggle={() => this.setState({ open_image: false, image: "" })}
                />

                <div>
                    <div className="hdv-default-header mx-3">
                        <span className="screen-menu-desc">
                            {this.props.match.params.id ? <h4>Editar Monitorado</h4> : <h4>Novo Monitorado</h4>}
                            <div className="gray-background">
                                <i className="fa fa-map-marked-alt fa-2x"></i>
                            </div>
                        </span>
                    </div>
                </div>

                <Tabs className="tabs-style">
                    <TabList>
                        <Tab>Detalhes do Monitorado</Tab>
                        <Tab disabled={(this.props.match.params.id) ? false : true}>Parâmetros</Tab>
                        <Tab disabled={(this.props.match.params.id) ? false : true}>Comandos</Tab>
                        <Tab disabled={(this.props.match.params.id) ? false : true}>Fotos</Tab>
                    </TabList>

                    <TabPanel>
                        <form className="hdv-form-default">
                            <div className="row">
                                <div className="col-md-6">
                                    <label className="required" htmlFor="nome">Nome do Monitorado</label>
                                    <input type="text" name="nome" id="nome" value={this.state.detalhes.nome} onChange={this.handleChange} />
                                    <span id="validacao-nome"></span>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    <label className="required" htmlFor="cliente">Cliente</label>
                                    <Select
                                        value={selectedCliente}
                                        disabled={auth.isClient()}
                                        onChange={(e) => this.handleChangeSelectCliente(e, 'cliente')}
                                        options={this.state.clientes}
                                    />
                                    <span id="validacao-cliente"></span>
                                </div>
                                <div className="col-md-6">
                                    <label className="required" htmlFor="tipo">Tipo</label>
                                    <Select
                                        value={selectedTipo}
                                        onChange={(e) => this.handleChangeSelect(e, 'tipo')}
                                        options={this.state.tipos}
                                    />
                                    <span id="validacao-tipo"></span>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <label className="required" htmlFor="nome">Selecionar Ponto no Mapa</label>
                                    <input type="text" name="address" id="address" value={this.state.address} onKeyPress={this.keyPressSearchAddress} onChange={this.handleAddress} />
                                    <span id="validacao-location"></span>
                                </div>
                            </div>

                            <div className="hdv-mapform">
                                <Mapa mapObjectFn={this.updateMapObject} />
                            </div>
                        </form>
                    </TabPanel>

                    <TabPanel>
                        <form className="hdv-form-default">
                            <div className="row">
                                <div className="col-md-5">
                                    <label className="required" htmlFor="equipamento">Equipamento</label>
                                    <Select
                                        value={selectedEquipamentoParamentro}
                                        onChange={(e) => this.handleChangeSelectEquipamentosParametros(e, 'equipamento_parametro_id')}
                                        options={this.state.equipamentos}
                                    />
                                    <span id="validacao-equipamento"></span>
                                </div>
                                <div className="col-md-4">
                                    <label className="required" htmlFor="parametro_id">Parâmetro</label>
                                    <Select
                                        value={selectedParametroSelecionado}
                                        onChange={(e) => this.handleChangeSelectParametros(e, 'parametro_id')}
                                        options={this.state.parametros_selecionados}
                                    />
                                </div>
                                <div className="col-md-3 flex-col">
                                    <button
                                        style={{ marginBottom: "7px", width: "100%" }}
                                        className="hdv-btn-forms hdv-btn-blue btn-inline-resolve"
                                        onClick={this.addNovoParametro} >
                                        <i className="fa fa-plus-o"></i>
                                        Vincular Parâmetro
                                    </button>
                                </div>
                            </div>
                        </form>

                        <h6 className="hdv-subtable-title">Lista de Parâmetros Vinculados</h6>
                        <table className="table afira-table">
                            <thead>
                                <tr>
                                    <th>Equipamento</th>
                                    <th>Parâmetro</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.createRows("parametros_vinculados", "campo.equipamento.imei", "campo.descricao", "monitorado-parametro")}
                            </tbody>
                        </table>
                    </TabPanel>

                    <TabPanel>
                        <form className="hdv-form-default">
                            <div className="row">
                                <div className="col-md-5">
                                    <label className="required" htmlFor="equipamento">Equipamento</label>
                                    <Select
                                        value={selectedEquipamentoComando}
                                        onChange={(e) => this.handleChangeSelectEquipamentosComandos(e, 'equipamento_comando_id')}
                                        options={this.state.equipamentos}
                                    />
                                    <span id="validacao-equipamento"></span>
                                </div>
                                <div className="col-md-4">
                                    <label className="required" htmlFor="entrada_tipo">Comando</label>
                                    <Select
                                        value={selectedComandoSelecionado}
                                        onChange={(e) => this.handleChangeSelectComandos(e, 'comando_id')}
                                        options={this.state.comandos_selecionados}
                                    />
                                </div>
                                <div className="col-md-3 flex-col">
                                    <button
                                        style={{ marginBottom: "7px", width: "100%" }}
                                        className="hdv-btn-forms hdv-btn-blue btn-inline-resolve"
                                        onClick={this.addNovoComando} >
                                        <i className="fa fa-plus-o"></i>
                                        Vincular Comando
                                    </button>
                                </div>
                            </div>
                        </form>

                        <h6 className="hdv-subtable-title">Lista de Comandos Vinculados</h6>
                        <table className="table afira-table">
                            <thead>
                                <tr>
                                    <th>Equipamento</th>
                                    <th>Comando</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.createRows("comandos_vinculados", "comando.equipamento.imei", "comando.descricao", "monitorado-comando")}
                            </tbody>
                        </table>
                    </TabPanel>

                    <TabPanel>
                        <form className="hdv-form-default">
                            <div className="row">
                                <div className="col-md-2">
                                    <img className="img-preview-photo" src={this.state.foto_temp} />
                                </div>
                                <div className="col-md-3">
                                    <div className="hdv-file-picker">
                                        <label className="required" htmlFor="foto_file">Foto</label>
                                        <input type="file" accept=".jpg,.jpeg,.png,.webp" id="foto_file" onChange={this.handleImgPreview} />
                                        <label className="m-0" htmlFor="foto_file" style={{ width: "100%" }}>Selecionar arquivo</label>
                                        <span id="validacao-foto"></span>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <label className="required" htmlFor="foto_descricao">Descrição</label>
                                    <input onChange={this.handleImgPreview} value={this.state.foto_file.foto_descricao} type="text" id="foto_descricao" />
                                    <span id="validacao-descricao"></span>
                                </div>
                                <div className="col-md-3 flex-col">
                                    <button
                                        style={{ marginBottom: "17px", width: "100%" }}
                                        className="hdv-btn-forms hdv-btn-blue btn-inline-resolve"
                                        onClick={this.addNovoFoto} >
                                        <i className="fa fa-plus-o"></i>
                                        Adicionar Foto
                                    </button>
                                </div>
                            </div>
                        </form>

                        <h6 className="hdv-subtable-title">Lista de Fotos Cadastrados</h6>
                        <table className="table afira-table">
                            <thead>
                                <tr>
                                    <th>Foto</th>
                                    <th>Descrição</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.createRows("fotos_vinculados", "foto", "descricao", "monitorado-foto")}
                            </tbody>
                        </table>
                    </TabPanel>
                </Tabs>

                <div className="hdv-btn-group">
                    <ButtonsForm
                        route="/monitorado"
                        edit={this.props.match.params.edit ? true : false}
                        disabled={this.state.cooldown}
                        buttons={[
                            {
                                method: "post",
                                button: <button onClick={() => this.saveAction(false, true)} className="hdv-btn-forms hdv-btn-green">Salvar</button>
                            }, {
                                method: "post",
                                button: <button style={{ display: this.props.match.params.id ? "none" : "" }} onClick={() => this.saveAction(true)} className="hdv-btn-forms hdv-btn-greenlight">Salvar e Cadastrar um Novo</button>
                            }, {
                                method: "patch",
                                button: <button onClick={() => this.saveAction(false, true)} className="hdv-btn-forms hdv-btn-green">Salvar</button>
                            },
                        ]}
                    />
                    <button onClick={this.props.history.goBack} className="hdv-btn-forms hdv-btn-orange">Cancelar</button>
                </div>
            </div>
        )
    }
}

export default Form