import React from "react"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { components } from "react-select"
import Select from "../../../components/Select"
import cfg from "../../../utils/config"
import auth from "../../../utils/auth"
import axios from "axios"

class ContatoAlerta extends React.Component {
   configMe = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }
   userInfo = auth.getUserInfo()

   state = {
      contatos: [],
      clientes: [],
      modal: false,
      contato: {
         email_externo: "",
         cliente: null,
         enviar_email: true,
         enviar_mobile: false
      },
   }

   componentDidMount() {
      if (this.props?.contatos?.length > 0) {
         let { contatos } = this.state
         contatos = [...contatos, ...this.props.contatos]
         this.setState({ contatos })
      }

      this.getClientes()
   }

   getClientes = async () => {
      let clientes = []

      await axios.get(cfg.base_api_url + cfg.api_v2 + '/' + this.userInfo.empresa + '/usuario/cliente/?limit=100', this.configMe).then((res) => {
         res.data?.results?.forEach(usuario => {
            clientes.push({
               label: usuario?.nome,
               value: usuario.id,
               email: usuario?.email
            })
         })
      })

      await axios.get(cfg.base_api_url + cfg.api_v2 + '/' + this.userInfo.empresa + '/cliente/?limit=100', this.configMe).then((res) => {
         res.data?.results?.forEach(cliente => {
            clientes.push({
               label: cliente.nome_fantasia,
               value: cliente.id,
               email: cliente.email_administrador
            })
         })
      })

      console.log(clientes)
      this.setState({ clientes })
   }

   handleModal = () => {
      this.setState({ modal: !this.state.modal })
   }

   saveContatos = () => {
      this.handleModal()
      this.props?.onChange(this.state.contatos)
   }

   removeContato = (index) => {
      let { contatos } = this.state
      contatos.splice(index, 1)

      this.setState({ contatos })
   }

   addContato = () => {
      let { contato, contatos } = this.state

      if (contato?.email_externo?.length > 0) {
         if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(contato?.email_externo))) {
            return
         }

         contatos.push({
            label: null,
            email: contato?.email_externo,
            enviar_email: true,
            enviar_mobile: false,
         })

         this.cancelar()
         this.setState({ contatos })
      } else if (contato?.cliente && (contato?.enviar_email || contato?.enviar_mobile)) {
         const copy = JSON.parse(JSON.stringify(contato))
         contatos.push({
            label: copy?.cliente?.label,
            email: copy?.cliente?.email,
            enviar_email: copy?.enviar_email,
            enviar_mobile: copy?.enviar_mobile,
         })

         this.cancelar()
         this.setState({ contatos })
      }
   }

   cancelar = () => {
      this.setState({
         contato: {
            cliente: null,
            enviar_mobile: false,
            email_externo: "",
            enviar_email: true
         }
      })
   }

   handleChange = (field, value) => {
      let { contato } = this.state
      contato[field] = value
      this.setState({ contato })
   }

   render() {
      const { Option } = components

      return (
         <>
            <Modal size="lg" isOpen={this.state.modal} toggle={this.handleModal}>
               <ModalHeader>Contatos do Alerta</ModalHeader>
               <ModalBody style={{ minHeight: "300px" }}>
                  <div className="row hdv-form-default">
                     <div className="col-md-5">
                        <label className="mt-0">Cliente:</label>
                        <Select
                           components={{
                              Option: (props) => (
                                 <Option {...props}>
                                    <p id="font">{props.data.label}</p>
                                    <span style={{ opacity: "0.8" }}>{props.data?.email ?? "---"}</span>
                                 </Option>
                              )
                           }}
                           value={this.state.contato.cliente}
                           disabled={this.state.contato.email_externo?.length > 0}
                           onChange={(e) => this.handleChange("cliente", e)}
                           options={this.state.clientes}
                        />
                        <span className="custom-hr" data-text="Ou"></span>
                        <label htmlFor="email_externo">E-mail externo</label>
                        <input
                           type="text" id="email_externo"
                           value={this.state.contato.email_externo}
                           disabled={(this.state.contato?.cliente)}
                           onKeyDown={(e) => e.key === "Enter" ? this.addContato() : null}
                           onChange={(e) => this.handleChange("email_externo", e.target.value)}
                        />

                        <label className="mt-2 mb-0">Enviar via:</label>
                        <div className="row col-md-12 px-0">
                           <label htmlFor="enviar_email" className="col-md-4" style={{ color: "#222" }}>
                              <input
                                 type="checkbox"
                                 id="enviar_email"
                                 checked={this.state.contato.enviar_email}
                                 onChange={(e) => this.handleChange("enviar_email", e.target.checked)}
                              /> E-mail
                           </label>
                           <label htmlFor="enviar_mobile" className="col-md-4" style={{ color: "#222" }}>
                              <input
                                 type="checkbox"
                                 id="enviar_mobile"
                                 checked={this.state.contato.enviar_mobile}
                                 onChange={(e) => this.handleChange("enviar_mobile", e.target.checked)}
                              /> Mobile
                           </label>
                        </div>

                        <div className="d-flex justify-content-between mt-3">
                           <button
                              className="col-md-6"
                              style={{ borderRadius: "10px", margin: "0 3px", padding: "10px 14px", cursor: "pointer" }}
                              onClick={this.cancelar}>
                              Cancelar
                           </button>

                           <button
                              className="col-md-6 hdv-btn-green"
                              disabled={!(this.state.contato?.email_externo?.length > 0 || this.state.contato?.cliente)}
                              style={{ borderRadius: "10px", margin: "0 3px", color: "#fff", padding: "10px 14px", cursor: "pointer" }}
                              onClick={this.addContato}>
                              Adicionar
                           </button>
                        </div>
                     </div>
                     <div className="col-md-7">
                        <label className="mt-0">Lista de Contatos</label>
                        <div className="d-flex flex-column" style={{ maxHeight: "400px", overflowY: "auto" }}>
                           {this.state.contatos.map((contato, index) => (
                              <div key={index} className="contato-item">
                                 <div>
                                    <p>{this.state.contatos[index]?.label ?? "---"}</p>
                                    <p style={{ opacity: "0.8" }}>{this.state.contatos[index]?.email ?? "---"}</p>
                                 </div>
                                 <div className="d-flex">
                                    {this.state.contatos[index]?.enviar_email ? <i className="fa fa-envelope fa-2x" title="Enviar e-mail"></i> : null}
                                    {this.state.contatos[index]?.enviar_mobile ? <i className="fa fa-mobile fa-2x" title="Enviar notificação"></i> : null}
                                    <i onClick={() => this.removeContato(index)} className="fa fa-times fa-2x" style={{ backgroundColor: "#e34b4b", color: "#fff", cursor: "pointer" }}></i>
                                 </div>
                              </div>
                           ))}
                        </div>
                     </div>
                  </div>
               </ModalBody>
               <ModalFooter>
                  <Button color="default" onClick={() => { this.cancelar(); this.handleModal() }}>Cancelar</Button>
                  <Button color="primary" onClick={this.saveContatos}>Salvar</Button>
               </ModalFooter>
            </Modal>

            <button
               onClick={this.handleModal}
               className="hdv-btn-forms hdv-btn-blue w-100 d-flex align-items-center justify-content-center hdv-btn-blue text-white"
               style={{ height: "43px", borderRadius: "10px", cursor: "pointer" }}>
               <i className="fa fa-cog fa-2x"></i>
               Gerenciar
            </button>
         </>
      )
   }
}

export default ContatoAlerta