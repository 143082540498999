import React, { Component } from 'react'
import { Switch } from 'react-router-dom'
import Form from './form'
import Grid from './grid'
import PrivateRoute from '../../containers/PrivateRoute'

class EquipamentoVirtual extends Component {
	render() {
		return (
			<div className="hdv-render-div">
				<div>
					<Switch>
						<PrivateRoute path='/virtual/:imei/lancamento' component={Form} />
						<PrivateRoute path='/virtual/' component={Grid} />
					</Switch>
				</div>
			</div>
		)
	}
}

export default EquipamentoVirtual
