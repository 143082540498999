import React from 'react'
import Select from '../../components/Select'
import { Link, Redirect } from 'react-router-dom'
import ButtonsForm from '../../components/ButtonsForm'
import auth from '../../utils/auth'
import cfg from '../../utils/config'
import helper from '../../utils/helper'
import axios from 'axios'


export default class Form extends React.Component {
    state = {
        detalhes: { cliente: null, monitorado: null, equipamento: null, campo: null, usuario: "", codigo: "", chave: "", enviarDados: false },
        validacao: { data: true, cliente: true, monitorado: true, equipamento: true, campo: true, usuario: true, codigo: true, chave: true },
        clientes: [],
        monitorados: [],
        equipamentos: [],
        campos: [],
        isCliente: auth.getUserInfo()?.cliente
    }

    componentDidMount() {
        const { detalhes, isCliente } = this.state

        if (this.props.match.params.id) {
            this.getData()
        }

        if (isCliente) {
            this.getMonitorado()
            delete detalhes.cliente
            this.setState({ detalhes })
        } else {
            this.getClientes()
        }
    }

    getData = () => {
        let userInfo = auth.getUserInfo()
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }

        axios.get(cfg.base_api_url + cfg.api_v2 + `/${userInfo.empresa}/sideccr/${this.props.match.params.id}`, config_me)
            .then(res => {
                let { detalhes, isCliente } = this.state
                let result = res?.data

                detalhes['chave'] = result.chave
                detalhes['usuario'] = result.usuario
                detalhes['enviarDados'] = result.ativo
                detalhes['codigo'] = result.codigo_medidor
                detalhes['campo'] = result.campo_customizado
                detalhes['equipamento'] = result.equipamento
                detalhes['monitorado'] = result.monitorado
                detalhes['enviarDados'] = result.ativo

                if (!isCliente) {
                    detalhes['cliente'] = result.cliente
                    this.getClientes()
                }
                this.getMonitorado(result.cliente.value)
                this.getEquipamento(result.monitorado.value)
                this.getCamposEquipamento(result.equipamento.value)

                this.setState({ detalhes })
            })
    }

    getClientes = () => {
        let userInfo = auth.getUserInfo()
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }

        axios.get(cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + `/acesso/modulo/2/revenda/`, config_me)
            .then((res) => {
                let optionsCliente = []
                res.data.forEach((c) => {
                    optionsCliente.push({ "value": c.cliente__id, "label": c.cliente__nome_fantasia })
                })

                this.setState({ clientes: optionsCliente })
            })
    }

    getMonitorado = (id_cliente) => {
        let userInfo = auth.getUserInfo()
        let bodyFormData = new FormData()

        bodyFormData.append('cliente_id', id_cliente ?? userInfo.id)

        axios({
            method: 'POST',
            url: cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/monitorado/filtro/',
            data: bodyFormData,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        })
            .then((response) => {
                let optionsMonitorado = []
                response.data.forEach((c) => {
                    optionsMonitorado.push({ "value": c.id, "label": c.nome })
                })
                this.setState({ monitorados: optionsMonitorado })
            })
            .catch(error => console.log(error))
    }

    getEquipamento = (id_monitorado) => {
        let userInfo = auth.getUserInfo()
        axios({
            method: 'GET',
            url: cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/monitorado-equipamento/' + id_monitorado + '/',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        })
            .then((res) => {
                let optionsEquipamento = []
                let optionsCampos = []

                res.data.equipamentos.forEach((equipamento) => optionsEquipamento.push(equipamento))
                res.data.campos.forEach((campo) => optionsCampos.push(campo))

                this.setState({ equipamentos: optionsEquipamento, campos: optionsCampos })
            })
            .catch(console.error)
    }

    getCamposEquipamento = (id_equipamento) => {
        let userInfo = auth.getUserInfo()

        axios({
            method: 'GET',
            url: cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + `/campo-customizado/?equipamento=${id_equipamento}`,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        })
            .then((res) => {
                let fields = []

                res.data?.results.forEach((field) => fields.push({ label: field.descricao, value: field.id }))

                this.setState({ campos: fields })
            })
            .catch(error => console.log(error))
    }

    handleChange = (selectedOption, field) => {
        let obj = this.state.detalhes
        let canotClearFields = ["usuario", "chave", "codigo", "enviarDados"]
        obj[field] = selectedOption

        if (field === "cliente") {
            this.getMonitorado(selectedOption.value)
            canotClearFields.push("cliente")
        } else if (field === 'monitorado') {
            this.getEquipamento(selectedOption.value)
            canotClearFields.push("cliente", "monitorado")
        } else if (field === 'equipamento') {
            this.getCamposEquipamento(selectedOption.value)
            canotClearFields.push("cliente", "monitorado", "equipamento")
        }

        if (field != "campo") {
            for (let i in obj) {
                !canotClearFields.includes(i) ? obj[i] = null : null
            }
        }

        this.setState({ detalhes: obj })
    }

    handleCheckbox = (e) => {
        let { detalhes } = this.state
        detalhes['enviarDados'] = !detalhes['enviarDados']
        this.setState({ detalhes })
    }

    handleChangeInput = (e) => {
        let { detalhes } = this.state
        detalhes[e.target.name] = e.target.value
        this.setState({ detalhes })
    }

    validation = () => {
        const { validacao, detalhes } = this.state

        let notValidate = false
        let validateForm = validacao

        for (let key in detalhes) {
            if (detalhes[key] === null && key != 'equipamento' || detalhes[key] === "" && key != 'equipamento') {
                validateForm[key] = false
                notValidate = true
            } else
                validateForm[key] = true
        }

        this.setState({ validacao: validateForm })

        return notValidate
    }

    saveAction = (novoCadastro) => {
        this.setState({ cooldown: true })
        const { detalhes } = this.state

        if (this.validation()) {
            this.setState({ cooldown: false })
            return
        }

        let userInfo = auth.getUserInfo()
        let requestURL = ''
        let verb = ''
        if (this.props.match.params.id) {
            requestURL = cfg.base_api_url + cfg.api_v2 + `/${userInfo.empresa}/sideccr/` + this.props.match.params.id + '/'
            verb = 'PATCH'
        } else {
            requestURL = cfg.base_api_url + cfg.api_v2 + `/${userInfo.empresa}/sideccr/`
            verb = 'POST'
        }

        let bodyFormData = new FormData()

        bodyFormData.append('usuario', detalhes.usuario)
        bodyFormData.append('codigo_medidor', detalhes.codigo)
        bodyFormData.append('chave', detalhes.chave)
        bodyFormData.append('ativo', detalhes.enviarDados ? '1' : '0')
        bodyFormData.append('monitorado', detalhes.monitorado.value)
        bodyFormData.append('campo_customizado', detalhes.campo.value)

        axios({
            method: verb,
            url: requestURL,
            data: bodyFormData,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        }).then(res => {
            if (novoCadastro) {
                window.location.reload()
            } else {
                helper.dispatchEvent("showAviso", {
                    message: `${verb === "POST" ? 'Criado' : 'Editado'} com sucesso`,
                    callback: () => this.setState({ redirect: true, path: "/sideccr" }),
                    callbackClose: verb === "POST" ?
                        () => {
                            window.location.href = `/sideccr/${res.data.id}/editar?protocolo=true`
                            setTimeout(() => {
                                window.location.reload()
                            }, 1000)
                        }
                        : null
                })
            }
        })

        setTimeout(() => this.setState({ cooldown: false }), 2500)
    }

    render() {
        const { validacao, detalhes, isCliente } = this.state
        const clienteOption = detalhes.cliente
        const monitoradoOption = detalhes.monitorado
        const equipamentoOption = detalhes.equipamento
        const campoOption = detalhes.campo

        if (this.state.redirect === true) {
            return <Redirect to={{ pathname: '/redirect', state: { pathto: this.state.path } }} />
        }

        return (
            <div>
                <div className="hdv-default-header mx-3">
                    <span className="screen-menu-desc">
                        {this.props.match.params.id ? <h4>Editar SIDECCR-R</h4> : <h4>Novo SIDECC-R</h4>}
                        <div className="gray-background">
                            <i className="fa fa-network-wired fa-2x"></i>
                        </div>
                    </span>
                </div>

                <div className="hdv-form-default row">
                    <div style={{ display: isCliente ? "none" : "block" }} className="col-md-3">
                        <label className="required">Cliente</label>
                        <Select
                            value={clienteOption}
                            name="cliente"
                            onChange={(e) => this.handleChange(e, 'cliente')}
                            options={this.state.clientes}
                        />
                        {!validacao.cliente ? <span id='validacao-cliente'>Campo não pode ser nulo!</span> : null}
                    </div>
                    <div className="col-md-3">
                        <label className="required">Monitorado</label>
                        <Select
                            value={monitoradoOption}
                            name="monitorado"
                            onChange={(e) => this.handleChange(e, 'monitorado')}
                            options={this.state.monitorados}
                        />
                        {!validacao.monitorado ? <span id='validacao-monitorado'>Campo não pode ser nulo!</span> : null}
                    </div>
                    <div className="col-md-3">
                        <label className="required">Equipamento</label>
                        <Select
                            value={equipamentoOption}
                            onChange={(e) => this.handleChange(e, 'equipamento')}
                            options={this.state.equipamentos}
                        />
                        {!validacao.equipamento ? <span id='validacao-equipamento'>Campo não pode ser nulo!</span> : null}
                    </div>
                    <div className="col-md-3">
                        <label className="required">Campo</label>
                        <Select
                            value={campoOption}
                            onChange={(e) => { this.handleChange(e, 'campo') }}
                            options={this.state.campos}
                        />
                        {!validacao.campo ? <span id='validacao-campo'>Campo não pode ser nulo!</span> : null}
                    </div>
                    <div className="col-md-6">
                        <label className="required">Usuário
                            <input
                                type="text"
                                value={detalhes.usuario}
                                name="usuario"
                                onChange={(e) => this.handleChangeInput(e)}
                            />
                            {!validacao.usuario ? <span id='validacao-usuario'>Campo não pode ser nulo!</span> : null}
                        </label>

                        <label className="required">Código do medidor
                            <input
                                type="text"
                                value={detalhes.codigo}
                                name="codigo"
                                onChange={(e) => this.handleChangeInput(e)}
                            />
                            {!validacao.codigo ? <span id='validacao-codigo'>Campo não pode ser nulo!</span> : null}
                        </label>
                    </div>
                    <div className="col-md-6">
                        <label className="required">Chave
                            <input
                                type="text"
                                value={detalhes.chave}
                                name="chave"
                                onChange={(e) => this.handleChangeInput(e)}
                            />
                            {!validacao.chave ? <span id='validacao-equipamento'>Campo não pode ser nulo!</span> : null}
                        </label>
                    </div>
                    <div className="col-md-6">
                        <label className="required" >
                            <input
                                type="checkbox"
                                className="checkbox"
                                name="chave"
                                checked={detalhes.enviarDados}
                                onChange={(e) => this.handleCheckbox(e)}
                            />&nbsp;
                            Enviar dados para SIDECC-R
                        </label>
                    </div>
                </div>
                <br />

                <div className="hdv-btn-group" style={{ display: 'flex' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <p className="badge-success"
                            style={{ fontSize: '14px', padding: '0px 10px', borderRadius: '8px', margin: '5px 0' }}>
                            {this.state.salvoMsg}
                        </p>
                        <p className="badge-danger"
                            style={{ fontSize: '14px', padding: '0px 10px', borderRadius: '8px', margin: '5px 0' }}>
                            {this.state.erroMsg}
                        </p>
                    </div>
                </div>

                <div className="hdv-btn-group">
                    <ButtonsForm
                        route="/sideccr"
                        edit={this.props.match.params.edit ? true : false}
                        cooldown={this.state.cooldown}
                        buttons={[
                            {
                                method: "post",
                                button: <button onClick={() => this.saveAction(false)} className="hdv-btn-forms hdv-btn-green">Salvar</button>
                            },

                            {
                                method: "post",
                                button: <button style={{ display: this.props.match.params.id ? "none" : "inline-block" }} onClick={() => this.saveAction(true)} className="hdv-btn-forms hdv-btn-greenlight">Salvar e Cadastrar um Novo</button>
                            },

                            {
                                method: "patch",
                                button: <button onClick={() => this.saveAction(false)} className="hdv-btn-forms hdv-btn-green">Salvar</button>
                            },
                        ]}
                    />
                    <Link to="/sideccr">
                        <button className="hdv-btn-forms hdv-btn-orange">Cancelar</button>
                    </Link>
                </div>
            </div>
        )
    }
}